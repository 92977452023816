import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Menu,
  MenuItem,
  ListItem,
  List
} from '@mui/material'
import { Chip, Tooltip, IconButton, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useNavigate } from 'react-router-dom'
import {
  cancelInvoice,
  getAppoinmentsByID,
  getAppoinmentsList,
  getCustomerList,
  ShowInsuranceInvocieApi,
  ShowInvocieApi,
  updateAppointment,
  viewVitalReports
} from './ApiMethods'
import AppLoader from '../../../components/AppLoader'
import toast from 'react-hot-toast'
import { useRef } from 'react'
import moment from 'moment'
import ViewVitalReportsModal from '../../../components/orders/ViewVitalReportsModal'
import Spinner from './Spinner'

export const formatDateTime = isoDateString => {
  const dateObj = new Date(isoDateString)
  const formattedDate = dateObj.toLocaleDateString()
  const formattedTime = dateObj.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  })
  return `${formattedDate} ${formattedTime}`
}

const PatientCard = ({
  appointment,
  setAppApiloading,
  patientID,
  Doctors,
  filterStatus,
  currentPage
}) => {
  const [invoiceBox, openInvoiceBox] = useState(false)

  const [open, setOpen] = useState(false)
  const [appointmentId, setAppointmentId] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(appointment.status)
  const navigate = useNavigate()
  const [viewVitalSings, setViewVitalSings] = useState(null)
  const [viewProgressNotes, setViewProgressNotes] = useState(null)
  const openVitalSignMenu = Boolean(viewVitalSings)
  const openProgressMenu = Boolean(viewProgressNotes)
  const today = moment()
  const oneMonthBefore = today.clone().subtract(1, 'months')
  const [vitalSignStateDate, setVitalSignStartDate] = useState(
    appointment?.visit_date?.split('T')[0]
  )
  const [vitalReportData, setVitalReportsData] = useState(null)
  const [isVitalsView, setIsVitalSignsView] = useState(false)
  const [openVitalReportsModal, setOpenVitalReportsModal] = useState(false)
  const todayDate = new Date().toISOString().split('T')[0]
  const [selectedPatient, SetSelectedPatient] = useState(null)
  const user = localStorage?.getItem('user')
  const loggedUser = JSON.parse(user)
  const isReceptionist = loggedUser?.RoleId == 7
  const isDoctor = loggedUser?.RoleId == 9
  const isNurse = loggedUser?.RoleId == 10

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // console.log("USerr: ", loggedUser?.RoleId);

  const getStatusSeverity = status => {
    switch (status.toLowerCase()) {
      case 'fulfilled':
        return 'success'
      case 'paid':
        return 'success'
      case 'pending':
        return 'info'
      case 'cancelled':
        return 'error'
      case 'unpaid':
        return 'error'
      case 'booked':
        return 'default'
      case 'waitlisted':
        return 'warning'
      default:
        return 'default'
    }
  }

  const getBgBasedOnStatus = status => {
    switch (status.toLowerCase()) {
      case 'fulfilled':
        return '!bg-[#2E7D32]'
      case 'paid':
        return '!bg-[#2E7D32]'
      case 'pending':
        return '!bg-[#0288D1]'
      case 'cancelled':
        return '!bg-[#D32F2F]'
      case 'unpaid':
        return '!bg-[#D32F2F]'
      case 'booked':
        return 'default'
      case 'waitlisted':
        return '!bg-yellow-500'
      default:
        return '!bg-[#333]'
    }
  }

  const handleStatusChange = newStatus => {
    setStatus(newStatus)
    setAppointmentId(appointment.visit_id)
    handleClickOpen()
  }

  function getDoctorName (id) {
    return Doctors?.data?.find(doc => doc?.practitioner_id === id).name
  }

  const PrintSaleInvoice = (id, visitData) => {
    let payload = { visit_detail: visitData }
    ShowInvocieApi(setLoading, id, payload).then(response => {
      if (response?.status === 200) {
        navigate('/receptionist/show-invoice', {
          state: { data: response?.data }
        })
      }
    })
  }
  const PrintCompanyInvoice = data => {
    ShowInsuranceInvocieApi(setLoading, data).then(response => {
      if (response?.status === 200) {
        navigate('/receptionist/show-invoice', {
          state: { data: response?.data }
        })
      }
    })
  }
  const data = [
    { value: 'Waitlisted', label: 'Waitlisted' },
    { value: 'Fulfilled', label: 'Fulfilled' },
    { value: 'Cancelled', label: 'Cancelled' }
  ]

  const handleViewReports = async (patient, isViewVitalSign) => {
    console.log(isViewVitalSign)
    if (isViewVitalSign) {
      setIsVitalSignsView(true)
    } else {
      setIsVitalSignsView(false)
    }
    SetSelectedPatient(patient)
    let payload = {
      patient_id: patient?.patient_id,
      from_date: vitalSignStateDate
    }
    let reportsData = await viewVitalReports(setLoading, payload)
    setViewVitalSings(false)
    setViewProgressNotes(false)

    if (reportsData?.data) {
      setVitalReportsData(reportsData)
      setOpenVitalReportsModal(true)
    } else {
      toast.error('No reports data found.')
    }
  }

  const handleUpdateAppointmentStatus = async () => {
    if (status == 'Cancelled' && appointment?.payment_status == 'Paid') {
      let maxLimit = appointment?.patient?.insurance_plans?.[0]?.max_limit
      const storedPayers = JSON.parse(localStorage.getItem('hisPayers')) || {}
      const selectedPayer = storedPayers.find(
        payer =>
          payer?.rcm_ref == appointment?.patient?.insurance_plans?.[0]?.payer_id
      )

      let customersData = await getCustomerList(setLoading)
      let selectedPatientId = customersData?.data?.find(
        data => data?.his_refrence == appointment?.patient?.patient_id
      )?.id

      const commonData = {
        date: appointment?.visit_date,
        paid: false,
        item_details:
          appointment?.patient?.beneficiary_type === 'Cash'
            ? calculateCashDetails(appointment?.items)
            : calculateShares(appointment?.items, maxLimit),
        customer_id: Number(selectedPatientId),
        payment_method: appointment?.payment_method,
        tax_type: 'percentage',
        tax_amount: 15,
        invoice_ref: appointment?.invoice_no
      }
      const invoiceData =
        appointment?.patient?.beneficiary_type === 'Cash'
          ? {
              ...commonData,
              invoice_type: 'sales'
            }
          : {
              ...commonData,
              invoice_type: 'credit',
              company_id: Number(selectedPayer?.license_id)
            }

      await cancelInvoice(setLoading, invoiceData).then(res => {
        if (res) {
          updateAppointment(setLoading, {
            status: status,
            visit_id: appointmentId
          }).then(res => {
            if (res) {
              window.location.reload()
            }
          })
        }
      })
    } else {
      updateAppointment(setLoading, {
        status: status,
        visit_id: appointmentId
      }).then(res => {
        if (res) {
          window.location.reload()
        }
      })
    }
    handleClose()
    setOpen(false)
  }

  const calculateCashDetails = ItemsData => {
    let items = ItemsData || []

    const detailedItems = items.map(itm => {
      const unitPrice = Number(itm.unit_price) || 0
      const quantity = parseFloat(itm.quantity) || 0

      const itemAmount = unitPrice * quantity

      const discountAmount = Number(itm?.discount_amount) || 0
      const discountType = itm?.discount_type || ''
      let itemDiscount = 0

      if (discountType === 'percentage') {
        itemDiscount = (unitPrice * quantity * discountAmount) / 100
      } else if (discountType === 'fixed') {
        itemDiscount = discountAmount
      }

      // Calculate tax for the specific item
      const itemTax = itm.tax_check
        ? CalculateTax({ values: { items: [itm] } })
        : 0

      const itemNetTotal = itemAmount - itemDiscount + itemTax

      return {
        unitPrice,
        quantity: Number(quantity),
        item_code: itm?.service_code || '',
        item_name: itm?.service_desc || '',
        item_type: itm?.service_type || '',
        discount_type: discountType,
        discountAmount: Number(itemDiscount.toFixed(2)),
        discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
        itemAmount: parseFloat(itemAmount.toFixed(2)),
        patientShare: parseFloat(itemNetTotal.toFixed(2)),
        companyShare: 0,
        patientTax: parseFloat(itemTax.toFixed(2)),
        companyTax: 0
      }
    })

    const totalAmount = detailedItems
      .map(itm => itm.itemAmount)
      .reduce((acc, curr) => acc + curr, 0)

    const totalDiscount = detailedItems
      .map(itm => itm.discountAmount)
      .reduce((acc, curr) => acc + curr, 0)

    const totalTax = detailedItems
      .map(itm => itm.patientTax)
      .reduce((acc, curr) => acc + curr, 0)

    const netTotal = detailedItems
      .map(itm => itm.patientShare)
      .reduce((acc, curr) => acc + curr, 0)

    return {
      itemBreakdown: detailedItems,
      totals: {
        patientShareTotal: parseFloat(totalAmount.toFixed(2)),
        companyShareTotal: 0,
        patientTaxTotal: parseFloat(totalTax.toFixed(2)),
        companyTaxTotal: 0,
        discountedAmount: parseFloat(totalDiscount.toFixed(2)),
        totalAmount:
          parseFloat(totalAmount.toFixed(2)) -
          parseFloat(totalDiscount.toFixed(2)) +
          parseFloat(totalTax.toFixed(2))
      }
    }
  }

  function CalculateTax (itemsdata) {
    const items = itemsdata?.values?.items || []

    const taxableAmount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const unitPrice = Number(itm?.unit_price) || 0
        const quantity = Number(itm?.quantity) || 0
        return unitPrice * quantity
      })
      .reduce((acc, curr) => acc + curr, 0)

    const totalDiscount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const discountAmount = Number(itm?.discount_amount) || 0
        const discountType = itm?.discount_type
        let discount = 0

        if (discountType === 'percentage') {
          discount =
            (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
            100
        } else if (discountType === 'fixed') {
          discount = discountAmount
        }

        return discount
      })
      .reduce((acc, curr) => acc + curr, 0)

    const taxableAmountAfterDiscount = Math.max(
      taxableAmount - totalDiscount,
      0
    )

    const taxAmount = (taxableAmountAfterDiscount * 15) / 100

    return taxAmount
  }

  function calculateShares (itemsData, maxLimit) {
    let items = itemsData || []

    let totalPatientShare = 0
    let totalCompanyShare = 0
    let totalPatientTax = 0
    let totalCompanyTax = 0
    let totalDiscountedAmount = 0
    const nationality = appointment?.patient?.nationality
    const itemBreakdown = items.map(itm => {
      const unitPrice = Number(itm?.unit_price) || 0
      const quantity = itm?.quantity || 0
      const discountAmount = Number(itm?.discount_amount) || 0
      const patientSharePercentage = Number(itm?.patient_share) || 20

      const totalAmount = unitPrice * quantity
      let discountedAmount

      if (itm?.discount_type === 'fixed') {
        discountedAmount = totalAmount - discountAmount
        totalDiscountedAmount += discountAmount
      } else {
        const discountValue = (totalAmount * discountAmount) / 100
        totalDiscountedAmount += discountValue
        discountedAmount = totalAmount - discountValue
      }

      const fullPatientShare = (discountedAmount * patientSharePercentage) / 100
      let currentPatientShare = 0
      let currentCompanyShare = 0

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare
        } else {
          currentPatientShare = remainingLimit
        }
        totalPatientShare += currentPatientShare
      }

      currentCompanyShare = discountedAmount - currentPatientShare
      totalCompanyShare += currentCompanyShare

      let patientTax = 0
      let companyTax = currentCompanyShare * 0.15

      if ((nationality === 'saudi' || nationality === 'sa') && itm?.tax_check) {
        patientTax = currentPatientShare * 0.15
      } else if (itm?.tax_check) {
        patientTax = currentPatientShare * 0.15
      }

      totalPatientTax += patientTax
      totalCompanyTax += companyTax

      return {
        item_code: itm?.service_code || '',
        item_name: itm?.service_desc || '',
        item_type: itm?.service_type || '',
        discount_type: itm?.discount_type || '',
        unitPrice,
        quantity: Number(quantity),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        discountAmount: parseFloat(discountAmount),
        discountedAmount: parseFloat(discountedAmount.toFixed(2)),
        patientShare: parseFloat(currentPatientShare.toFixed(2)),
        companyShare: parseFloat(currentCompanyShare.toFixed(2)),
        patientTax: parseFloat(patientTax.toFixed(2)),
        companyTax: parseFloat(companyTax.toFixed(2))
      }
    })

    return {
      itemBreakdown,
      totals: {
        patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
        companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
        patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
        companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
        discountedAmount: parseFloat(totalDiscountedAmount.toFixed(2)),
        totalAmount:
          parseFloat(totalPatientShare.toFixed(2)) +
          parseFloat(totalCompanyShare.toFixed(2)) +
          parseFloat(totalPatientTax.toFixed(2)) +
          parseFloat(totalCompanyTax.toFixed(2))
      }
    }
  }

  return (
    <>
      <Card
        className={`${
          appointment?.visit_category == 'special_patient'
            ? '!bg-[#fceede]'
            : '!bg-white'
        } shadow-sm hover:shadow-md transition-shadow duration-200 rounded-md`}
        style={{
          border: 'none',
          // backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          padding: '0'
        }}
      >
        <div className='flex items-center w-full'>
          <div
            className={`${getBgBasedOnStatus(
              appointment.status
            )} h-[150px] flex items-center justify-center p-2`}
          >
            <Typography
              variant='subtitle1'
              component='div'
              sx={{ fontSize: '0.8rem', fontWeight: '500', color: '#333' }}
              className='pe-2 text-center'
            >
              <p className=' font-semibold text-[16px] text-white'>Token</p>
              <p className=' font-semibold text-[20px] text-white'>
                {appointment?.token?.token_number}{' '}
              </p>
            </Typography>
          </div>
          <div className='flex-1 p-2'>
            <CardHeader
              title={
                <div className='flex gap-4 items-center justify-between'>
                  <div className='flex items-center gap-4'>
                    <Typography
                      variant='subtitle1'
                      component='div'
                      sx={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        color: '#333'
                      }}
                    >
                      {appointment?.participant}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      component='div'
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        color: '#333'
                      }}
                    >
                      {formatDateTime(appointment?.visit_date)}
                    </Typography>
                    {appointment?.visit_category === 'special_patient' && (
                      <>
                        <div className='relative'>
                          <Tooltip
                            title={
                              <List
                                sx={{
                                  minWidth: 200,
                                  p: 1,
                                  borderRadius: '4px'
                                }}
                              >
                                {appointment?.assigned_nurses?.map(
                                  (nurse, index) => (
                                    <ListItem key={index} sx={{ py: '2px' }}>
                                      <Typography
                                        variant='body2'
                                        sx={{ color: 'common.white' }}
                                      >
                                        {nurse?.name}
                                      </Typography>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#2a2a2a',
                                  color: 'common.white',
                                  boxShadow: 3,
                                  border: '1px solid',
                                  borderColor: '#404040',
                                  p: 0,
                                  '.MuiTooltip-arrow': {
                                    color: '#2a2a2a',
                                    '&:before': {
                                      border: '1px solid #404040'
                                    }
                                  }
                                }
                              }
                            }}
                            arrow
                            placement='bottom-start'
                          >
                            <Chip
                              label={'View Assigned Nurses'}
                              size='small'
                              className='!rounded'
                              sx={{
                                backgroundImage:
                                  'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                color: 'white',
                                fontSize: '0.7rem',
                                px: 0.5,
                                py: 0.25,
                                mr: '2px',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundImage:
                                    'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                                },
                                '& .MuiChip-label': {
                                  p: 0
                                }
                              }}
                            />
                          </Tooltip>
                        </div>

                        <div className='relative'>
                          <Tooltip
                            title={
                              <List
                                sx={{
                                  minWidth: 200,
                                  p: 1,
                                  borderRadius: '4px'
                                }}
                              >
                                {appointment?.assigned_drivers?.drivers?.map(
                                  (driver, index) => (
                                    <ListItem key={index} sx={{ py: '2px' }}>
                                      <Typography
                                        variant='body2'
                                        sx={{ color: 'common.white' }}
                                      >
                                        {driver?.name}
                                      </Typography>
                                    </ListItem>
                                  )
                                )}
                              </List>
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#2a2a2a',
                                  color: 'common.white',
                                  boxShadow: 3,
                                  border: '1px solid',
                                  borderColor: '#404040',
                                  p: 0,
                                  '.MuiTooltip-arrow': {
                                    color: '#2a2a2a',
                                    '&:before': {
                                      border: '1px solid #404040'
                                    }
                                  }
                                }
                              }
                            }}
                            arrow
                            placement='bottom-start'
                          >
                            <Chip
                              label={'View Assigned Drivers'}
                              size='small'
                              className='!rounded'
                              sx={{
                                backgroundImage:
                                  'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                color: 'white',
                                fontSize: '0.7rem',
                                px: 0.5,
                                py: 0.25,
                                mr: '2px',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundImage:
                                    'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                                },
                                '& .MuiChip-label': {
                                  p: 0
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              }
              action={
                <div className='flex gap-1'>
                  {loggedUser.RoleId !== 9 && (
                    <>
                      {appointment?.invoice_id ? (
                        <>
                          <div className='relative'>
                            <Chip
                              label={'Print Sale Invoice'}
                              color={'success'}
                              size='small'
                              onClick={() => openInvoiceBox(!invoiceBox)}
                              className='!mr-[2px] rounded !cursor-pointer'
                              style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                              variant='contained'
                              sx={{
                                backgroundImage:
                                  'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                borderColor: '#f14f3e',
                                color: 'white',
                                '&:hover': {
                                  borderColor: '#f14f3e'
                                },
                                '&:focus': {
                                  borderColor: '#f14f3e'
                                }
                              }}
                            ></Chip>
                            {invoiceBox && (
                              <div className='absolute bg-white border rounded shadow-md top-[35px] w-full z-10 p-0'>
                                {appointment?.invoice_id?.map(
                                  (invoice, index) => (
                                    <Chip
                                      key={index}
                                      label={`Invoice ${index + 1}`}
                                      size='small'
                                      color='default'
                                      onClick={() => {
                                        PrintSaleInvoice(invoice, appointment)
                                      }}
                                      className='w-full rounded !cursor-pointer mb-1'
                                      style={{
                                        fontSize: '0.7rem',
                                        padding: '2px 4px'
                                      }}
                                      variant='contained'
                                      sx={{
                                        backgroundImage:
                                          'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                        borderColor: '#f14f3e',
                                        color: 'white',
                                        '&:hover': {
                                          borderColor: '#f14f3e'
                                        },
                                        '&:focus': {
                                          borderColor: '#f14f3e'
                                        }
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                  {isReceptionist && (
                    <React.Fragment>
                      <Chip
                        label={appointment.payment_status}
                        color={
                          appointment?.payment_status === 'Paid'
                            ? 'success'
                            : 'error'
                        }
                        size='small'
                        className='!mr-[2px] rounded'
                        style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                      />
                    </React.Fragment>
                  )}
                </div>
              }
              className='p-1'
              sx={{ padding: '4px 8px' }}
            />

            <CardContent
              className='text-sm p-2 flex justify-between '
              sx={{ padding: '4px 8px' }}
            >
              <div className='flex flex-col justify-center gap-2 mb-1'>
                <span
                  className='font-semibold text-gray-600'
                  style={{ fontSize: '1rem' }}
                >
                  Doctor
                </span>
                <span className='text-gray-800' style={{ fontSize: '1rem' }}>
                  {appointment?.practitioner?.name}
                </span>
              </div>
              <div className='flex flex-col justify-center gap-2 mb-1'>
                <span
                  className='font-semibold text-gray-600'
                  style={{ fontSize: '1rem' }}
                >
                  Episode Type
                </span>
                <span className='text-gray-800' style={{ fontSize: '1rem' }}>
                  {appointment?.episode_type}
                </span>
              </div>
              <div className='flex flex-col justify-center gap-2 mb-1'>
                <span
                  className='font-semibold text-gray-600'
                  style={{ fontSize: '1rem' }}
                >
                  Episode No
                </span>
                <span className='text-gray-800' style={{ fontSize: '1rem' }}>
                  {appointment?.episode_id}
                </span>
              </div>
              <div className='flex flex-col justify-center gap-2 mb-1'>
                <span
                  className='font-semibold text-gray-600'
                  style={{ fontSize: '1rem' }}
                >
                  Visit Category
                </span>
                {appointment?.visit_category == 'special_patient' ? (
                  <span className='text-green-800' style={{ fontSize: '1rem' }}>
                    Special Visit
                  </span>
                ) : (
                  <span className='text-gray-800' style={{ fontSize: '1rem' }}>
                    Normal
                  </span>
                )}
              </div>
              <div className='flex flex-col justify-center gap-2 mb-1'>
                <span
                  className='font-semibold text-gray-600'
                  style={{ fontSize: '1rem' }}
                >
                  Comment
                </span>
                <span className='text-gray-800' style={{ fontSize: '1rem' }}>
                  {appointment?.comment}
                </span>
              </div>
            </CardContent>

            <CardActions
              className='flex justify-between pt-1 !px-0 !pb-0'
              sx={{ padding: '4px 8px' }}
            >
              <div className='flex gap-2 items-center justify-end w-full'>
                {loggedUser.RoleId !== 9 && loggedUser.RoleId !== 10 && (
                  <>
                    <label
                      className='text-xs font-medium text-gray-600'
                      style={{ fontSize: '0.7rem' }}
                    >
                      Change Status
                    </label>
                    <select
                      id='Status'
                      name='Status'
                      value={status}
                      className='rounded p-1 text-xs border-gray-300 bg-gray-50'
                      style={{
                        fontSize: '0.7rem',
                        padding: '2px 4px',
                        flexGrow: 1
                      }}
                      onChange={e => handleStatusChange(e.target.value)}
                      disabled={
                        appointment?.status === 'Cancelled' ||
                        appointment?.status === 'Fulfilled'
                      }
                    >
                      <option value='Pending'>Pending</option>
                      {data.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </>
                )}
                {!isReceptionist && appointment?.emr_numbers?.length
                  ? appointment?.emr_numbers?.map(emrNumber => (
                      <Chip
                        label={emrNumber}
                        color={'success'}
                        size='small'
                        onClick={() => {
                          let patientId = patientID
                            ? patientID
                            : appointment?.patient?.patient_id
                          console.log(patientId)
                          if (patientId) {
                            navigate('/his/emr-edit', {
                              state: {
                                patientID: patientId,
                                appointment: appointment,
                                emrNumber
                              }
                            })
                          } else {
                            toast?.error('Please Select Patient First')
                          }
                        }}
                        className='rounded !cursor-pointer'
                        style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                        variant='contained'
                        sx={{
                          backgroundImage:
                            'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                          borderColor: '#f14f3e',
                          color: 'white',
                          '&:hover': {
                            borderColor: '#f14f3e'
                          },
                          '&:focus': {
                            borderColor: '#f14f3e'
                          }
                        }}
                      />
                    ))
                  : null}

                {(!isReceptionist &&
                  !appointment?.emr_numbers?.length &&
                  appointment?.payment_status === 'Paid') ||
                (!isReceptionist &&
                  appointment?.visit_category === 'special_patient' &&
                  !appointment?.emr_numbers?.length) ? (
                  <Chip
                    label={'Create EMR'}
                    color={'success'}
                    size='small'
                    onClick={() => {
                      let patientId = patientID
                        ? patientID
                        : appointment?.patient?.patient_id
                      if (patientId) {
                        navigate('/his/emr', {
                          state: {
                            patientID: patientID,
                            appointment: appointment
                          }
                        })
                      } else {
                        toast?.error('Please Select Patient First')
                      }
                    }}
                    className='rounded !cursor-pointer'
                    style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                    variant='contained'
                    sx={{
                      backgroundImage:
                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                      borderColor: '#f14f3e',
                      color: 'white',
                      '&:hover': {
                        borderColor: '#f14f3e'
                      },
                      '&:focus': {
                        borderColor: '#f14f3e'
                      }
                    }}
                  ></Chip>
                ) : null}

                {(appointment?.payment_status === 'UnPaid' && isReceptionist) ||
                appointment?.items?.some(
                  itm => itm?.status === 'UnPaid' && isReceptionist
                ) ? (
                  <Tooltip title='Pay Bill'>
                    <Chip
                      label={'Pay Bill'}
                      color={'success'}
                      size='small'
                      onClick={() => {
                        navigate('/receptionist/create-visit', {
                          state: { appointment: appointment }
                        })
                      }}
                      className='rounded !cursor-pointer'
                      style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                      variant='contained'
                      sx={{
                        backgroundImage:
                          'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                        borderColor: '#f14f3e',
                        color: 'white',
                        '&:hover': {
                          borderColor: '#f14f3e'
                        },
                        '&:focus': {
                          borderColor: '#f14f3e'
                        }
                      }}
                    />
                  </Tooltip>
                ) : null}
                {(
                  <Chip
                    label={'Form Entries'}
                    color={'success'}
                    size='small'
                    onClick={() => {
                      navigate('/receptionist/form-entries', {
                        state: {
                          visitId: appointment?.visit_id,
                          patient: appointment?.patient,
                          appointment: appointment
                        }
                      })
                    }}
                    className='!mr-[2px] rounded !cursor-pointer'
                    style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                    variant='contained'
                    sx={{
                      backgroundImage:
                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                      borderColor: '#f14f3e',
                      color: 'white',
                      '&:hover': {
                        borderColor: '#f14f3e'
                      },
                      '&:focus': {
                        borderColor: '#f14f3e'
                      }
                    }}
                  />
                )}
                {isReceptionist && appointment?.emr_numbers?.length && (
                  <Chip
                    label={'View Emr'}
                    color={'success'}
                    size='small'
                    onClick={() => {
                      navigate('/receptionist/view-emr', {
                        state: {
                          emr_numbers: appointment?.emr_numbers[0]
                        }
                      })
                    }}
                    className='!mr-[2px] rounded !cursor-pointer'
                    style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                    variant='contained'
                    sx={{
                      backgroundImage:
                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                      borderColor: '#f14f3e',
                      color: 'white',
                      '&:hover': {
                        borderColor: '#f14f3e'
                      },
                      '&:focus': {
                        borderColor: '#f14f3e'
                      }
                    }}
                  />
                )}
                {!isReceptionist &&
                  appointment?.visit_category == 'special_patient' && (
                    <div className=''>
                      <div className='flex items-center justify-between gap-2 border !border-primary p-1 rounded'>
                        <h5>Reports</h5>
                        <div className='flex flex-col  items-end gap-1'>
                          <div>
                            <Button
                              id='basic-button'
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup='true'
                              aria-expanded={open ? 'true' : undefined}
                              className=' !bg-gray-500 !text-white hover:opacity-80 !h-[24px] !capitalize !px-2 !text-[12px]'
                              endIcon={<ArrowDropDownIcon />}
                              onClick={event =>
                                setViewVitalSings(event.currentTarget)
                              }
                              size='small'
                            >
                              View Vital Sings
                            </Button>
                            <Menu
                              id='basic-menu'
                              anchorEl={viewVitalSings}
                              open={openVitalSignMenu}
                              onClose={() => setViewVitalSings(null)}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                              style={{
                                width: '300px !important',
                                borderRadius: '10px !important'
                              }}
                              className='!rounded-md !p-0'
                            >
                              <MenuItem className='w-[300px] !hover:bg-white !p-0'>
                                <div className='flex flex-col p-2 px-3 w-full'>
                                  <label className='text-[14px] mb-1'>
                                    Start Date
                                  </label>
                                  <input
                                    type='date'
                                    value={vitalSignStateDate}
                                    onChange={e =>
                                      setVitalSignStartDate(e.target.value)
                                    }
                                    placeholder='select start date'
                                    className='w-full h-[25px] rounded border-gray-500 text-[14px]'
                                  />
                                  <button
                                    style={{
                                      backgroundImage:
                                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                      borderColor: '#f14f3e',
                                      color: 'white',
                                      width: '100px',
                                      padding: '4px 5px',
                                      fontSize: '12px',
                                      marginTop: '10px',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        borderColor: '#f14f3e'
                                      },
                                      '&:focus': {
                                        borderColor: '#f14f3e'
                                      }
                                    }}
                                    onClick={() =>
                                      handleViewReports(
                                        appointment?.patient,
                                        true
                                      )
                                    }
                                  >
                                    View Reports
                                  </button>
                                </div>
                              </MenuItem>
                            </Menu>
                          </div>
                          <div>
                            <Button
                              id='basic-button'
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup='true'
                              aria-expanded={open ? 'true' : undefined}
                              className=' !bg-gray-500 !text-white hover:opacity-80 !h-[24px] !capitalize !px-2 !text-[12px]'
                              endIcon={<ArrowDropDownIcon />}
                              onClick={event =>
                                setViewProgressNotes(event.currentTarget)
                              }
                              size='small'
                            >
                              View Progress Notes
                            </Button>
                            <Menu
                              id='basic-menu'
                              anchorEl={viewProgressNotes}
                              open={openProgressMenu}
                              onClose={() => setViewProgressNotes(null)}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button'
                              }}
                              style={{
                                width: '300px !important',
                                borderRadius: '10px !important'
                              }}
                              className='!rounded-md !p-0'
                            >
                              <MenuItem className='w-[300px] !hover:bg-white !p-0'>
                                <div className='flex flex-col p-2 px-3 w-full'>
                                  <label className='text-[14px] mb-1'>
                                    Start Date
                                  </label>
                                  <input
                                    type='date'
                                    value={vitalSignStateDate}
                                    onChange={e =>
                                      setVitalSignStartDate(e.target.value)
                                    }
                                    placeholder='select start date'
                                    className='w-full h-[25px] rounded border-gray-500 text-[14px]'
                                  />
                                  <button
                                    style={{
                                      backgroundImage:
                                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                                      borderColor: '#f14f3e',
                                      color: 'white',
                                      width: '100px',
                                      padding: '4px 5px',
                                      fontSize: '12px',
                                      marginTop: '10px',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        borderColor: '#f14f3e'
                                      },
                                      '&:focus': {
                                        borderColor: '#f14f3e'
                                      }
                                    }}
                                    onClick={() =>
                                      handleViewReports(
                                        appointment?.patient,
                                        false
                                      )
                                    }
                                  >
                                    View Reports
                                  </button>
                                </div>
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </CardActions>
          </div>
        </div>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          className='py-4 text-green-600 !font-[700]'
        >
          {'Confirm Status Change'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className='mb-4'>
            Are you sure you want to change the status for Appointment No
            <span className='font-[600] text-gray-800 ms-2'>
              {appointment?.token?.token_number}?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='error'
            size='small'
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='success'
            size='small'
            onClick={handleUpdateAppointmentStatus}
          >
            {loading ? <Spinner size='15px' /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <ViewVitalReportsModal
        isVitalSigns={isVitalsView}
        patientData={selectedPatient}
        visit_date={appointment?.visit_date || ''}
        openVitalReportsModal={openVitalReportsModal}
        setOpenVitalReportsModal={setOpenVitalReportsModal}
        reportsData={vitalReportData}
      />
      <AppLoader setShowLoader={setLoading} showLoader={loading} />
    </>
  )
}

export default PatientCard
