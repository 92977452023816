import { useFormikContext, Field } from 'formik';
import React from 'react';

export default function SupportingInfoVitalSigns() {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const defaultDate = new Date().toISOString().split('T')[0];

  // const handleDateChange = (field, value) => {
  //   // Update the date for all vital signs
  //   const updatedVitalSigns = values.vitalSigns.map(vitalSign => ({
  //     ...vitalSign,
  //     [field]: value
  //   }));
  //   setFieldValue('vitalSigns', updatedVitalSigns);
  //   setFieldValue(field, value);
  // };

  const vitalSignReasonLOVs = {
    'vital-sign-height': [{ id: '999.9', name: 'Not Measured' }],
    'vital-sign-weight': [{ id: '999.9', name: 'Not Collected' }],
    'vital-sign-systolic': [
      { id: '888', name: 'Not measurable' },
      { id: '999', name: 'Unknown' }
    ],
    'vital-sign-diastolic': [
      { id: '888', name: 'Not measurable' },
      { id: '999', name: 'Unknown' }
    ],
    temperature: [
      { id: '88', name: 'Not Obtained' },
      { id: '99', name: 'Unknown' }
    ],
    pulse: [
      { id: '997', name: 'Cardiac Arrest' },
      { id: '998', name: 'Not Recorded' },
      { id: '999', name: 'Not Stated' }
    ],
    'respiratory-rate': [
      { id: '00', name: 'Artificial Ventilation' },
      { id: '88', name: 'Not Obtained' },
      { id: '99', name: 'Unknown' }
    ],
    'oxygen-saturation': [
      { id: '888', name: 'Not Obtained' },
      { id: '999', name: 'Unknown' }
    ],
    SP02: [
      { id: '88', name: 'Not Obtained' },
      { id: '99', name: 'Unknown' }
    ],
    'pain-score': [
      { id: '88', name: 'Not Obtained' },
      { id: '99', name: 'Unknown' }
    ],
    RBS: [
      { id: '88', name: 'Not Obtained' },
      { id: '99', name: 'Unknown' }
    ]
  };

  return (
    <div className='p-3 mb-2 !bg-gray-100 rounded shadow-sm'>
      <div className='inventory-svg mb-2 flex gap-3 items-center'>
        <div className='inventory-svg'>
          <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='34' height='34' rx='8' fill='#F4F6F8'></rect>
            <path
              d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
              fill='#F5793B'
            ></path>
            <path d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z' fill='#F5793B'></path>
          </svg>
        </div>
        <div className='text-[#344054] text-[18px] font-semibold'>Vital Signs</div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mb-2'>
        <div className='flex flex-col'>
          <label className='text-sm'>Start Date:</label>
          <Field
            type='date'
            name={'startDate'}
            // value={values.vitalSigns[0]?.startDate}
            // onChange={e => handleDateChange('startDate', e.target.value)}
            className={`border p-2 rounded text-sm ${touched?.startDate && errors?.startDate ? 'border-red-500' : ''}`}
          />

          {touched?.startDate && errors?.startDate && <div className='text-red-500 text-xs'>{errors?.startDate}</div>}
        </div>

        <div className='flex flex-col'>
          <label className='text-sm'>End Date:</label>
          <Field
            type='date'
            name={`endDate`}
            // value={values.vitalSigns[0]?.endDate}
            // onChange={e => handleDateChange('endDate', e.target.value)}
            className={`border p-2 rounded text-sm ${touched?.endDate && errors?.endDate ? 'border-red-500' : ''}`}
          />
          {touched?.endDate && errors?.endDate && <div className='text-red-500 text-xs'>{errors?.endDate}</div>}
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-10 gap-2'>
        {values.vitalSigns.map((vitalSign, index) => (
          <div key={index} className='flex w-full flex-col gap-2'>
            <div className='flex w-full'>
              <div className='flex flex-col w-[75%]'>
                <label className='text-xs capitalize whitespace-nowrap'>{vitalSign.category.replace(/-/g, ' ')}</label>
                <Field
                  name={`vitalSigns[${index}].value`}
                  type='number'
                  className={`border p-2 text-sm rounded-l ${
                    touched.vitalSigns?.[index]?.value && errors.vitalSigns?.[index]?.value ? 'border-red-500' : ''
                  }`}
                  onChange={e => {
                    let value = e?.target?.value;
                    setFieldValue(`vitalSigns[${index}].value`, value);
                    if (value) {
                      setFieldValue(`vitalSigns[${index}].reason`, '');
                    }
                  }}
                  placeholder={`Enter ${vitalSign.category}`}
                />
                {touched.vitalSigns?.[index]?.value && errors.vitalSigns?.[index]?.value && (
                  <div className='text-red-500 text-xs'>{errors.vitalSigns[index].value}</div>
                )}
              </div>

              <div className='flex flex-col w-[25%]'>
                <label className='text-xs'>Unit</label>
                <Field
                  name={`vitalSigns[${index}].valueUnit`}
                  type='text'
                  className='border p-2 text-sm rounded-r !bg-primary text-white'
                  disabled
                  value={vitalSign.valueUnit}
                />
              </div>
            </div>

            {vitalSignReasonLOVs[vitalSign.category] && (
              <div className='flex flex-col w-full'>
                <label className='text-xs'>Reason</label>
                <Field
                  as='select'
                  name={`vitalSigns[${index}].reason`}
                  className='border p-2 rounded text-sm'
                  value={vitalSign.reason || ''}
                  onChange={e => {
                    let value = e?.target?.value;
                    setFieldValue(`vitalSigns[${index}].reason`, value);
                    if (value) {
                      setFieldValue(`vitalSigns[${index}].value`, '');
                    }
                  }}
                >
                  <option value=''>Select Reason</option>
                  {vitalSignReasonLOVs[vitalSign.category].map(reason => (
                    <option key={reason.id} value={reason.id}>
                      {reason.name}
                    </option>
                  ))}
                </Field>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
