import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, IconButton, Tooltip } from '@mui/material';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { BiSearch, BiX } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import _, { values } from 'lodash';

import './homecare.css';
import {
  createNewInvoice,
  CreateOrdersApi,
  getCashBankAccounts,
  getCompanyList,
  getCustomerList,
  getAllPractitioners,
  getPriceListByID
} from '../components/ApiMethods';
import AppLoader from '../../../components/AppLoader';
import SharedInput from '../components/SharedInput';
import SharedDropdown from '../components/SharedDropdown';
import SharedSearchDropdown from './SharedSearchDropdown';
import priceList from '../../../gql/priceList';
import { getCurrentDateTime } from './NewAppointment';
import Spinner from './Spinner';
import { truncateText } from '../../../util/truncateText';

function CreateOrder() {
  const [open, setOpen] = useState(false);
  const [payerId, setPayerID] = useState('2117');
  const formikRef = useRef(null);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [ref, setRef] = useState('');
  const [patientType, setPatientType] = useState(null);
  const navigate = useNavigate();
  const [priceListId, setPriceListId] = useState('0');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [cashAccount, setCashAccounts] = useState([]);
  const [payerLicense, setPayerLicense] = useState('2117');
  const location = useLocation();
  const appointmentBillToPay = location?.state?.appointment;
  const currentDate = new Date().toISOString().split('T')[0];

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: '',
      payerId: payerId
    },
    skip: true
  });
  const handleSearch = term => {
    setItemsLoading(true);
    refetchSearchPriceListCode({
      input: term,
      payerId: payerId
    }).then(data => {
      setItemsLoading(false);
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  const calculateCashDetails = formikProps => {
    const items = formikProps?.values?.items || [];
    const detailedItems = items.map(itm => {
      const unitPrice = Number(itm.unit_price) || 0;
      const quantity = parseFloat(itm.quantity) || 0;

      const itemAmount = unitPrice * quantity;

      const discountAmount = Number(itm?.discount_amount) || 0;
      const discountType = itm?.discount_type || '';
      let itemDiscount = 0;

      if (discountType === 'percentage') {
        itemDiscount = (unitPrice * quantity * discountAmount) / 100;
      } else if (discountType === 'fixed') {
        itemDiscount = discountAmount;
      }

      // Calculate tax for the specific item
      const itemTax = itm.tax_check ? CalculateTax({ values: { items: [itm] } }) : 0;

      const itemNetTotal = itemAmount - itemDiscount + itemTax;

      return {
        unitPrice,
        quantity: Number(quantity),
        item_code: itm?.node_standard_code || '',
        item_name: itm?.non_standard_desc || '',
        item_type: itm?.service_type || '',
        discount_type: discountType,
        discountAmount: Number(itemDiscount.toFixed(2)),
        discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
        itemAmount: parseFloat(itemAmount.toFixed(2)),
        patientShare: parseFloat(itemNetTotal.toFixed(2)),
        companyShare: 0,
        patientTax: parseFloat(itemTax.toFixed(2)),
        companyTax: 0
      };
    });

    const totalAmount = detailedItems.map(itm => itm.itemAmount).reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = detailedItems.map(itm => itm.discountAmount).reduce((acc, curr) => acc + curr, 0);

    const totalTax = detailedItems.map(itm => itm.patientTax).reduce((acc, curr) => acc + curr, 0);

    const netTotal = detailedItems.map(itm => itm.patientShare).reduce((acc, curr) => acc + curr, 0);

    return {
      itemBreakdown: detailedItems,
      totals: {
        patientShareTotal: parseFloat(totalAmount.toFixed(2)),
        companyShareTotal: 0,
        patientTaxTotal: parseFloat(totalTax.toFixed(2)),
        companyTaxTotal: 0,
        discountedAmount: parseFloat(totalDiscount.toFixed(2)),
        totalAmount:
          parseFloat(totalAmount.toFixed(2)) - parseFloat(totalDiscount.toFixed(2)) + parseFloat(totalTax.toFixed(2))
      }
    };
  };

  const getNationality = () => {
    const patient = patientList?.data?.find(item => item.id === ref);
    return patient ? patient.nationality : null;
  };

  function calculateShares(formikProps, maxLimit) {
    let items = formikProps?.values?.items || [];

    let totalPatientShare = 0;
    let totalCompanyShare = 0;
    let totalPatientTax = 0;
    let totalCompanyTax = 0;
    let totalDiscountedAmount = 0;
    const nationality = getNationality();
    const itemBreakdown = items.map(itm => {
      const unitPrice = Number(itm?.unit_price) || 0;
      const quantity = itm?.quantity || 0;
      const discountAmount = Number(itm?.discount_amount) || 0;
      const patientSharePercentage = Number(itm?.patient_share);

      const totalAmount = unitPrice * quantity;
      let discountedAmount;

      if (itm?.discount_type === 'fixed') {
        discountedAmount = totalAmount - discountAmount;
        totalDiscountedAmount += discountAmount;
      } else {
        const discountValue = (totalAmount * discountAmount) / 100;
        totalDiscountedAmount += discountValue;
        discountedAmount = totalAmount - discountValue;
      }

      const fullPatientShare = (discountedAmount * patientSharePercentage) / 100;
      let currentPatientShare = 0;
      let currentCompanyShare = 0;

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare;
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare;
        } else {
          currentPatientShare = remainingLimit;
        }
        totalPatientShare += currentPatientShare;
      }
      currentCompanyShare = discountedAmount - currentPatientShare;
      totalCompanyShare += currentCompanyShare;

      let patientTax = 0;
      let companyTax = currentCompanyShare * 0.15;

      if ((nationality === 'saudi' || nationality === 'sa') && itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      } else if (itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      }

      totalPatientTax += patientTax;
      totalCompanyTax += companyTax;

      return {
        item_code: itm?.non_standard_code || '',
        item_name: itm?.non_standard_desc || '',
        item_type: itm?.service_type || '',
        discount_type: itm?.discount_type,
        unitPrice,
        quantity: Number(quantity),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        discountAmount: parseFloat(discountAmount),
        discountedAmount: parseFloat(discountedAmount.toFixed(2)),
        patientShare: parseFloat(currentPatientShare.toFixed(2)),
        companyShare: parseFloat(currentCompanyShare.toFixed(2)),
        patientTax: parseFloat(patientTax.toFixed(2)),
        companyTax: parseFloat(companyTax.toFixed(2))
      };
    });

    return {
      itemBreakdown,
      totals: {
        patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
        companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
        patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
        companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
        discountedAmount: parseFloat(totalDiscountedAmount.toFixed(2)),
        totalAmount:
          parseFloat(totalPatientShare.toFixed(2)) +
          parseFloat(totalCompanyShare.toFixed(2)) +
          parseFloat(totalPatientTax.toFixed(2)) +
          parseFloat(totalCompanyTax.toFixed(2))
      }
    };
  }

  const handleSearchChange = event => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm.length >= 3) {
      setItemsLoading(true);
      debouncedSearch(newSearchTerm);
    } else {
      setSearchResult([]);
      setItemsLoading(false);
    }
  };
  const debouncedSearch = _.debounce(handleSearch, 1000);

  const handleSelectSearchItem = (formik, item) => {
    const newItem = {
      ...item,
      quantity: 1,
      discount_type: 'percentage',
      discount_amount: 0,
      tax_check: false,
      type: item?.service_type,
      standard_code: item?.non_standard_code,
      node_standard_code: item?.non_standard_code,
      standard_description: item?.service_desc,
      ...(formik?.values?.AppoinmentType === 'Insurance' && {
        patient_share:
          (selectedPatient?.insurance_plans?.length > 0 && selectedPatient?.insurance_plans[0]?.patient_share) ||
          selectedPatient?.insurance_plans[0]?.patient_share == 0
            ? selectedPatient?.insurance_plans[0]?.patient_share
            : 20
      }),
      ...(formik?.values?.AppoinmentType === 'Insurance' ? { discount_amount: 0 } : { discount_amount: 0 })
    };
    formik.setFieldValue('items', [...formik.values.items, newItem]);

    setSearchResult([]);
    setSearchTerm('');
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const getuser = localStorage.getItem('user');
  const user = JSON.parse(getuser);

  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [paidToday, setPaidToday] = useState(true);

  const paymentMethods = [
    { label: 'Mada', value: 'Mada' },
    { label: 'Cash', value: 'cash' },
    { label: 'Credit', value: 'credit' },
    { label: 'Bank', value: 'bank' }
  ];

  useEffect(() => {
    getCustomerList(setLoading, setPatientList);
    getAllPractitioners(setLoading, setDoctorList);
    // getitemsList(setLoading, setItemsList);
    fetchPriceList(priceListId);
    getCompanyList(setLoading, setCompanyList);
    getCashBankAccounts(setLoading, setCashAccounts);
  }, []);

  const hisPayers =
    localStorage.getItem('hisPayers') && localStorage.getItem('hisPayers') !== 'undefined'
      ? JSON.parse(localStorage.getItem('hisPayers'))
      : [];

  const [validate, setValidate] = useState('Cash');
  const [maxLimit, setMaxLimit] = useState('100');

  useEffect(() => {
    if (patientList?.data && appointmentBillToPay?.patient?.patient_id) {
      setRef(patientList.data.find(patient => patient.his_refrence === appointmentBillToPay?.patient?.patient_id)?.id);
      const selectedPatient = patientList?.data?.find(
        patient => patient.his_refrence === appointmentBillToPay?.patient?.patient_id
      );
      setPatientType(selectedPatient.beneficiary_type);
    }
  }, [patientList, appointmentBillToPay]);

  const initialValues = {
    no_of_sessions: 1,
    isSendForApproval: false,
    listInvoices: false,
    AppoinmentType: 'Cash',
    service_categories: '',
    service_type: '',
    visit_type: '',
    reasons: '',
    isReferral: false,
    speciality: '',
    practitionerName: '',
    practitionerRole: '',
    approval_no: '',
    approval_date: currentDate,
    visit_date: getCurrentDateTime(),
    slots: '',
    customer_id: '',
    customer_name: '',
    practitioner_id: '',
    comment: '',
    // date: new Date().toISOString().split("T")[0],
    // date: getCurrentDateTime(),
    payment_method: 'cash',
    // description: '',
    paid: false,
    branch_id: 1,
    business_id: 1,
    invoice_type: 'sales',
    insurancePlanId: '',
    payerId: '',
    payerName: '',
    insurancePlanIndex: '',
    // tax_check: false,
    items: [],
    company_id: payerId,
    diagnosisArr: [],
    vitalSigns: [
      {
        category: 'vital-sign-systolic',
        startDate: currentDate,
        endDate: currentDate,
        value: '',
        valueUnit: 'mm[Hg]'
      },
      {
        category: 'vital-sign-diastolic',
        startDate: '',
        endDate: '',
        // value: "",
        value: '',
        valueUnit: 'mm[Hg]'
      },
      {
        category: 'vital-sign-height',
        startDate: '',
        endDate: '',
        value: '',
        valueUnit: 'cm'
      },
      {
        category: 'vital-sign-weight',
        startDate: '',
        endDate: '',
        value: '',
        valueUnit: 'kg'
      },
      {
        category: 'temperature',
        startDate: '',
        endDate: '',
        value: '',
        valueUnit: 'Cel'
      },
      {
        category: 'respiratory-rate',
        startDate: '',
        endDate: '',
        value: '',
        valueUnit: '/min'
      },
      {
        category: 'pulse',
        startDate: '',
        endDate: '',
        value: '',
        valueUnit: '/min'
      }
    ],
    supportingInfos: [
      {
        category: 'chief-complaint',
        codeText: '',
        code: '',
        valueUnit: '',
        value: ''
      }
    ]
  };

  const validationSchema = yup.object().shape({
    AppoinmentType: paidToday ? yup.string().required('Appointment Type is required') : null,
    speciality: yup.string().required('Speciality is required'),
    payment_method: yup.string().when('listInvoices', {
      is: true,
      then: () => yup.string().required('Payment method is required'),
      otherwise: () => yup.string().notRequired()
    }),
    visit_date: yup.string().required('Start date is required'),
    customer_id: yup.string().required('Patient is required'),
    practitioner_id: yup.string().required('Practitioner is required'),
    comment: yup.string(),
    // date: null,
    paid: yup.string().required('Paid status is required ')
  });

  function CalculateTax(formikProps) {
    const items = formikProps?.values?.items || [];

    const taxableAmount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .filter(itm => itm?.tax_check)
      .map(itm => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === 'percentage') {
          discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
        } else if (discountType === 'fixed') {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxableAmountAfterDiscount = Math.max(taxableAmount - totalDiscount, 0);

    const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

    return taxAmount;
  }

  function CalculateNetTotal(formikProps) {
    const items = formikProps?.values?.items || [];

    const totalAmount = items
      .map(itm => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .map(itm => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === 'percentage') {
          discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
        } else if (discountType === 'fixed') {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxAmount = CalculateTax(formikProps);

    const netTotal = Math.max(totalAmount - totalDiscount + taxAmount, 0);
    console.log('Net Total:', netTotal);

    return netTotal;
  }

  useEffect(() => {
    validate === 'Cash' && setPayerID('2117');
  }, [validate]);

  const handleSubmit = async (values, { resetForm }) => {
    if (!values?.items?.length) {
      toast.error('At least one item is required');
      return;
    }

    if (values?.listInvoices && values?.paid && !values?.account_code) {
      toast.error('Please select account');
      return;
    }

    let selectedPatient = patientList?.data?.find(patient => patient.his_refrence === values?.customer_id);
    if (selectedPatient) {
      selectedPatient.full_name = selectedPatient?.name;
      selectedPatient.id = selectedPatient.id;
      selectedPatient.patient_id = String(selectedPatient.id);
      selectedPatient.his_ref = selectedPatient?.his_refrence;
      selectedPatient.file_no = selectedPatient?.file_number;
    }

    const selectedDoctor = doctorList?.data?.find(doc => doc.practitioner_id === values?.practitioner_id);
    let supportingInfos =
      values?.supportingInfos && values?.vitalSigns ? [...values?.vitalSigns, ...values?.supportingInfos] : [];
    let formattedInfos = supportingInfos?.map(data => {
      return {
        ...data,
        value: String(data?.value)
      };
    });

    const totalNumberOfSessions = values?.items?.length
      ? values.items.map(data => Number(data?.quantity || 0)).reduce((acc, curr) => acc + curr, 0)
      : 1;

    const ordersData = {
      service_category: {
        category: values?.service_categories
      },
      ...(values?.AppoinmentType === 'Insurance'
        ? {
            approval_date: values?.approval_date,
            approval_no: values?.approval_no
          }
        : null),
      service_type: { type: values?.service_type },
      specialty: values?.speciality,
      reason_code: { code: values?.reasons },
      order_date: values?.visit_date,
      start: values?.slots,
      participant: { participant: '' },
      comment: values?.comment,
      number_of_sessions: Number(totalNumberOfSessions),
      visit_category: 'out_patient',
      patient: selectedPatient ?? '',
      practitioner: selectedDoctor ?? '',
      payment_method: values?.payment_method,
      payment_status: values?.paid ? 'Paid' : 'UnPaid',
      diagnosisArr: values?.diagnosisArr ? values?.diagnosisArr : [],
      supportingInfos: formattedInfos,
      invoice_check: values?.listInvoices,
      items: values?.items.map(item => ({
        ...item,
        discount_amount: Number(item?.discount_amount),
        patient_share: Number(item?.patient_share),
        name: item?.non_standard_desc,
        item_code: item?.node_standard_code,
        item_type: item?.service_type,
        quantity: Number(item?.quantity),
        total_quantity: Number(item?.quantity),
        unit_price: Number(item?.unit_price),
        standard_code: item?.node_standard_code,
        discount_type: item?.discount_type,
        tax_check: item?.tax_check,
        item_id: String(item?.id),
        payer_id: item?.payer_id,
        maternity: false,
        status: values?.paid ? 'Paid' : 'UnPaid'
      }))
    };
    const newItems = values?.items.map(item => ({
      ...item,
      discount_amount: Number(item?.discount_amount),
      name: item?.non_standard_desc,
      item_code: item?.node_standard_code,
      item_type: item?.service_type,
      quantity: Number(item?.quantity),
      total_quantity: Number(item?.quantity),
      amount: Number(item?.unit_price),
      unit_price: Number(item?.unit_price),
      patient_share: Number(item?.patient_share),
      type: item?.type,
      standard_code: item?.standard_code,
      standard_description: item?.standard_description,
      maternity: false,
      care_teams: [
        {
          practitioner: values?.rcm_ref || 0,
          practitionerName: values?.practitionerName || '',
          practitioner_role: values?.practitionerRole || '',
          care_team_role: '',
          qualification: values?.speciality || ''
        }
      ]
    }));

    const commonData = {
      date: values?.visit_date,
      // description: values?.description,
      paid: values?.paid,
      ...(formikRef?.current?.values?.paid === true && {
        account_code: values?.account_code
      }),
      branch_id: values?.providers_branch_id,
      business_id: values?.providers_id,
      item_details:
        validate === 'Cash' ? calculateCashDetails(formikRef?.current) : calculateShares(formikRef?.current, maxLimit),
      customer_id: Number(ref),
      payment_method: values?.payment_method,
      tax_type: 'percentage',
      tax_amount: 15
    };

    const invoiceData =
      values.AppoinmentType === 'Cash'
        ? {
            ...commonData,
            invoice_type: 'sales'
          }
        : {
            ...commonData,
            invoice_type: 'credit',
            company_id: Number(payerLicense)
          };
    try {
      setLoading(true);
      if (values?.listInvoices) {
        await createNewInvoice(setLoading, invoiceData).then(async res => {
          console.log(res);
          if (res?.data) {
            ordersData.invoice_no = res?.data?.invoice?.invoice_number;
            ordersData.invoice_id = res?.data?.invoice?.id;
            ordersData.company_invoice_no = res?.data?.companyInvoice?.id;
            await CreateOrdersApi(setLoading, ordersData, navigate);
          }
        });
      } else {
        await CreateOrdersApi(setLoading, ordersData, navigate);
      }
    } catch (error) {
      console.error('Error during appointment or invoice creation:', error);
    } finally {
      setLoading(false);
    }
  };
  const typeOptions = [
    { value: 'fixed', label: 'Fixed' },
    { value: 'percentage', label: 'Percentage' }
  ];

  const prevPriceListIdRef = useRef();
  const fetchPriceList = useCallback(id => {
    if (id && id !== prevPriceListIdRef.current) {
      getPriceListByID(
        setLoading,
        {
          payer_id: String(id)
        },
        setItemsList
      );
      prevPriceListIdRef.current = id;
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {formikProps => (
          <div className='pre_auth_req_page flex h-screen rounded-md bg-gray-100 p-2'>
            <div className={`${validate === '' || !paidToday ? 'w-full' : 'w-3/4'} pr-2 overflow-auto`}>
              <h2 className='text-xl font-semibold mb-2 flex items-center gap-1 bg-white shadow-md p-2 rounded-md text-gray-800'>
                <div className='flex justify-end items-end cursor-pointer' onClick={() => navigate(-1)}>
                  <IconButton
                    aria-label='go back'
                    className='!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full'
                  >
                    <FaArrowLeft />
                  </IconButton>
                </div>
                Create Order
              </h2>
              <Form onSubmit={formikProps.handleSubmit}>
                {/* {console.log(formikProps?.values)} */}
                <div className='bg-white rounded-lg shadow-md p-3 mb-2'>
                  <div className='grid md:grid-cols-3 lg:grid-cols-5 gap-[0.5rem]'>
                    <SharedInput
                      name='visit_date'
                      type='datetime-local'
                      label='Order Date'
                      value={formikProps.values.start}
                      onChange={e => {
                        formikProps.handleChange(e);

                        const startDate = new Date(e.target.value);
                        const currentDate = new Date();

                        startDate.setHours(0, 0, 0, 0);
                        currentDate.setHours(0, 0, 0, 0);

                        const isInFuture = startDate > currentDate;
                        setPaidToday(!isInFuture);
                      }}
                    />
                    <SharedSearchDropdown
                      name='customer_id'
                      label='Patient'
                      optionLabel='Select Patient'
                      data={patientList?.data?.map(patient => ({
                        value: patient.his_refrence,
                        label:
                          patient.name +
                          ' - ' +
                          patient?.file_number +
                          ' - ' +
                          patient?.document_id +
                          ' - ' +
                          patient?.primary_contact_number,
                        refrence: patient.id
                      }))}
                      onItemAdd={value => {
                        const selectedPatient = patientList?.data?.find(patient => patient.his_refrence === value);
                        setSelectedPatient(selectedPatient);
                        formikProps.setFieldValue('items', []);
                        formikProps.setFieldValue('AppoinmentType', selectedPatient?.beneficiary_type);

                        if (selectedPatient && patientList?.data) {
                          const storedPayers = JSON.parse(localStorage.getItem('hisPayers')) || {};

                          if (
                            selectedPatient.beneficiary_type == 'Insurance' &&
                            !selectedPatient?.insurance_plans?.length
                          ) {
                            toast.error('no insurance plan!');
                          }
                          if (
                            storedPayers?.length &&
                            selectedPatient.beneficiary_type == 'Insurance' &&
                            selectedPatient?.insurance_plans?.length
                          ) {
                            const selectedPayer = storedPayers.find(
                              payer =>
                                payer?.payer_id == selectedPatient?.insurance_plans[0]?.payer_id ||
                                payer.rcm_ref == selectedPatient?.insurance_plans[0]?.payer_id
                            );
                            formikProps.setFieldValue('payer', selectedPayer?.rcm_ref);
                            setPayerID(selectedPayer?.rcm_ref);
                            setPayerLicense(selectedPayer?.license_id);
                          }
                          if (selectedPatient.beneficiary_type == 'Insurance') {
                            let maxLimit = selectedPatient?.insurance_plans
                              ? selectedPatient?.insurance_plans[0]?.max_limit
                              : 0;
                            setMaxLimit(maxLimit);
                          }
                        }
                        setValidate(selectedPatient.beneficiary_type);
                        setRef(selectedPatient.id);
                      }}
                    />

                    {paidToday && (
                      <div className='flex gap-1 flex-col'>
                        <label className='text-sm'>Patient Type</label>

                        <div className='flex w-full'>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue('AppoinmentType', 'Cash');
                              formikProps.setFieldValue('items', []);
                              setValidate('Cash');
                            }}
                            className={`w-[50%] border p-1.5 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                              formikProps.values.AppoinmentType === 'Cash'
                                ? '!bg-primary !border-primary text-white'
                                : null
                            }`}
                          >
                            Cash
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue('AppoinmentType', 'Insurance');
                              formikProps.setFieldValue('items', []);
                              setValidate('Insurance');
                            }}
                            className={`w-[50%] border  p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.AppoinmentType === 'Insurance'
                                ? '!bg-primary !border-primary text-white'
                                : null
                            }`}
                          >
                            Insurance
                          </button>
                        </div>
                        {formikProps.errors.AppoinmentType && formikProps.touched.AppoinmentType ? (
                          <p className='max-w-2xl text-sm leading-6 text-red-500'>
                            {formikProps.errors.AppoinmentType}
                          </p>
                        ) : null}
                      </div>
                    )}
                    <SharedSearchDropdown
                      name='practitioner_id'
                      label='Doctor'
                      optionLabel='Select doctor'
                      data={doctorList?.data
                        ?.filter(data => data?.role == 'Doctor')
                        ?.map(doctor => ({
                          value: doctor.practitioner_id,
                          label: doctor.name,
                          speciality: doctor?.speciality
                        }))}
                      onItemAdd={value => {
                        const selectedDoctor = doctorList?.data?.find(doc => doc.practitioner_id === value);

                        console.log(selectedDoctor);

                        if (selectedDoctor) {
                          formikProps.setFieldValue('speciality', selectedDoctor.speciality);

                          formikProps.setFieldValue('practitionerName', selectedDoctor?.name);
                          formikProps.setFieldValue('rcm_ref', selectedDoctor?.rcm_ref);

                          formikProps.setFieldValue('practitionerRole', selectedDoctor.role);
                        }
                      }}
                    />
                    {formikProps.values.AppoinmentType === 'Insurance' && paidToday && (
                      <div className='flex flex-col gap-1'>
                        <label htmlFor='payer_id'>Payer*</label>
                        <select
                          id='payer'
                          name='payer'
                          onChange={event => {
                            formikProps.handleChange(event);
                            const selectedPayer =
                              hisPayers?.length > 0 && hisPayers?.find(payer => payer?.rcm_ref === event.target.value);
                            const selectedPayerId = event.target.value;
                            setPayerID(selectedPayerId);
                            console.log(selectedPayer);
                            setPayerLicense(selectedPayer?.license_id);
                          }}
                          value={formikProps.values.payer}
                          autoComplete='payer'
                          className='w-full border rounded-lg'
                        >
                          <option value='' data-license={''}>
                            Select Payer
                          </option>
                          {hisPayers?.length > 0 &&
                            hisPayers?.map((payer, i) => (
                              <option key={payer?.rcm_ref} value={payer?.rcm_ref}>
                                {payer?.name}
                              </option>
                            ))}
                        </select>
                        {formikProps.errors.payer && formikProps.touched.payer ? (
                          <p className='max-w-2xl text-sm leading-6 text-red-500'>{formikProps.errors.payer}</p>
                        ) : null}
                      </div>
                    )}
                    <SharedInput
                      name='speciality'
                      type='text'
                      label='Speciality'
                      placeholder={"Doctor's Speciality"}
                      disabled='true'
                    />
                    <SharedInput name='comment' label='Comment' placeholder={'Add Comment'} />
                    {formikProps.values.AppoinmentType === 'Insurance' && (
                      <>
                        <SharedInput name='approval_no' type='text' label='Approval No' placeholder={'Approval No'} />
                        <SharedInput name='approval_date' type='date' label='Approval Date' />
                      </>
                    )}
                    <div class='toggle-button-ref flex items-end gap-2.5 justify-center mt-2'>
                      <input
                        type='checkbox'
                        id='listInvoices'
                        name='listInvoices'
                        checked={formikProps.values.listInvoices}
                        onChange={() => formikProps.setFieldValue('listInvoices', !formikProps.values.listInvoices)}
                      />
                      <label for='listInvoices'></label>
                      <span>Generate Invoice</span>
                    </div>
                  </div>
                </div>

                {paidToday && formikProps.values.listInvoices && (
                  <div className='bg-white rounded-lg shadow-md p-6 mb-2'>
                    <h3 className='mb-2 font-semibold'>Invoice Details</h3>
                    <div className={`grid ${validate === 'Insurance' ? 'grid-cols-4' : 'grid-cols-4'} gap-[0.5rem]`}>
                      {/* <SharedInput name='date' label='Date' type='datetime-local' /> */}
                      <div>
                        <label className='text-sm'>Paid</label>

                        <div className='flex w-full'>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue(`paid`, true);
                            }}
                            className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                              formikProps.values.paid === true ? '!bg-primary !border-primary text-white' : ''
                            }`}
                          >
                            Paid
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              formikProps.setFieldValue(`paid`, false);
                            }}
                            className={`w-[50%] border p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.paid === false ? '!bg-primary !border-primary text-white' : ''
                            }`}
                          >
                            Unpaid
                          </button>
                        </div>
                      </div>
                      <SharedDropdown
                        name='payment_method'
                        label='Payment Method'
                        optionLabel='Select'
                        data={paymentMethods}
                      />

                      {formikProps?.values?.paid && (
                        <SharedSearchDropdown
                          name='account_code'
                          label='Account'
                          optionLabel='Select Account'
                          data={cashAccount?.data?.map(acc => ({
                            value: Number(acc.code),
                            label: acc.name_en
                          }))}
                        />
                      )}
                      {/* <SharedInput name='description' label='Description' placeholder={'Enter Description'} /> */}
                    </div>
                  </div>
                )}

                {formikProps.values.AppoinmentType !== '' && paidToday && (
                  <div className='bg-white rounded-lg shadow-md p-6 mb-2'>
                    <h3 className='text-xl font-semibold mb-4 text-gray-700'>Items</h3>

                    <div className='field flex flex-col gap-1 relative'>
                      <span className='absolute inset-y-0 left-0 pl-3 flex items-center'>
                        <BiSearch className='h-5 w-5 text-gray-400' />
                      </span>
                      <input
                        type='text'
                        placeholder='Search for item by Service Code or Service Description'
                        onChange={handleSearchChange}
                        value={searchTerm}
                        className='block w-full py-2 !px-10 !rounded-md'
                      />
                      {searchTerm.length > 0 && !itemsLoading && (
                        <span
                          className='absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer'
                          onClick={() => {
                            setSearchResult([]);
                            setSearchTerm('');
                          }}
                        >
                          <BiX className='h-7 w-7 text-gray-400' />
                        </span>
                      )}
                      {itemsLoading && (
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer'>
                          <Spinner size='20px' />
                        </div>
                      )}
                      {searchResult.length > 0 ? (
                        <ul className='absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto'>
                          {searchResult.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => handleSelectSearchItem(formikProps, item)}
                              className='py-1 px-3 cursor-pointer hover:bg-gray-100 border-b border-gray-300'
                            >
                              <div className='flex gap-3'>
                                <div className='my-auto w-36 font-semibold capitalize '>{item?.service_type}</div>
                                <div className='pl-3 border-l border-gray-500'>
                                  <div className='border-b border-gray-200'>
                                    {item?.node_standard_code}
                                    {item?.non_standard_desc}
                                  </div>
                                  <div>
                                    {item?.non_standard_code}
                                    {item?.non_standard_desc}
                                  </div>
                                </div>
                              </div>
                              {/* Assuming item has a 'name' property */}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        searchTerm.length > 3 && (
                          <ul className='absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto'>
                            <li className='py-1 px-3 cursor-pointer font-medium text-center text-lg'>
                              No Result Found
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                    <div className='overflow-x-auto'>
                      <table className='min-w-full divide-y divide-gray-200'>
                        <thead className='bg-gray-100'>
                          <tr>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Name
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Unit Price
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Quantity
                            </th>
                            {formikProps?.values?.AppoinmentType === 'Insurance' && (
                              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Patient Share
                              </th>
                            )}
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Discount
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Tax
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formikProps.values.items.map((item, index) => (
                            <tr key={index} className='bg-white border-b'>
                              <td className='px-4 py-2'>
                                <Tooltip title={item?.service_desc} className='text-[12px]'>
                                  {item?.service_desc}
                                </Tooltip>
                              </td>
                              <td className='px-4 py-2'>
                                <SharedInput
                                  type='text'
                                  name={`items[${index}].unit_price`}
                                  value={item.unit_price}
                                  className='!w-20'
                                  min={0}
                                />
                              </td>
                              <td className='px-4 py-2'>
                                <SharedInput
                                  type='number'
                                  name={`items[${index}].quantity`}
                                  value={item.quantity}
                                  className='!w-20'
                                  min={0}
                                />
                              </td>
                              {formikProps?.values?.AppoinmentType === 'Insurance' ? (
                                <td className='px-4 py-2'>
                                  <SharedInput
                                    name={`items[${index}].patient_share`}
                                    value={item.patient_share}
                                    type='number'
                                    className='!w-24'
                                  />
                                </td>
                              ) : null}
                              <td className='px-4 py-2'>
                                <div className='flex items-center space-x-2'>
                                  <SharedDropdown
                                    name={`items[${index}].discount_type`}
                                    value={item.discount_type}
                                    data={typeOptions}
                                    optionLabel='select'
                                    // disabled={true}
                                    className='!w-24'
                                  />
                                  <SharedInput
                                    type='number'
                                    name={`items[${index}].discount_amount`}
                                    value={item.discount_amount}
                                    className='!w-20'
                                    min={0}
                                  />
                                </div>
                              </td>

                              <td className='px-4 py-2'>
                                <div className='flex w-full'>
                                  <button
                                    type='button'
                                    onClick={() => {
                                      formikProps.setFieldValue(`items[${index}].tax_check`, false);
                                    }}
                                    className={`w-[50%] border px-1.5 py-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                      formikProps.values.items[index].tax_check === false
                                        ? '!bg-primary !border-primary text-white'
                                        : ''
                                    }`}
                                  >
                                    False
                                  </button>
                                  <button
                                    type='button'
                                    onClick={() => {
                                      formikProps.setFieldValue(`items[${index}].tax_check`, true);
                                    }}
                                    className={`w-[50%] border px-1.5 py-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                      formikProps.values.items[index].tax_check === true
                                        ? '!bg-primary !border-primary text-white'
                                        : ''
                                    }`}
                                  >
                                    True
                                  </button>
                                </div>
                              </td>
                              <td className='px-4 py-2'>
                                <Button
                                  color='error'
                                  onClick={() => {
                                    const newItems = formikProps.values.items.filter((_, i) => i !== index);
                                    formikProps.setFieldValue('items', newItems);
                                  }}
                                >
                                  <RiDeleteBin5Line />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className='flex justify-end mt-6'>
                  <Button
                    variant='contained'
                    type='submit'
                    size='large'
                    style={{
                      backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                    }}
                    className='px-6 py-2 text-white font-semibold rounded-md shadow-sm hover:shadow-md transition-all duration-300'
                  >
                    Create Order
                  </Button>
                </div>
              </Form>
              <AppLoader setShowLoader={setLoading} showLoader={loading} />
            </div>

            {validate !== '' && paidToday && formikProps.values.listInvoices && (
              <div className='w-1/4 bg-white rounded-lg shadow-md p-4 h-fit'>
                <h3 className='text-xl font-medium mb-3 text-gray-800'>Bill Summary</h3>
                <div className='space-y-2'>
                  {formikProps?.values?.AppoinmentType === 'Insurance' && (
                    <div className='bg-gray-50 p-2 rounded-lg'>
                      <div className='flex justify-between'>
                        <h4 className='text-md font-medium text-gray-700'>Total Discount:</h4>
                        <span className='font-semibold'>
                          {formikProps?.values?.AppoinmentType === 'Cash'
                            ? formikProps?.values?.items
                                ?.map(itm => {
                                  const discountAmount = Number(itm?.discount_amount) || 0;
                                  const discountType = itm?.discount_type;
                                  let discount = 0;

                                  if (discountType === 'percentage') {
                                    discount = (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) / 100;
                                  } else if (discountType === 'fixed') {
                                    discount = discountAmount;
                                  }

                                  return discount;
                                })
                                .reduce((acc, curr) => acc + curr, 0)
                            : calculateShares(formikProps, maxLimit).totals?.discountedAmount}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className='bg-gray-50 p-2 rounded-lg'>
                    <h4 className='text-md font-medium text-gray-700'>Cash Bill</h4>
                    <div className='space-y-1'>
                      <div className='flex justify-between'>
                        <span className='text-gray-600 text-sm'>Amount:</span>
                        <span className='font-semibold text-sm'>
                          {formikProps?.values?.AppoinmentType === 'Cash'
                            ? calculateCashDetails(formikProps)?.totals?.patientShareTotal
                            : calculateShares(formikProps, maxLimit).totals?.patientShareTotal}
                        </span>
                      </div>
                      {formikProps?.values?.AppoinmentType === 'Cash' && (
                        <div className='flex justify-between'>
                          <span className='text-gray-600 text-sm'>Discount:</span>
                          <span className='font-semibold text-sm'>
                            {formikProps?.values?.AppoinmentType === 'Cash'
                              ? calculateCashDetails(formikProps)?.totals?.discountedAmount
                              : calculateShares(formikProps, maxLimit).totals?.discountedAmount}
                          </span>
                        </div>
                      )}
                      <div className='flex justify-between'>
                        <span className='text-gray-600 text-sm'>Tax:</span>
                        <span className='font-semibold text-sm'>
                          {formikProps?.values?.AppoinmentType === 'Cash'
                            ? calculateCashDetails(formikProps)?.totals?.patientTaxTotal
                            : calculateShares(formikProps, maxLimit).totals?.patientTaxTotal}
                        </span>
                      </div>
                      <div className='flex justify-between text-md font-semibold'>
                        <span className='text-gray-600'>Net Total:</span>
                        <span>
                          {/* {formikProps?.values?.AppoinmentType === "Cash"
                        ? calculateCashDetails(formikProps)?.totals
                            ?.patientShareTotal -
                          calculateCashDetails(formikProps, maxLimit)
                            ?.totals?.discountedAmount +
                          calculateCashDetails(formikProps, maxLimit).totals
                            ?.patientTaxTotal
                        : calculateShares(formikProps, maxLimit).totals
                            ?.patientShareTotal +
                          calculateShares(formikProps, maxLimit).totals
                            ?.patientTaxTotal} */}
                          {formikProps?.values?.AppoinmentType === 'Cash'
                            ? Number(
                                calculateCashDetails(formikProps)?.totals?.patientShareTotal -
                                  calculateCashDetails(formikProps, maxLimit)?.totals?.discountedAmount +
                                  calculateCashDetails(formikProps, maxLimit)?.totals?.patientTaxTotal || 0
                              ).toFixed(2)
                            : Number(
                                calculateShares(formikProps, maxLimit)?.totals?.patientShareTotal +
                                  calculateShares(formikProps, maxLimit)?.totals?.patientTaxTotal || 0
                              ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {validate === 'Insurance' && paidToday && (
                    <div className='bg-gray-50 p-2 rounded-lg'>
                      <h4 className='text-md font-medium text-gray-700'>Company Bill</h4>
                      <div className='space-y-1'>
                        <div className='flex justify-between'>
                          <span className='text-gray-600 text-sm'>Amount:</span>
                          <span className='font-semibold text-sm'>
                            {calculateShares(formikProps, maxLimit).totals?.companyShareTotal}
                          </span>
                        </div>
                        <div className='flex justify-between'>
                          <span className='text-gray-600 text-sm'>Tax:</span>
                          <span className='font-semibold text-sm'>
                            {calculateShares(formikProps, maxLimit).totals?.companyTaxTotal}
                          </span>
                        </div>
                        <div className='flex justify-between text-md font-semibold'>
                          <span className='text-gray-600'>Net Total:</span>
                          <span>
                            {(
                              parseFloat(calculateShares(formikProps, maxLimit).totals?.companyShareTotal || 0) +
                              parseFloat(calculateShares(formikProps, maxLimit).totals?.companyTaxTotal || 0)
                            )?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <AppLoader setShowLoader={setLoading} showLoader={loading} />
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  );
}

export default CreateOrder;
