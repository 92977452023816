import React, { useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip
} from '@mui/material'
import {
  CreateNewEmr,
  GetEmrByNumber,
  getPractitionerDetail,
  getRcmPatientByID,
  UpdateEMR
} from './ApiMethods'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { useMutation } from '@apollo/client'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import preAuth from '../../../gql/preAuth'
import SupportingInfoVitalSigns from './SupportingInfoVitalSigns'
import DiagnosisSearchNSelect from '../../PreAuth/components/request/DiagnosisSearchNSelect'
import ArrayInput from './ArrayInput'
import supportingInfoElements from '../../PreAuth/components/request/SupportingInfoElement'
import AppLoader from '../../../components/AppLoader'
import ItemsList from '../../PreAuth/components/request/ItemsList'
import IsAccident from '../../PreAuth/components/request/IsAccident'
import EncounterForm from '../../PreAuth/components/request/EncounterForm'
import EmrSupportingINfo from './EmrSupportingINfo'
import AllergyIntolerance from './AllergyIntolerance'

export default function EmrForm () {
  function classNames (...classes) {
    return classes?.filter(Boolean).join(' ')
  }
  const navigate = useNavigate()
  const currentDate = new Date()
  const location = useLocation()
  const patientId = location?.state?.patientID

  const patient = location?.state?.appointment
  const visit_date = patient?.visit_date?.split('T')[0]
  const token = JSON.parse(localStorage.getItem('user')).jwt
  const appointmentId = location?.state?.appointmentId
  const selectedEmrNumber = location?.state?.emrNumber
  const emr = location.state?.emr
  const [selectedEmrData, setSelectedEmrData] = useState(null)
  const [Loading, setLoading] = useState(false)
  const [detail, setDetail] = useState('')
  const [rcmDetail, setRcmDetail] = useState(null)
  const todayDate = new Date().toISOString().split('T')[0]
  const user = localStorage.getItem('user')
  const loggedUser = JSON.parse(user)
  const isNurseModule =
    loggedUser?.RoleId == 10 && loggedUser?.RoleName == 'nurse'
  const [open, setOpen] = useState(false)
  const formikRef = useRef(null)
  const [checkboxes, setCheckboxes] = useState({
    er_case: false,
    chronic: false,
    congential: false,
    rta: false,
    work_related: false,
    vaccination: false,
    checkup: false,
    psychiatric: false,
    infertility: false,
    pregnancy: false,
    lactation: false,
    acute: false
  })
  const [editorValue, setEditorValue] = useState(
    selectedEmrData?.doctor_remarks || ''
  )

  const diagnosisTypes = [
    { id: 'principal', name: 'Principal Diagnosis' },
    { id: 'secondary', name: 'Secondary Diagnosis' },
    { id: 'admitting', name: 'Admitting Diagnosis' },
    { id: 'discharge', name: 'Discharge Diagnosis' },
    { id: 'differential', name: 'Differential Diagnosis' }
  ]
  const statusValues = [
    {
      id: 'compelete',
      value: 'compeleted'
    },
    {
      id: 'pending',
      value: 'pending'
    }
  ]

  const excludedCategories = [
    'vital-sign-systolic',
    'vital-sign-diastolic',
    'vital-sign-height',
    'vital-sign-weight',
    'respiratory-rate',
    'temperature',
    'pulse',
    'SP02',
    'RBS',
    'pain-score'
  ]

  useEffect(() => {
    if (emr) {
      setSelectedEmrData(emr)
    } else if (selectedEmrNumber) {
      GetEmrByNumber(
        setLoading,
        { emr_id: Number(selectedEmrNumber) },
        setSelectedEmrData
      )
    }
  }, [emr, selectedEmrNumber])

  useEffect(() => {
    if (selectedEmrData?.doctor_remarks) {
      setEditorValue(selectedEmrData?.doctor_remarks)
    }
  }, [selectedEmrData])

  useEffect(() => {
    let rcm_ref = patient ? patient?.patient?.rcm_ref : emr?.patient?.rcm_ref
    getRcmPatientByID(rcm_ref, setRcmDetail)
    if (token) {
      getPractitionerDetail(setLoading, setDetail, token)
    }
  }, [])

  const formInitialValues = {
    is_nurse_module: isNurseModule ?? false,
    past_history: selectedEmrData?.past_history || '',
    physical_assesment: selectedEmrData?.physical_assesment || '',
    family_history: selectedEmrData?.family_history || '',
    doctor_remarks: selectedEmrData?.doctor_remarks || '',
    er_case: selectedEmrData?.er_case || false,
    chronic: selectedEmrData?.chronic || false,
    congential: selectedEmrData?.congential || false,
    rta: selectedEmrData?.rta || false,
    work_related: selectedEmrData?.work_related || false,
    vaccination: selectedEmrData?.vaccination || false,
    checkup: selectedEmrData?.checkup || false,
    psychiatric: selectedEmrData?.psychiatric || false,
    infertility: selectedEmrData?.infertility || false,
    pregnancy: selectedEmrData?.pregnancy || false,
    lactation: selectedEmrData?.lactation || false,
    acute: selectedEmrData?.acute || false,
    patientID: selectedEmrData?.patient?.patient_id || '',
    payerId: '',
    dateOrdered: selectedEmrData?.created_at
      ? new Date(selectedEmrData.created_at).toISOString().substring(0, 10)
      : currentDate.toISOString().substring(0, 10),
    claimType: '',
    careTeamsArr: selectedEmrData?.careTeamsArr || [
      {
        practitioner: detail ? detail?.data?.name : '',
        practitionerName: detail ? detail?.data?.name : '',
        practitioner_role: detail ? detail?.data?.role : '',
        // care_team_role: "",
        care_team_role: String(loggedUser?.RoleName),
        qualification: detail ? detail?.data?.speciality : ''
      }
    ],
    diagnosisArr: selectedEmrData?.diagnosisArr || [],
    significantSign: selectedEmrData?.significantSign || [],
    otherCondition: selectedEmrData?.otherCondition || [],
    visit_id: selectedEmrData?.visit_id || patient?.visit_id,
    // supportingInfos: selectedEmrData?.supportingInfos || [
    supportingInfos: selectedEmrData?.supportingInfos?.filter(
      info => !excludedCategories.includes(info.category)
    ) || [
      {
        category: 'chief-complaint',
        codeText: '',
        code: '',
        valueUnit: '',
        value: ''
      }
    ],
    infosWithoutCheifComplaints: [
      {
        category: '',
        codeText: '',
        code: '',
        valueUnit: '',
        value: ''
      }
    ],
    startDate:
      selectedEmrData?.supportingInfos?.find(
        info => info.category === 'vital-sign-systolic'
      )?.start_date ||
      visit_date ||
      todayDate,
    endDate:
      selectedEmrData?.supportingInfos?.find(
        info => info.category === 'vital-sign-systolic'
      )?.end_date ||
      visit_date ||
      todayDate,
    vitalSigns: [
      {
        category: 'vital-sign-systolic',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-systolic'
          )?.value || '',
        valueUnit: 'mm[Hg]',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-systolic'
          )?.reason || '888'
      },
      {
        category: 'vital-sign-diastolic',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-diastolic'
          )?.value || '',
        valueUnit: 'mm[Hg]',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-diastolic'
          )?.reason || '888'
      },
      {
        category: 'vital-sign-height',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-height'
          )?.value || '',
        valueUnit: 'cm',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-height'
          )?.reason || '999.9'
      },
      {
        category: 'vital-sign-weight',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-weight'
          )?.value || '',
        valueUnit: 'kg',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'vital-sign-weight'
          )?.reason || '999.9'
      },
      {
        category: 'temperature',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'temperature'
          )?.value || '',
        valueUnit: 'Cel',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'temperature'
          )?.reason || '88'
      },
      {
        category: 'respiratory-rate',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'respiratory-rate'
          )?.value || '',
        valueUnit: '/min',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'respiratory-rate'
          )?.reason || '88'
      },
      {
        category: 'pulse',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'pulse'
          )?.value || '',
        valueUnit: '/min',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'pulse'
          )?.reason || '998'
      },
      {
        category: 'SP02',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'SP02'
          )?.value || '',
        valueUnit: '%',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'SP02'
          )?.reason || '88'
      },
      {
        category: 'pain-score',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'pain-score'
          )?.value || '',
        valueUnit: '',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'pain-score'
          )?.reason || '88'
      },
      {
        category: 'RBS',
        startDate: '',
        endDate: '',
        value:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'RBS'
          )?.value || '',
        valueUnit: 'mg/dl',
        reason:
          selectedEmrData?.supportingInfos?.find(
            info => info.category === 'RBS'
          )?.reason || '88'
      }
    ],
    items: selectedEmrData?.items || [],
    encounterStatus: selectedEmrData?.encounterStatus || null,
    immunization: selectedEmrData?.immunization || {
      status: '',
      occurrenceDateTime: '',
      vaccine_code: ''
    },
    isImmunization: selectedEmrData?.isImmunization || false,
    compelete_emr: selectedEmrData?.complete_check || false,
    is_accident: selectedEmrData?.is_accident || false,
    allergyIntolerance: selectedEmrData?.allergyIntolerance || []
  }

  const preAuthValidationSchema = Yup.object().shape({
    careTeamsArr: Yup.array().min(1, 'Atleast One is required'),
    diagnosisArr: Yup.array().min(1, 'Atleast One is required'),
    //items: !isNurseModule ? Yup.array().min(1, 'Atleast One is required') : Yup.array().notRequired(),
    encounterStatus: Yup.string().nullable(),
    startDate: Yup.date().required('Start date is required'),
    endDate: Yup.date()
      .required('End date is required')
      .min(Yup.ref('startDate'), 'End date cannot be before start date'),
    vitalSigns: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required('Category is required'),
        value: Yup.number().test(
          'value-or-reason',
          'Value is required and must be positive when reason is empty',
          function (value) {
            const { reason } = this.parent
            if (!reason && (value === undefined || value <= 0)) {
              return false
            }
            return true
          }
        )
      })
    ),
    isImmunization: Yup.boolean(),
    compelete_emr: Yup.boolean()
    // allergyIntolerance: Yup.array().min(1, "Atleast One is reduired"),
  })

  const initialValues = {
    compelete_emr: true
  }

  function convertClinicalData (inputData) {
    return inputData.map(item => {
      const baseObject = {
        category: item.category,
        code: item.code || null,
        code_text: item.codeText || null,
        start_date: item.startDate || null,
        end_date: item.endDate || null,
        value: item.value || null,
        unit: item.valueUnit || null,
        reason: item.reason || null,
        image_content_type: item.imageContentType || null,
        image_title: item.imageTitle || null,
        image_creation_date: item.imageCreationDate || null,
        image_value: item.imageValue || null
      }

      if (item.category.startsWith('vital-sign-')) {
        baseObject.code = null
        baseObject.code_text = null
      }

      if (item.image) {
        baseObject.image_content_type = item.image.contentType || null
        baseObject.image_title = item.image.title || null
        baseObject.image_creation_date = item.image.creationDate || null
        baseObject.image_value = item.image.value || null
      }

      return baseObject
    })
  }
  const [requestPreAuth, { data, loading, error }] = useMutation(
    preAuth.REQUEST_AUTHORIZATION,
    {
      onCompleted (result) {
        if (result?.preAuthRequest?.message === 'data sent to payer') {
          toast.success(result?.preAuthRequest?.message)
          CreateNewEmr(setLoading, {
            ...formikRef?.current?.values,
            items: formikRef?.current?.values?.items.map(item => ({
              ...item,
              net: Number(item.net),
              patient_share: parseFloat(item.patient_share),
              payer_share: String(item.payer_share),
              is_package: String(item.is_package)
            })),
            patientID: patientId
          }).then(() => {
            if (isNurseModule) {
              navigate('/nursing/out-patient')
            } else {
              navigate('/his/patient')
            }
          })
        }
        if (result?.preAuthRequest?.nphies_err) {
          result?.preAuthRequest?.nphies_err?.map(err => {
            toast?.error(err?.display)
          })
        }
        setOpen(false)
      },
      onError (err) {
        console.error('Pre-Authorization Request Error:', err)
      }
    }
  )
  const requestPreAuthApi = values => {
    const updateddiagnosis = values?.diagnosisArr?.map(des => ({
      type: des?.type,
      on_admission: des?.on_admission === 'false' ? false : true,
      code: des?.codeId,
      description: des?.codeDescription
    }))
    const UpdatedItems = values?.items.map(item => ({
      type: item.type,
      standard_code: item.standardCode,
      standard_description: item.standardCodeDescription,
      non_standard_code: item.non_standard_code,
      non_standard_description: item.non_standard_description,
      medication_selection_reason: item.medicationSelectionReason,
      prescribed_medication: Number(item.prescribedMedication),
      body_site: item.body_site || null,
      sub_site: item.sub_site || '',
      service_start_date: item.service_start_date,
      service_end_date: item.service_end_date || '',
      supporting_info: item.supporting_info || [],
      diagnosis: item.diagnosis,
      care_teams: item.care_teams,
      // is_package:
      //   item.is_package === "false" ? false : Boolean(item?.is_package),
      is_package: item.is_package === String(item?.is_package),
      quantity: item.quantity,
      code: item.standardCode,
      unit_price: item.unit_price,
      tax_amount: item.tax_amount,
      factor: item.factor,
      // net: Number(item.net),
      net: parseFloat(item?.net ? item.net : 0),
      discount_amount: item.discount_amount,
      patient_share: item.patient_share,
      patient_share_amount: item.patientShareAmount,
      payer_share: String(item.payer_share),
      maternity: false
    }))
    const mappedValues = {
      is_edit_request: false,
      ref_transaction_id: null,
      is_followup_req: false,
      prv_transaction_id: '',
      patient_id:
        patient?.patient?.rcm_ref || selectedEmrData?.patient?.patient_id,
      insurance_plan_id: rcmDetail?.insurance_plans
        ? rcmDetail?.insurance_plans[0]?.id
        : '',
      is_new_born: false,
      is_referral: false,
      date_ordered: currentDate.toISOString().substring(0, 10),
      priority: 'stat',
      type: 'professional',
      // is_accident: values?.is_accident,
      subtype: 'op',
      offline_verification_id: null,
      offline_verification_date: null,
      verification_resp_ident_value: null,
      verification_resp_ident_url: null,
      physicians_id: [detail?.data?.id],
      diagnosis: updateddiagnosis,
      supporting_info: convertClinicalData(values?.supportingInfos),
      is_accident: false,
      accident_info: {
        street_name: '',
        city: '',
        state: '',
        country: '',
        date: ''
      },
      items: UpdatedItems,
      vision_prescription: null,
      is_vision_prescription: false,
      encounter: null
    }
    requestPreAuth({
      variables: {
        input: mappedValues
      }
    })
  }

  // populate initial values from selectedEmrData when component mounts
  useEffect(() => {
    if (selectedEmrData) {
      setCheckboxes({
        er_case: selectedEmrData?.er_case || false,
        chronic: selectedEmrData?.chronic || false,
        congential: selectedEmrData?.congential || false,
        rta: selectedEmrData?.rta || false,
        work_related: selectedEmrData?.work_related || false,
        vaccination: selectedEmrData?.vaccination || false,
        checkup: selectedEmrData?.checkup || false,
        psychiatric: selectedEmrData?.psychiatric || false,
        infertility: selectedEmrData?.infertility || false,
        pregnancy: selectedEmrData?.pregnancy || false,
        lactation: selectedEmrData?.lactation || false,
        acute: selectedEmrData?.acute || false
      })
    }
  }, [selectedEmrData])

  const hasInsurancePlans =
    (selectedEmrData && selectedEmrData.patient?.insurance_plans?.length > 0) ||
    patient?.patient?.insurance_plans?.length > 0

  const handleSubmit = (values, { setSubmitting }) => {
    let patientId = selectedEmrData
      ? selectedEmrData?.patient?.patient_id
      : patient?.patient?.patient_id
    if (!patientId) {
      toast.error('Please select a patient')
      return
    }
    const updatedVital = values?.vitalSigns.map(vt => {
      let vital = {
        category: vt?.category,
        start_date: values?.startDate,
        end_date: values?.endDate,
        value: String(vt?.value),
        unit: vt?.unit,
        reason: vt?.reason
      }
      if (vt?.value) vital.reason = ''
      return vital
    })

    if (selectedEmrData) {
      handleUpdateEMR(values, patientId, updatedVital)
    } else {
      handleCreateEMR(values, patientId, updatedVital)
    }

    setSubmitting(false)
  }

  const handleUpdateEMR = (values, patientId, updatedVital) => {
    let payload = {
      ...values,
      patientID: patientId,
      payerId: values?.payerId,
      dateOrdered: values?.dateOrdered,
      claimType: values?.claimType,
      careTeamsArr: values?.careTeamsArr,
      complete_check: values?.compelete_emr,
      significient_signs: values?.significantSign || [],
      other_conditions: values?.otherCondition || [],
      diagnosisArr: values?.diagnosisArr.map(item => ({
        ...item,
        on_admission: String(item.on_admission)
      })),
      visit_id: parseInt(values?.visit_id, 10),
      emr_id: selectedEmrData?.emr_id,
      supportingInfos: [...values?.supportingInfos, ...updatedVital],
      items: values?.items.map(item => ({
        ...item,
        net: Number(item.net),
        patient_share: parseFloat(item.patient_share),
        payer_share: String(item.payer_share),
        is_package: String(item.is_package),
        standard_code: item?.standardCode,
        standard_description: item?.standardCodeDescription,
        name: item?.standardCodeDescription,
        service_code: item?.standardCode,
        service_desc: item?.standardCodeDescription,
        item_type: item?.type,
        service_type: item?.type,
        type: item?.type,
        item_id: String(item?.id || item?.item_id),
        id: String(item?.id || item?.item_id)
      })),
      encounterStatus: values?.encounterStatus,
      immunization: values?.immunization,
      isImmunization: values?.isImmunization,
      allergyIntolerance: values?.allergyIntolerance,
      doctor_remarks: editorValue
    }

    // if (isNurseModule) {
    //   payload.complete_check = false
    // }
    if (!isNurseModule) {
      payload.practitioner = detail?.data
      payload.practitioner.practitioner_id = loggedUser?.practitioner_id
    }

    UpdateEMR(setLoading, payload).then(res => {
      if (res) {
        if (isNurseModule) {
          navigate('/nursing/out-patient')
        } else {
          navigate('/his/patient')
        }
      }
    })
  }

  const handleCreateEMR = (values, patientId, updatedVital) => {
    let payload = {
      ...values,
      patientID: patientId,
      payerId: values?.payerId,
      dateOrdered: values?.dateOrdered,
      claimType: values?.claimType,
      careTeamsArr: values?.careTeamsArr,
      complete_check: values?.compelete_emr,
      significient_signs: values?.significantSign || [],
      other_conditions: values?.otherCondition || [],
      diagnosisArr: values?.diagnosisArr.map(item => ({
        ...item,
        on_admission: String(item.on_admission)
      })),
      visit_id: parseInt(values?.visit_id, 10),
      supportingInfos: [...values?.supportingInfos, ...updatedVital],
      items: values?.items.map(item => ({
        ...item,
        net: Number(item.net),
        patient_share: parseFloat(item.patient_share),
        payer_share: String(item.payer_share),
        is_package: String(item.is_package),
        standard_code: item?.standardCode,
        standard_description: item?.standardCodeDescription,
        name: item?.standardCodeDescription,
        service_code: item?.standardCode,
        service_desc: item?.standardCodeDescription,
        item_type: item?.type,
        service_type: item?.type,
        type: item?.type,
        item_id: String(item?.id)
      })),
      encounterStatus: values?.encounterStatus,
      immunization: values?.immunization,
      isImmunization: values?.isImmunization,
      allergyIntolerance: values?.allergyIntolerance,
      doctor_remarks: editorValue
    }
    CreateNewEmr(setLoading, payload).then(() => {
      if (isNurseModule) {
        navigate('/nursing/out-patient')
      } else {
        navigate('/his/patient')
      }
    })
  }

  const handleEditorChange = value => {
    setEditorValue(value)
    formikRef.current.setFieldValue('doctor_remarks', data)
  }

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      validate={values => {
        const errors = {}
        return errors
      }}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={preAuthValidationSchema}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
        <>
          <div className='pre_auth_req_page'>
            <div className='pre_auth_details_top !p-3 !mb-3 flex w-full items-center flex-col  md:flex-row'>
              <div className='flex w-full md:w-[28%] gap-3 items-center'>
                <div
                  className='back'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z'
                      fill='#4D4D53'
                    />
                  </svg>
                </div>
                <div>
                  <div className='pre_auth_details_title'>
                    <span>Electronic Medical Record</span>
                  </div>
                  <div className='pre_auth_details_content'>
                    <span>EMR Form</span>
                  </div>
                </div>
              </div>
              <div className='!w-full md:!w-[70%]'>
                <div className='flex items-start flex-col !w-full gap-3 mt-1.5'>
                  <div className='flex flex-row items-center flex-wrap gap-x-5'>
                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Full Name :
                      </span>
                      <span>
                        {selectedEmrData?.patient?.full_name ||
                          patient?.patient?.full_name ||
                          'N/A'}
                      </span>
                    </div>
                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Document ID :
                      </span>
                      <span>
                        {selectedEmrData?.patient?.document_id ||
                          patient?.patient?.document_id ||
                          'N/A'}
                      </span>
                    </div>
                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        File No :
                      </span>
                      <span>
                        {selectedEmrData?.patient?.file_no ||
                          patient?.patient?.file_no ||
                          'N/A'}
                      </span>
                    </div>

                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Date Of Birth :
                      </span>
                      <span>
                        {selectedEmrData?.patient?.dob ||
                          patient?.patient?.dob ||
                          'N/A'}
                      </span>
                    </div>

                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        City :
                      </span>
                      <span>
                        {selectedEmrData?.patient?.city ||
                          patient?.patient?.city ||
                          'N/A'}
                      </span>
                    </div>
                  </div>

                  <div className='flex flex-row items-center gap-x-5 flex-wrap'>
                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Policy Class:
                      </span>
                      <span>
                        {selectedEmrData?.patient?.insurance_plans?.[0]
                          ?.policy_class ||
                          patient?.patient?.insurance_plans?.[0]
                            ?.policy_class ||
                          'N/A'}
                      </span>
                    </div>

                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Policy Holder:
                      </span>
                      <span>
                        {selectedEmrData?.patient?.insurance_plans?.[0]
                          ?.policy_holder ||
                          patient?.patient?.insurance_plans?.[0]
                            ?.policy_holder ||
                          'N/A'}
                      </span>
                    </div>
                  </div>
                  {console.log(selectedEmrData)}
                  <div className='flex flex-row   flex-wrap items-start gap-x-5  '>
                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Payer:
                      </span>
                      <span>
                        {selectedEmrData?.patient?.insurance_plans?.[0]
                          ?.payer_name ||
                          rcmDetail?.insurance_plans?.[0]?.payer?.name_en ||
                          'N/A'}
                      </span>
                    </div>

                    <div className='flex flex-row'>
                      <span className='text-gray-600 font-semibold'>
                        Doctor Name :
                      </span>
                      {loggedUser?.FullName}
                      {/* <span> {detail?.data?.name || "N/A"}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SupportingInfoVitalSigns />
            <div className='!grid !grid-cols-1 '>
              <AllergyIntolerance />
            </div>

            <div className='!mb-3 checkbox'>
              <FormGroup className='!grid grid-cols-2 sm:!grid-cols-4 md:!grid-cols-6'>
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='er_case'
                      checked={values.er_case}
                    />
                  }
                  label='ER Case?'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='chronic'
                      checked={values.chronic}
                    />
                  }
                  label='Chronic'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='congential'
                      checked={values.congential}
                    />
                  }
                  label='Congenital'
                />
                <FormControlLabel
                  control={
                    <Field as={Checkbox} name='rta' checked={values.rta} />
                  }
                  label='RTA'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='work_related'
                      checked={values.work_related}
                    />
                  }
                  label='Work Related'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='vaccination'
                      checked={values.vaccination}
                    />
                  }
                  label='Vaccination'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='checkup'
                      checked={values.checkup}
                    />
                  }
                  label='Checkup'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='psychiatric'
                      checked={values.psychiatric}
                    />
                  }
                  label='Psychiatric'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='infertility'
                      checked={values.infertility}
                    />
                  }
                  label='Infertility'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='pregnancy'
                      checked={values.pregnancy}
                    />
                  }
                  label='Pregnancy'
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name='lactation'
                      checked={values.lactation}
                    />
                  }
                  label='Lactation'
                />
                <FormControlLabel
                  control={
                    <Field as={Checkbox} name='acute' checked={values.acute} />
                  }
                  label='Acute'
                />
              </FormGroup>
            </div>

            <div className='my-2'>
              <div className='!w-full'>
                <label className='text-sm'>Physical Assesment</label>
                <Field
                  as='textarea'
                  name='physical_assesment'
                  placeholder='Enter Value'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                  type='text'
                  value={values.physical_assesment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id='physical_assesment'
                  rows={1}
                />
              </div>
            </div>

            <div className='!grid !grid-cols-1 new'>
              <div className=' shadow-md !w-full !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 !mb-4 history '>
                  <div className='!w-full'>
                    <label className='text-sm'>Past History</label>
                    <Field
                      as='textarea'
                      name='past_history'
                      placeholder='Enter Value'
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                      type='text'
                      value={values.past_history}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='past_history'
                      rows={1}
                    />
                  </div>

                  <div className='!w-full'>
                    <label className='text-sm'>Family History</label>

                    <Field
                      as='textarea'
                      placeholder='Enter Value'
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                      name='family_history'
                      // onChange={historyHhandleChange}
                      // value={selectedEmrData?.family_history || ""}
                      type='text'
                      value={values.family_history}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='family_history'
                      rows={1}
                    />
                  </div>
                </div>

                <div className='fieldAddFields'>
                  <div className='Diagnose first'>
                    <FieldArray name='diagnosisArr'>
                      {({ push, remove }) => {
                        return (
                          <>
                            {/* <div class="care_teams !p-3 !mb-3" id="careTeams2"> */}
                            <div class=''>
                              <div class='flex_cont !mb-3'>
                                <div className='w-full '>
                                  <label className='text-sm !mb-1'>
                                    Diagnose
                                  </label>
                                  <DiagnosisSearchNSelect
                                    setFieldValue={val => {
                                      const newIndex =
                                        values.diagnosisArr.length
                                      push({
                                        id: newIndex + 1,
                                        codeDescription: val.AsciiDescription,
                                        type:
                                          newIndex === 0
                                            ? 'principal'
                                            : newIndex === 1
                                            ? 'secondary'
                                            : newIndex === 2
                                            ? 'admitting'
                                            : newIndex === 3
                                            ? 'discharge'
                                            : newIndex === 4
                                            ? 'differential'
                                            : '',
                                        on_admission: 'false',
                                        codeId: val.CodeId
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                              <div class='pre_auth_communication_table'>
                                <div className=''>
                                  <div id='tableBody'>
                                    {values?.diagnosisArr?.map(
                                      (item, index) => (
                                        <div
                                          key={`diagnosis-${index}`}
                                          className='personal-data !p-3 !mb-3 last:!mb-0'
                                        >
                                          <div className='personal-fields grid grid-cols-2 md:grid-cols-4 gap-2'>
                                            <div className=''>
                                              <label className='text-sm'>
                                                Code-Description
                                              </label>
                                              <Tooltip
                                                title={
                                                  values.diagnosisArr[index]
                                                    ?.codeDescription
                                                    ? `${values.diagnosisArr[index]?.codeDescription} - ${values.diagnosisArr[index]?.codeId}`
                                                    : ''
                                                }
                                              >
                                                <span>
                                                  (
                                                  {values.diagnosisArr[index]
                                                    ?.codeDescription
                                                    ? `${values.diagnosisArr[
                                                        index
                                                      ]?.codeDescription.slice(
                                                        0,
                                                        20
                                                      )}...`
                                                    : ''}
                                                  )
                                                </span>
                                              </Tooltip>
                                              <DiagnosisSearchNSelect
                                                setFieldValue={val => {
                                                  setFieldValue(
                                                    `diagnosisArr.${index}.codeDescription`,
                                                    val.AsciiDescription
                                                  )
                                                  setFieldValue(
                                                    `diagnosisArr.${index}.codeId`,
                                                    val.CodeId
                                                  )
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`diagnosisArr.${index}.codeDescription`}
                                                component='div'
                                                className='text-red-500 text-xs'
                                              />
                                            </div>
                                            <div className='flex items-end'>
                                              <div className='w-full'>
                                                <label className='text-sm'>
                                                  Type
                                                </label>
                                                <Field
                                                  as='select'
                                                  name={`diagnosisArr.${index}.type`}
                                                  value={
                                                    index === 0
                                                      ? 'principal'
                                                      : 'secondary'
                                                  }
                                                >
                                                  <option value=''></option>
                                                  {diagnosisTypes.map(item => (
                                                    <option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </Field>
                                                <ErrorMessage
                                                  name={`diagnosisArr.${index}.type`}
                                                  component='div'
                                                  className='text-red-500 text-xs'
                                                />
                                              </div>
                                            </div>
                                            <div className=''>
                                              <label className='text-sm'>
                                                On Admission
                                              </label>
                                              <Field
                                                as='select'
                                                name={`diagnosisArr.${index}.on_admission`}
                                              >
                                                <option value=''>
                                                  On Admission
                                                </option>
                                                <option value={'true'}>
                                                  Yes
                                                </option>
                                                <option value={'false'}>
                                                  No
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name={`diagnosisArr.${index}.on_admission`}
                                                component='div'
                                                className='text-red-500 text-xs'
                                              />
                                            </div>
                                            <div className='flex justify-end items-end'>
                                              <div
                                                className='remove-row'
                                                onClick={() => remove(index)}
                                              >
                                                <svg
                                                  width='38'
                                                  height='38'
                                                  viewBox='0 0 38 38'
                                                  fill='none'
                                                  xmlns='http://www.w3.org/2000/svg'
                                                >
                                                  <circle
                                                    cx='19'
                                                    cy='19'
                                                    r='19'
                                                    fill='#DE5042'
                                                  />
                                                  <path
                                                    fill-rule='evenodd'
                                                    clip-rule='evenodd'
                                                    d='M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z'
                                                    fill='white'
                                                  />
                                                  <mask
                                                    id='mask0_235_11306'
                                                    style={{
                                                      WebkitMaskType:
                                                        'luminance',
                                                      maskType: 'luminance'
                                                    }}
                                                    maskUnits='userSpaceOnUse'
                                                    x='11'
                                                    y='18'
                                                    width='16'
                                                    height='2'
                                                  >
                                                    <path
                                                      fill-rule='evenodd'
                                                      clip-rule='evenodd'
                                                      d='M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z'
                                                      fill='white'
                                                    />
                                                  </mask>
                                                  <g mask='url(#mask0_235_11306)'></g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              {errors.diagnosisArr &&
                              touched.diagnosisArr &&
                              typeof errors.diagnosisArr === 'string' ? (
                                <div className='max-w-2xl mt-2 text-sm leading-6'>
                                  <p className='text-red-500'>
                                    {errors.diagnosisArr}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </>
                        )
                      }}
                    </FieldArray>
                  </div>
                </div>
              </div>
            </div>

            <EmrSupportingINfo
              requireSupportingInfoElements={supportingInfoElements}
              fieldName='supportingInfos'
            />

            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
              <div>
                <ArrayInput
                  name={'significantSign'}
                  title={'Significant Sign'}
                />
              </div>
              <div>
                <ArrayInput name={'otherCondition'} title={'Other Condition'} />
              </div>
            </div>

            {!isNurseModule && (
              <ItemsList
                emr={true}
                patient={
                  selectedEmrData ? selectedEmrData?.patient : patient?.patient
                }
                rcmDetail={rcmDetail}
              />
            )}

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
              <IsAccident />

              <EncounterForm />
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
              <div className='shadow-md !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100'>
                <div class='flex gap-2 !mb-3'>
                  <div class='inventory-svg'>
                    <svg
                      width='34'
                      height='34'
                      viewBox='0 0 34 34'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='34' height='34' rx='8' fill='#F4F6F8'></rect>
                      <path
                        d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                        fill='#F5793B'
                      ></path>
                      <path
                        d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z'
                        fill='#F5793B'
                      ></path>
                    </svg>
                  </div>
                  <div class='personal-head '>
                    <span className='!text-[#344054] text-lg font-semibold'>
                      Immunization
                    </span>
                    <div className='flex space-x-3 md:mt-0 md:ml-4 mr-2'>
                      <Switch
                        checked={values.isImmunization}
                        onChange={() =>
                          setFieldValue(
                            'isImmunization',
                            !values.isImmunization
                          )
                        }
                        className='group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2'
                      >
                        <span className='sr-only'>Use setting</span>
                        <span
                          aria-hidden='true'
                          className='pointer-events-none absolute h-full w-full rounded-md bg-white'
                        />
                        <span
                          aria-hidden='true'
                          className={classNames(
                            values.isImmunization
                              ? 'bg-amber-600'
                              : 'bg-gray-200',
                            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                          )}
                        />
                        <span
                          aria-hidden='true'
                          className={classNames(
                            values.isImmunization
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
                {values?.isImmunization && (
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
                    <input
                      className='w-full rounded-md border-none'
                      type='datetime-local'
                      name='immunization.occurrenceDateTime'
                      value={values.immunization.occurrenceDateTime}
                      onChange={handleChange}
                      id='date'
                      placeholder='Date'
                    />
                    <input
                      className='w-full rounded-md border-none'
                      name='immunization.vaccine_code'
                      value={values.immunization.vaccine_code}
                      onChange={handleChange}
                      id='vaccine_code'
                      placeholder='Vaccine Code'
                    />
                    <select
                      className='w-full rounded-md border-none'
                      name='immunization.status'
                      onChange={handleChange}
                      value={values.immunization.status}
                      id='status'
                    >
                      <option value=''>Status</option>
                      {statusValues.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className='shadow-md !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100'>
                <div class='flex gap-2 !mb-2'>
                  <div class='inventory-svg'>
                    <svg
                      width='34'
                      height='34'
                      viewBox='0 0 34 34'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='34' height='34' rx='8' fill='#F4F6F8'></rect>
                      <path
                        d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                        fill='#F5793B'
                      ></path>
                      <path
                        d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z'
                        fill='#F5793B'
                      ></path>
                    </svg>
                  </div>
                  <div class='personal-head '>
                    <span className='!text-[#344054] text-lg font-semibold'>
                      Doctor Progress Notes
                    </span>
                  </div>
                </div>
                <div className=''>
                  <div className='!w-full'>
                    <ReactQuill
                      value={editorValue}
                      onChange={handleEditorChange}
                      placeholder='Enter Remarks'
                      onBlur={() =>
                        handleBlur({
                          target: { name: 'doctor_remarks', value: editorValue }
                        })
                      }
                      className='block w-full rounded-md min-h-[150px] border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='flex items-center gap-2'>
              <span className='!text-[#344054] text-lg font-semibold'>
                Close EMR
              </span>
              <Switch
                checked={values.compelete_emr}
                onChange={() =>
                  setFieldValue('compelete_emr', !values.compelete_emr)
                }
                className='group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2'
              ></Switch>
            </div>

            <div>
              <div className='flex justify-center gap-2'>
                {isNurseModule && selectedEmrData?.complete_check ? null : (
                  <Button
                    variant='contained'
                    size='small'
                    type='submit'
                    style={{
                      backgroundImage:
                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                    }}
                    onClick={handleSubmit}
                    startIcon={<CheckOutlinedIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                )}

                {hasInsurancePlans && !isNurseModule && (
                  <Button
                    variant='contained'
                    size='small'
                    type='button'
                    style={{
                      backgroundImage:
                        'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                    }}
                    className={` disabled:!text-white `}
                    onClick={() => requestPreAuthApi(values)}
                    startIcon={<DoneAllIcon />}
                  >
                    Request Pre Auth and Save Emr
                  </Button>
                )}
                {/* {patient?.patient?.insurance_plans?.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className={` disabled:!text-white `}
                    onClick={() => requestPreAuthApi(values)}
                    startIcon={<DoneAllIcon />}
                  >
                    Request Pre Auth and Save Emr
                  </Button>
                )} */}
              </div>
            </div>
          </div>
          <AppLoader
            setShowLoader={setLoading}
            showLoader={Loading || loading}
          />
          {/* <EmrPreAuthModel
            open={open}
            setOpen={setOpen}
            values={values}
            rcmDetail={rcmDetail}
            Detail={detail}
          /> */}
        </>
      )}
    </Formik>
  )
}
