import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React, { useCallback, useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import { getPatientList, SearchPatient } from '../HIS/components/ApiMethods';
import { FemaleOutlined, MaleOutlined } from '@mui/icons-material';
import PatientAppoinmentHistory from '../HIS/components/PatientAppoinmentHistory';
import AppLoader from '../../components/AppLoader';
import PatientMedicalHistory from '../HIS/components/PatientMedicalHistory';
import MenuIcon from '@mui/icons-material/Menu';
import Spinner from '../HIS/components/Spinner';
import { CalculateAge } from '../../util/CalculateAge';

export const calculateAge = dob => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default function NewAppointmentMain() {
  const [searchText, setSearchText] = useState('');
  const [Loading, setloading] = useState(false);
  const [patientList, setPatientList] = useState(null);
  const [activeTab, setActiveTab] = useState('appointment');
  const [open, setOpen] = useState(false);
  const [patientID, setPatientID] = useState(null);
  // const practitioner_id = localStorage.getItem('practitioner_id');
  const user = localStorage?.getItem('user') ? JSON.parse(localStorage?.getItem('user')) : null;
  const practitionerId = user?.practitioner_id || null;
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const prevSelectedPatientid = localStorage.getItem('visit_selected_patient_id');
  const prevSelectedTab = localStorage.getItem('visit_active_tab');

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPatients = text => {
    SearchPatient(setloading, setPatientList, {
      query: text
    });
    setSearchLoading(false);
  };

  const debouncedFetchPatients = useCallback(debounce(fetchPatients, 500), []);

  const fetchPatientList = async () => {
    setLoading(true);
    console.log(practitionerId, user);
    await getPatientList(setLoading, setPatientList, {
      practitioner_id: practitionerId
    }).then(res => {
      if (res) {
        if (prevSelectedPatientid) {
          setPatientID(prevSelectedPatientid);
        }
        if (prevSelectedTab) {
          setActiveTab(prevSelectedTab);
        }
      } else {
        setPatientID(null);
        setActiveTab('appointment');
      }
    });
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    fetchPatientList();
  }, []);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchText(value);
    setSearchLoading(true);
    setPatientID(null);
    if (value) {
      debouncedFetchPatients(value);
    } else {
      getPatientList(setloading, setPatientList, {
        practitioner_id: practitionerId || ''
      });
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    if (prevSelectedPatientid) {
      setPatientID(prevSelectedPatientid);
    }
    if (prevSelectedTab) {
      setActiveTab(prevSelectedTab);
    }
  }, []);

  const tabList = [
    {
      label: 'Visit History',
      comapre: 'appointment',
      command: () => {
        setActiveTab('appointment');
        localStorage.setItem('visit_active_tab', 'appointment');
      }
    },
    {
      label: 'Medical History',
      comapre: 'medical',
      command: () => {
        setActiveTab('medical');
        localStorage.setItem('visit_active_tab', 'medical');
      }
    }
  ];

  return (
    <React.Fragment>
      {!loading && (
        <div className='grid grid-cols-8 gap-2'>
          <div className={`col-span-2 hidden xl:block bg-gray-100 border border-gray-100 p-2 rounded-md h-[85vh] `}>
            <div className='relative rounded-md shadow-sm mb-2'>
              <input
                type='text'
                name='search'
                id='search'
                value={searchText}
                onChange={handleSearchChange}
                className='block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                placeholder='Search'
              />
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                {searchLoading ? (
                  <Spinner size='20px' />
                ) : (
                  <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                )}
              </div>
            </div>
            <div>
              <ul style={{ overflowY: 'auto' }} className='h-[calc(85vh-4rem)] overflow-y-auto'>
                {patientList?.data?.map((patient, index) => (
                  <li
                    key={index}
                    className={`p-2 shadow-sm  mb-2 rounded-md hover:shadow-2xl cursor-pointer ${
                      patientID === patient.patient_id ? ' bg-orange-500 text-white' : 'bg-white '
                    }`}
                    onClick={() => {
                      setPatientID(patient.patient_id);
                      localStorage.setItem('visit_selected_patient_id', patient.patient_id);
                      setActiveTab('appointment');
                      localStorage.setItem('visit_active_tab', 'appointment');
                    }}
                  >
                    <div className=''>
                      <div className='flex items-center'>
                        <div>
                          <Avatar
                            label={patient?.data ? patient?.full_name.charAt(0) : 'S'}
                            className='mb-0.5 text-black w-[2.5rem] h-[2.5rem]'
                            size='large'
                          />
                        </div>
                        <p className='font-bold flex text-sm gap-2 items-center justify-center ml-1'>
                          {patient?.full_name} &nbsp; ({patient.document_id})
                        </p>
                      </div>

                      <div className='flex justify-between !w-full'>
                        <div>
                          <p className='text-xs'>
                            <b>ID:</b> {patient.patient_id}
                          </p>
                          <p className='text-xs'>
                            <b>DID:</b> {patient.document_id}
                          </p>
                        </div>
                        <div className='flex items-center my-auto'>
                          {patient?.gender === 'male' ? <MaleOutlined /> : <FemaleOutlined />}
                          <div className='text-gray-500 flex justify-center font-bold text-sm'>
                            {CalculateAge(patient?.dob)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={` xl:col-span-6 col-span-7 border bg-gray-100 p-2 border-gray-100 rounded-md shadow-inner h-[85vh] ${'col-span-6'}`}
          >
            <ul className='grid grid-flow-col mb-2 text-center border rounded-md text-gray-500 p-1'>
              <span className='flex justify-center items-center cursor-pointer xl:hidden' onClick={() => setOpen(true)}>
                <MenuIcon className='w-[35px] h-[35px]' />
              </span>
              {tabList?.map(tab => (
                <li key={tab.comapre}>
                  <a
                    className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                      activeTab === tab?.comapre ? 'gradient-background rounded shadow text-white' : ''
                    }`}
                    onClick={() => tab?.command()}
                  >
                    {tab.label}
                  </a>
                </li>
              ))}
            </ul>
            <div className='h-[59vh] sm:h-[70vh] overflow-y-auto'>
              {activeTab === 'appointment' ? (
                <PatientAppoinmentHistory patientID={patientID} setAppApiloading={setloading} doctor={true} />
              ) : activeTab === 'medical' ? (
                <PatientMedicalHistory patientID={patientID} />
              ) : null}
            </div>
          </div>
        </div>
      )}
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className='col-span-1 bg-gray-100 border border-gray-100 p-2  h-[100vh]'>
          <div className='relative shadow-sm mb-2'>
            <input
              type='text'
              name='search'
              id='search'
              value={searchText}
              onChange={handleSearchChange}
              className='block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              placeholder='Search'
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              {searchLoading ? (
                <Spinner size='20px' />
              ) : (
                <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              )}
            </div>
          </div>
          <div>
            <ul className='h-[90vh] overflow-y-auto'>
              {patientList?.data?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id ? ' bg-orange-500 text-white' : 'bg-white '
                  }`}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    localStorage.setItem('visit_selected_patient_id', patient.patient_id);
                    localStorage.setItem('visit_active_tab', 'appointment');
                    setActiveTab('appointment');
                    setOpen(false);
                  }}
                >
                  <div className='flex items-center'>
                    <Avatar
                      label={patient?.data ? patient?.full_name.charAt(0) : 'S'}
                      className='mr-2 text-black w-[2.5rem] h-[2.5rem]'
                      size='large'
                    />
                  </div>
                  <div>
                    <p className='font-bold flex text-sm gap-2 items-center justify-center'>
                      {patient?.full_name} &nbsp; ({patient.document_id})
                      <span className='mt-0.5'>{patient?.gender === 'M' ? <MaleOutlined /> : <FemaleOutlined />}</span>
                    </p>
                    <p className='text-xs'>ID: {patient.patient_id}</p>
                    <p className='text-xs'>DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Drawer>
      <AppLoader showLoader={loading} />
    </React.Fragment>
  );
}
