import React, { useEffect, useState } from 'react';
import { Formik, FieldArray, Field, useFormik } from 'formik';
// import { ToastContainer, toast } from "react-toastify";
import toast from 'react-hot-toast';
import _, { set } from 'lodash';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import beneficiary from '../../../gql/beneficiary';
import InsurancePlans from './InsurancePlans';
import { Switch } from '@headlessui/react';
import SubscriberDetails from './SubscriberDetails';
import countries from '../../../components/CountriesList';
import AppModal from '../../../components/AppModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, styled, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import eligibility from '../../../gql/eligibility';
import AddIcon from '@mui/icons-material/Add';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { createCustomer, createPatientHIS } from '../../HIS/components/ApiMethods';
import AppLoader from '../../../components/AppLoader';
import Select from 'react-select';
import insurancePlans from '../../../gql/insurancePlans';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AddBeneficiaryPersonalDataForm({ item, chiReqRecord, setChiReqRecord, formData }) {
  const [addAddress, setAddAddress] = useState(false);
  const [isFormReset, setIsFormReset] = useState(false);
  const [submitEligibility, setSubmitEligibility] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const currentDate = new Date();
  // const hisPayers = JSON.parse(localStorage.getItem("hisPayers"));
  const [hisPayers, setHisPayers] = useState({});

  useEffect(() => {
    const hisPayersWithoutParsed = localStorage.getItem('hisPayers');

    if (
      hisPayersWithoutParsed === null ||
      hisPayersWithoutParsed === undefined ||
      hisPayersWithoutParsed === 'undefined'
    ) {
      toast.error('HIS Payer not added');
    } else {
      try {
        const parsedHisPayers = JSON.parse(hisPayersWithoutParsed);
        setHisPayers(parsedHisPayers); // Successfully set parsed data
      } catch (e) {
        toast.error('Invalid JSON format');
      }
    }
  }, []);

  const location = useLocation();
  const payers = JSON.parse(localStorage.getItem('payersData'));
  const Statename = location?.state?.name;

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );
  const navigate = useNavigate();

  const [checkEligibility, { data: eligibilityData, loading: loadingData, error: errorData }] = useMutation(
    eligibility.ELIGIBILITY_CHECK,
    {
      update(proxy, result) {
        toast.success('Data send to payer successfully!');
        onSuccessfulEligibilityRequestResponse(result.data);
      },
      onError(error) {
        onEligibilityResponseError(error.graphQLErrors[0]);
      }
    }
  );

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);
  const onSuccessfulEligibilityRequestResponse = data => {
    if (!data.verificationRequest.nphies_err) {
      setShowLoader(false);
      navigate('/eligibility');
      // refetch({
      //   limit: 10,
      //   page: 1,
      //   fromDate: fromDate,
      //   toDate: toDate,
      // });
    } else {
      setShowLoader(false);
      // setErrorData(data.verificationRequest.nphies_err);
      // setShowErrorAlert(true);
    }
  };

  const onEligibilityResponseError = error => {
    setShowLoader(false);
    // refetch({
    //   limit: 10,
    //   page: 1,
    //   fromDate: fromDate,
    //   toDate: toDate,
    // });
    toast.error(`${error?.message}`);
  };

  const [patient_Id, setPatientId] = useState(item ? item.id : null);
  const gender = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' },
    { id: 'unknown', name: 'Unknown' }
    // { id: "other", name: "Undetermined" },
  ];
  const maritalStatuses = [
    { id: 'D', name: 'Divorced' },
    { id: 'L', name: 'Legally Separated' },
    { id: 'M', name: 'Married' },
    { id: 'U', name: 'Unmarried' },
    { id: 'W', name: 'Widowed' }
  ];
  const occupationOptions = [
    { id: 'administration', name: 'Administration' },
    { id: 'agriculture', name: 'Agriculture' },
    { id: 'business', name: 'Business' },
    { id: 'education', name: 'Education' },
    { id: 'housewife', name: 'Housewife' },
    { id: 'marine', name: 'Marine' },
    { id: 'medical field', name: 'Medical Field' },
    { id: 'military', name: 'Military' },
    { id: 'skilled worker', name: 'Skilled Worker' },
    { id: 'student', name: 'Student' },
    { id: 'oil industries', name: 'Oil Industries' },
    { id: 'unemployed', name: 'Unemployed' },
    { id: 'others', name: 'Others' },
    { id: 'unknown', name: 'Unknown' }
  ];
  const religionOptions = [
    { id: '1', name: 'Muslim' },
    { id: '2', name: 'Christian' },
    { id: '3', name: 'Judaism' },
    { id: '4', name: 'Buddhism' },
    { id: '5', name: 'Zoroastrian' },
    { id: '7', name: 'Hinduism' },
    { id: '8', name: 'Sikh' },
    { id: '9', name: 'Without' },
    { id: '98', name: 'Not available' },
    { id: '99', name: 'Not mentioned' },
    { id: '0', name: 'Other' }
  ];
  const xdocumentTypes = [
    { id: 'iqama', name: 'Iqama' },
    { id: 'Resident Card', name: 'Resident Card' },
    { id: 'Passport', name: 'Passport' },
    { id: 'Visa', name: 'Visa' },
    { id: 'National Card', name: 'National Card' },
    { id: 'Medical Record Number', name: 'Medical Record Number' }
  ];
  const documentTypes = [
    { id: 'VP', name: 'VISA' },
    { id: 'PPN', name: 'Passport' },
    { id: 'PRC', name: 'Permanent Resident Card Number' },
    { id: 'MR', name: 'Medical record number' },
    { id: 'NI', name: 'National Card' }
  ];
  const nationalities = [
    { id: 'Afghan', name: 'Afghan' },
    { id: 'Albanian', name: 'Albanian' },
    { id: 'Algerian', name: 'Algerian' },
    { id: 'Andorran', name: 'Andorran' },
    { id: 'Angolan', name: 'Angolan' },
    { id: 'Antiguan or Barbudan', name: 'Antiguan or Barbudan' },
    { id: 'Argentine', name: 'Argentine' },
    { id: 'Armenian', name: 'Armenian' },
    { id: 'Australian', name: 'Australian' },
    { id: 'Austrian', name: 'Austrian' },
    { id: 'Azerbaijani', name: 'Azerbaijani' },
    { id: 'Bahamian', name: 'Bahamian' },
    { id: 'Bahraini', name: 'Bahraini' },
    { id: 'Bangladeshi', name: 'Bangladeshi' },
    { id: 'Barbadian', name: 'Barbadian' },
    { id: 'Belarusian', name: 'Belarusian' },
    { id: 'Belgian', name: 'Belgian' },
    { id: 'Belizean', name: 'Belizean' },
    { id: 'Beninese', name: 'Beninese' },
    { id: 'Bhutanese', name: 'Bhutanese' },
    { id: 'Bolivian', name: 'Bolivian' },
    { id: 'Bosnian or Herzegovinian', name: 'Bosnian or Herzegovinian' },
    { id: 'Botswanan', name: 'Botswanan' },
    { id: 'Brazilian', name: 'Brazilian' },
    { id: 'Bruneian', name: 'Bruneian' },
    { id: 'Bulgarian', name: 'Bulgarian' },
    { id: 'Burkinabé', name: 'Burkinabé' },
    { id: 'Burundian', name: 'Burundian' },
    { id: 'Cabo Verdean', name: 'Cabo Verdean' },
    { id: 'Cambodian', name: 'Cambodian' },
    { id: 'Cameroonian', name: 'Cameroonian' },
    { id: 'Canadian', name: 'Canadian' },
    { id: 'Central African', name: 'Central African' },
    { id: 'Chadian', name: 'Chadian' },
    { id: 'Chilean', name: 'Chilean' },
    { id: 'Chinese', name: 'Chinese' },
    { id: 'Colombian', name: 'Colombian' },
    { id: 'Comorian', name: 'Comorian' },
    { id: 'Congolese (Congo)', name: 'Congolese (Congo)' },
    { id: 'Congolese (DRC)', name: 'Congolese (DRC)' },
    { id: 'Costa Rican', name: 'Costa Rican' },
    { id: 'Croatian', name: 'Croatian' },
    { id: 'Cuban', name: 'Cuban' },
    { id: 'Cypriot', name: 'Cypriot' },
    { id: 'Czech', name: 'Czech' },
    { id: 'Danish', name: 'Danish' },
    { id: 'Djiboutian', name: 'Djiboutian' },
    { id: 'Dominican', name: 'Dominican' },
    { id: 'Dominican Republic', name: 'Dominican Republic' },
    { id: 'Ecuadorian', name: 'Ecuadorian' },
    { id: 'Egyptian', name: 'Egyptian' },
    { id: 'Salvadoran', name: 'Salvadoran' },
    { id: 'Equatorial Guinean', name: 'Equatorial Guinean' },
    { id: 'Eritrean', name: 'Eritrean' },
    { id: 'Estonian', name: 'Estonian' },
    { id: 'Eswatini', name: 'Eswatini' },
    { id: 'Ethiopian', name: 'Ethiopian' },
    { id: 'Fijian', name: 'Fijian' },
    { id: 'Finnish', name: 'Finnish' },
    { id: 'French', name: 'French' },
    { id: 'Gabonese', name: 'Gabonese' },
    { id: 'Gambian', name: 'Gambian' },
    { id: 'Georgian', name: 'Georgian' },
    { id: 'German', name: 'German' },
    { id: 'Ghanaian', name: 'Ghanaian' },
    { id: 'Greek', name: 'Greek' },
    { id: 'Grenadian', name: 'Grenadian' },
    { id: 'Guatemalan', name: 'Guatemalan' },
    { id: 'Guinean', name: 'Guinean' },
    { id: 'Bissau-Guinean', name: 'Bissau-Guinean' },
    { id: 'Guyanese', name: 'Guyanese' },
    { id: 'Haitian', name: 'Haitian' },
    { id: 'Honduran', name: 'Honduran' },
    { id: 'Hungarian', name: 'Hungarian' },
    { id: 'Icelandic', name: 'Icelandic' },
    { id: 'Indian', name: 'Indian' },
    { id: 'Indonesian', name: 'Indonesian' },
    { id: 'Iranian', name: 'Iranian' },
    { id: 'Iraqi', name: 'Iraqi' },
    { id: 'Irish', name: 'Irish' },
    { id: 'Israeli', name: 'Israeli' },
    { id: 'Italian', name: 'Italian' },
    { id: 'Jamaican', name: 'Jamaican' },
    { id: 'Japanese', name: 'Japanese' },
    { id: 'Jordanian', name: 'Jordanian' },
    { id: 'Kazakh', name: 'Kazakh' },
    { id: 'Kenyan', name: 'Kenyan' },
    { id: 'Kiribati', name: 'Kiribati' },
    { id: 'North Korean', name: 'North Korean' },
    { id: 'South Korean', name: 'South Korean' },
    { id: 'Kuwaiti', name: 'Kuwaiti' },
    { id: 'Kyrgyz', name: 'Kyrgyz' },
    { id: 'Lao', name: 'Lao' },
    { id: 'Latvian', name: 'Latvian' },
    { id: 'Lebanese', name: 'Lebanese' },
    { id: 'Basotho', name: 'Basotho' },
    { id: 'Liberian', name: 'Liberian' },
    { id: 'Libyan', name: 'Libyan' },
    { id: 'Liechtenstein', name: 'Liechtenstein' },
    { id: 'Lithuanian', name: 'Lithuanian' },
    { id: 'Luxembourgish', name: 'Luxembourgish' },
    { id: 'Malagasy', name: 'Malagasy' },
    { id: 'Malawian', name: 'Malawian' },
    { id: 'Malaysian', name: 'Malaysian' },
    { id: 'Maldivian', name: 'Maldivian' },
    { id: 'Malian', name: 'Malian' },
    { id: 'Maltese', name: 'Maltese' },
    { id: 'Marshallese', name: 'Marshallese' },
    { id: 'Mauritanian', name: 'Mauritanian' },
    { id: 'Mauritian', name: 'Mauritian' },
    { id: 'Mexican', name: 'Mexican' },
    { id: 'Micronesian', name: 'Micronesian' },
    { id: 'Moldovan', name: 'Moldovan' },
    { id: 'Monégasque', name: 'Monégasque' },
    { id: 'Mongolian', name: 'Mongolian' },
    { id: 'Montenegrin', name: 'Montenegrin' },
    { id: 'Moroccan', name: 'Moroccan' },
    { id: 'Mozambican', name: 'Mozambican' },
    { id: 'Burmese', name: 'Burmese' },
    { id: 'Namibian', name: 'Namibian' },
    { id: 'Nauruan', name: 'Nauruan' },
    { id: 'Nepali', name: 'Nepali' },
    { id: 'Dutch', name: 'Dutch' },
    { id: 'New Zealander', name: 'New Zealander' },
    { id: 'Nicaraguan', name: 'Nicaraguan' },
    { id: 'Nigerien', name: 'Nigerien' },
    { id: 'Nigerian', name: 'Nigerian' },
    { id: 'Macedonian', name: 'Macedonian' },
    { id: 'Norwegian', name: 'Norwegian' },
    { id: 'Omani', name: 'Omani' },
    { id: 'Pakistani', name: 'Pakistani' },
    { id: 'Palauan', name: 'Palauan' },
    { id: 'Panamanian', name: 'Panamanian' },
    { id: 'Papua New Guinean', name: 'Papua New Guinean' },
    { id: 'Paraguayan', name: 'Paraguayan' },
    { id: 'Peruvian', name: 'Peruvian' },
    { id: 'Filipino', name: 'Filipino' },
    { id: 'Polish', name: 'Polish' },
    { id: 'Portuguese', name: 'Portuguese' },
    { id: 'Palestinian', name: 'Palestinian' },
    { id: 'Qatari', name: 'Qatari' },
    { id: 'Romanian', name: 'Romanian' },
    { id: 'Russian', name: 'Russian' },
    { id: 'Rwandan', name: 'Rwandan' },
    { id: 'Saint Kitts and Nevis', name: 'Saint Kitts and Nevis' },
    { id: 'Saint Lucian', name: 'Saint Lucian' },
    { id: 'Saint Vincentian', name: 'Saint Vincentian' },
    { id: 'Samoan', name: 'Samoan' },
    { id: 'San Marinese', name: 'San Marinese' },
    { id: 'São Toméan', name: 'São Toméan' },
    { id: 'saudi', name: 'Saudi Arabian' },
    { id: 'Senegalese', name: 'Senegalese' },
    { id: 'Serbian', name: 'Serbian' },
    { id: 'Seychellois', name: 'Seychellois' },
    { id: 'Sierra Leonean', name: 'Sierra Leonean' },
    { id: 'Singaporean', name: 'Singaporean' },
    { id: 'Slovak', name: 'Slovak' },
    { id: 'Slovene', name: 'Slovene' },
    { id: 'Solomon Islander', name: 'Solomon Islander' },
    { id: 'Somali', name: 'Somali' },
    { id: 'South African', name: 'South African' },
    { id: 'Spanish', name: 'Spanish' },
    { id: 'Sri Lankan', name: 'Sri Lankan' },
    { id: 'Sudanese', name: 'Sudanese' },
    { id: 'Surinamese', name: 'Surinamese' },
    { id: 'Swedish', name: 'Swedish' },
    { id: 'Swiss', name: 'Swiss' },
    { id: 'Syrian', name: 'Syrian' },
    { id: 'Taiwanese', name: 'Taiwanese' },
    { id: 'Tajik', name: 'Tajik' },
    { id: 'Tanzanian', name: 'Tanzanian' },
    { id: 'Thai', name: 'Thai' },
    { id: 'Timorese', name: 'Timorese' },
    { id: 'Togolese', name: 'Togolese' },
    { id: 'Tongan', name: 'Tongan' },
    { id: 'Trinidadian or Tobagonian', name: 'Trinidadian or Tobagonian' },
    { id: 'Tunisian', name: 'Tunisian' },
    { id: 'Turkish', name: 'Turkish' },
    { id: 'Turkmen', name: 'Turkmen' },
    { id: 'Tuvaluan', name: 'Tuvaluan' },
    { id: 'Ugandan', name: 'Ugandan' },
    { id: 'Ukrainian', name: 'Ukrainian' },
    { id: 'Emirati', name: 'Emirati' },
    { id: 'British', name: 'British' },
    { id: 'American', name: 'American' },
    { id: 'Uruguayan', name: 'Uruguayan' },
    { id: 'Uzbek', name: 'Uzbek' },
    { id: 'Vanuatuan', name: 'Vanuatuan' },
    { id: 'Venezuelan', name: 'Venezuelan' },
    { id: 'Vietnamese', name: 'Vietnamese' },
    { id: 'Yemeni', name: 'Yemeni' },
    { id: 'Zambian', name: 'Zambian' },
    { id: 'Zimbabwean', name: 'Zimbabwean' }
  ];
  const residencyTypes = [
    { id: 'iqama', name: 'Iqama' },
    { id: 'visa', name: 'Visitor' },
    { id: 'nationalId', name: 'Citizen or Resident' }
  ];

  const bloodGroups = [
    { id: 'O+', name: 'O+' },
    { id: 'O-', name: 'O-' },
    { id: 'A+', name: 'A+' },
    { id: 'A-', name: 'A-' },
    { id: 'B+', name: 'B+' },
    { id: 'B-', name: 'B-' }
  ];
  const city = [
    { value: 'riyadh', label: 'Riyadh' },
    { value: 'jeddah', label: 'Jeddah' },
    { value: 'mecca', label: 'Mecca' },
    { value: 'medina', label: 'Medina' },
    { value: 'dhahran', label: 'Dhahran' },
    { value: 'dammam', label: 'Dammam' },
    { value: 'khobar', label: 'Khobar' },
    { value: 'tabuk', label: 'Tabuk' },
    { value: 'abha', label: 'Abha' },
    { value: 'khamis_mushait', label: 'Khamis Mushait' },
    { value: 'najran', label: 'Najran' },
    { value: 'buraydah', label: 'Buraydah' },
    { value: 'al_hasa', label: 'Al Hasa' },
    { value: 'al_mubarak', label: 'Al Mubarak' },
    { value: 'hafar_al_batin', label: 'Hafar Al Batin' },
    { value: 'al_jouf', label: 'Al Jouf' },
    { value: 'yanbu', label: 'Yanbu' },
    { value: 'al_ula', label: 'Al Ula' },
    { value: 'al_qatif', label: 'Al Qatif' },
    { value: 'al_madinah', label: 'Al Madinah' },
    { value: 'asa', label: 'Asa' },
    { value: 'taif', label: 'Taif' }
  ];
  const visaType = [
    { id: 6, name_ar: 'مرور', name_en: 'Transit' },
    {
      id: 10,
      name_ar: 'سياحية او سياحية - هيا',
      name_en: 'Tourism or Tourist - Haya'
    },
    { id: 14, name_ar: 'توصيل بضائع', name_en: 'Cargo Delivery' },
    { id: 23, name_ar: 'زيارة تجارية', name_en: 'Business Visit' },
    { id: 24, name_ar: 'زيارة عائلية', name_en: 'Family Visit' },
    { id: 21, name_ar: 'رجال اعمال', name_en: 'Businessmen' },
    { id: 22, name_ar: 'زيارة عمل', name_en: 'Work Visit' },
    { id: 27, name_ar: 'زيارة شخصية', name_en: 'Personal Visit' },
    { id: 40, name_ar: 'مرافق', name_en: 'Accompanying' },
    { id: 30, name_ar: 'زيارة فعالية', name_en: 'Event Visit' }
  ];

  const profession = [
    { value: 'management', label: 'Management' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'education', label: 'Education' },
    { value: 'business', label: 'Business' },
    { value: 'medical field', label: 'Medical field' },
    { value: 'military', label: 'Military' },
    { value: 'navy', label: 'Navy' },
    { value: 'worker', label: 'Worker' },
    { value: 'student', label: 'Student' },
    { value: 'oil industry', label: 'Oil industry' },
    { value: 'unepmloyed', label: 'Unepmloyed' },
    { value: 'other', label: 'Other' }
  ];
  const religions = [
    { value: 'christianity', label: 'Christianity' },
    { value: 'islam', label: 'Islam' },
    { value: 'hinduism', label: 'Hinduism' },
    { value: 'buddhism', label: 'Buddhism' },
    { value: 'sikhism', label: 'Sikhism' },
    { value: 'judaism', label: 'Judaism' },
    { value: 'bahai', label: "Baha'i Faith" },
    { value: 'jainism', label: 'Jainism' },
    { value: 'shinto', label: 'Shinto' },
    { value: 'zoroastrianism', label: 'Zoroastrianism' },
    { value: 'taoism', label: 'Taoism' },
    { value: 'confucianism', label: 'Confucianism' },
    { value: 'animism', label: 'Animism' },
    { value: 'neo_paganism', label: 'Neo-Paganism' },
    { value: 'rastafarianism', label: 'Rastafarianism' },
    { value: 'gnosticism', label: 'Gnosticism' },
    { value: 'unitarian_universalism', label: 'Unitarian Universalism' },
    { value: 'spiritualism', label: 'Spiritualism' },
    { value: 'atheism', label: 'Atheism' },
    { value: 'agnosticism', label: 'Agnosticism' },
    { value: 'secular_humanism', label: 'Secular Humanism' },
    { value: 'humanism', label: 'Humanism' },
    { value: 'church_of_the_subgenius', label: 'Church of the SubGenius' },
    { value: 'pastafarianism', label: 'Pastafarianism' },
    { value: 'other', label: 'Other' }
  ];
  const tableHeaders = [
    {
      name: 'House No./ Apartment'
    },
    {
      name: 'Street Name'
    },
    {
      name: 'City'
    },
    {
      name: 'State/Province'
    },
    {
      name: 'Country'
    },
    {
      name: 'Postal Code'
    }
  ];

  const onSuccessfulRequestResponse = data => {
    if (data.registerPatient) {
      toast.success('Successfully Added!');
      formik.resetForm();
      setChiReqRecord(null);
      setIsFormReset(true);
      setPatientId(data.registerPatient.data.id);
      navigate('/beneficiary');
      // !submitEligibility && navigate("/eligibility");
    } else {
    }
  };

  const onResponseError = error => {
    toast.error(`${error?.message}`);
  };

  const [createPatient, { data, loading, error }] = useMutation(beneficiary.CREATE_PATIENT, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    }
  });

  const [editBeneficiary, { updatedData, updatedLoading, updatedError }] = useMutation(beneficiary.UPDATE_BENEFICIARY, {
    update(proxy, result) {
      onSuccessfulEditResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    }
  });

  const onSuccessfulEditResponse = data => {
    if (data?.updatePatient) {
      toast.success(data?.updatePatient?.message || 'Update Successfully!');
      formik.resetForm();
      setChiReqRecord(null);
      setIsFormReset(true);
      navigate('/beneficiary');
      // refetch();
    } else {
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
    if (updatedError?.graphQLErrors[0]?.message) {
      toast.error(updatedError?.graphQLErrors[0]?.message);
    }
  }, [error, updatedError]);

  const formatInputData = (postData, rcm_ref) => {
    return {
      start_date: currentDate.toISOString().substring(0, 10),
      end_date: currentDate.toISOString().substring(0, 10),
      payer_id: `${postData?.insurance_plans?.length > 0 && postData?.insurance_plans[0]?.payer_id}`,
      patient_id: rcm_ref ? rcm_ref : postData?.id,
      insurance_plan_id: `${
        postData?.insurance_plans?.length > 0
          ? postData?.insurance_plans[0]?.id ?? postData?.insurance_plans[0]?.insurance_plan_id
          : ''
      }`,
      purpose: ['benefits', 'validation'],
      is_new_born: false,
      is_referral: false
    };
  };
  // console.log({
  //   payer_name: payers
  //     ?.flatMap((payerGroup) => payerGroup.payers) // Flatten all payers from all groups
  //     ?.find((payer) => payer.id === "1")?.name_en, // Find the payer with the matching id // Get the name_en field if the payer is found
  // });

  const addData = async values => {
    let data = _.cloneDeep(values);
    const { id, ...resDadta } = data;
    let { status, beneficiary_type, address, name, ...otherFields } = data;
    const user = JSON.parse(localStorage?.getItem('user'));
    const businessId = user?.providers_id;
    const branchId = user?.providers_branch_id;

    try {
      if (item) {
        data['id'] = item.id;

        delete data.insurance_plans;
        delete data.beneficiary_file_id;

        let payload = {
          id: item?.rcm_ref,
          document_id: data?.document_id,
          file_no: data?.file_no,
          last_name: data?.last_name,
          street_line: data?.address_line,
          state: data?.state,
          country: data?.country,
          city: item?.city,
          postal_code: data?.postal_code,
          nphies_id: data?.nphies_id,
          subscriber_id: data?.subscriber_id,
          subscriber_relationship: data?.subscriber_relationship,
          is_new_born: data?.is_new_born,
          passport_no: data?.passport_no,
          visa_no: data?.visa_no,
          visa_title: data?.visa_title,
          visa_type: data?.visa_type,
          border_no: data?.border_no,
          insurance_duration: data?.insurance_duration,
          first_name: data?.first_name,
          middle_name: data?.middle_name,
          family_name: data?.family_name,
          full_name: data?.name,
          dob: data?.dob,
          eHealth_id: data?.eHealth_id,
          nationality: data?.nationality,
          residency_type: data?.residency_type,
          document_type: data?.document_type,
          contact_number: data?.contact_number,
          martial_status: data?.martial_status,
          religion: data?.religion,
          occupation: data?.occupation,
          gender: data?.gender,
          blood_group: data?.blood_group,
          preferred_language: data?.preferred_language,
          emergency_number: data?.emergency_number,
          email: data?.email,
          address_line: data?.address_line
        };

        if (values?.insurance_plans) {
          payload.insurance_plans = values?.insurance_plans?.map(plan => {
            const { class_id, class_name, class_type, payer_name, payer_id, ...remainingFields } = plan;
            const parsedPlan = {
              ...remainingFields,
              policy_class_id: class_id,
              policy_class_name: class_name,
              policy_class_type: class_type,
              max_limit: parseInt(plan.max_limit),
              patient_share: parseInt(plan.patient_share),
              payer_id: hisPayers?.find(payer => payer?.payer_id == plan?.payer_id)?.rcm_ref || payer_id
            };
            delete parsedPlan?.policy_class;
            delete parsedPlan?.insurance_plan_id;
            delete parsedPlan?.payer_name;

            return parsedPlan;
          });
        }
        const res = await editBeneficiary({
          variables: {
            input: payload
          }
        }).then(response1 => {
          if (response1?.data) {
            createPatientHIS({
              setShowLoader,
              data: {
                ...data,
                insurance_plans: values?.insurance_plans?.map(plan => ({
                  ...plan,
                  max_limit: parseInt(plan.max_limit),
                  payer_name: payers
                    ?.flatMap(payerGroup => payerGroup.payers)
                    ?.find(payer => payer.id === plan?.payer_id)?.name_en,
                  patient_share: parseInt(plan.patient_share),
                  class_name: plan?.class_name,
                  class_id: plan?.class_id,
                  class_type: plan?.class_type,
                  insurance_plan_id: plan?.insurance_plan_id
                })),
                rcm_ref: item?.rcm_ref || values?.rcm_ref,
                full_name: data?.name,
                contact: data?.contact_number,
                passport_no: data.passport_no.toString(),
                visa_no: data.visa_no.toString(),
                border_no: data.border_no.toString(),
                business_id: businessId,
                branch_id: branchId,
                gender: values?.gender,
                id: item?.patient_id
              }
            }).then(res => {
              createCustomer({
                setShowLoader,
                data: {
                  ...resDadta,
                  insurance_plans: values?.insurance_plans?.map(plan => ({
                    ...plan,
                    max_limit: parseInt(plan.max_limit),
                    payer_name: payers
                      ?.flatMap(payerGroup => payerGroup.payers)
                      ?.find(payer => payer.id === plan?.payer_id)?.name_en,
                    patient_share: parseInt(plan.patient_share),
                    class_name: plan?.class_name,
                    class_id: plan?.class_id,
                    class_type: plan?.class_type,
                    insurance_plan_id: plan?.insurance_plan_id
                  })),
                  passport_no: data.passport_no.toString(),
                  visa_no: data.visa_no.toString(),
                  border_no: data.border_no.toString(),
                  business_id: businessId,
                  branch_id: branchId,
                  his_refrence: id,
                  primary_contact_number: data?.contact_number,
                  file_no: String(res?.file_no),
                  type: data?.beneficiary_type,
                  max_limit: parseInt(values?.insurance_plans[0]?.max_limit)
                }
              });
            });
          }
        });

        if (submitEligibility) {
          toast.loading('Checking Eligibility', { duration: 1000 });
          await checkEligibility({
            variables: {
              input: formatInputData(values, item?.rcm_ref)
            }
          });
        }
      } else {
        delete data.beneficiary_file_id;
        if (formik.values.beneficiary_type === 'Insurance') {
          const { id, ...restData } = data;
          // {
          //   console.table(hisPayers);
          // }
          const response = await createPatient({
            variables: {
              input: {
                ...otherFields,
                insurance_plans: formik?.values?.insurance_plans?.map(plan => {
                  const { class_id, class_name, class_type, payer_name, payer_id, ...remainingFields } = plan;
                  const parsedPlan = {
                    ...remainingFields,
                    policy_class_id: class_id,
                    policy_class_name: class_name,
                    policy_class_type: class_type,
                    max_limit: parseInt(plan.max_limit),
                    patient_share: parseInt(plan.patient_share),
                    payer_id: hisPayers?.find(payer => payer?.payer_id == plan?.payer_id)?.rcm_ref || payer_id
                  };
                  delete parsedPlan.payer_name;

                  return parsedPlan;
                }),

                full_name: values?.name,
                gender: values?.gender,
                nationality: values?.nationality
              }
            }
          });

          if (!response?.data?.registerPatient?.data) return;

          if (submitEligibility) {
            let eligibilityResponse = null;
            console.log('check eligibility True');
            if (
              response?.data?.registerPatient?.data &&
              hisPayers?.find(
                payer => payer?.rcm_ref == response?.data?.registerPatient?.data?.insurance_plans[0]?.payer_id
              )
            ) {
              toast.loading('Checking Eligibility', { duration: 1000 });
              eligibilityResponse = await checkEligibility({
                variables: {
                  input: formatInputData(response?.data?.registerPatient?.data, null)
                }
              });
            } else {
              toast.error('Payer ref not exist!');
            }

            if (eligibilityResponse?.data?.verificationRequest?.nphies_status === 'error') {
              const hisRes = await createPatientHIS({
                setShowLoader,
                data: {
                  ...data,
                  insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                    ...plan,
                    insurance_plan_id: response?.data?.registerPatient?.data?.insurance_plans[index]?.id,
                    max_limit: parseInt(plan.max_limit),
                    payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                    payer_id: hisPayers?.find(payer => payer?.payer_id == plan?.payer_id)?.payer_id || plan?.payer_id,
                    patient_share: parseInt(plan.patient_share)
                  })),
                  rcm_ref: response?.data?.registerPatient?.data?.id,
                  full_name: data?.name,
                  contact: data?.contact_number,
                  passport_no: data.passport_no.toString(),
                  visa_no: data.visa_no.toString(),
                  border_no: data.border_no.toString(),
                  business_id: businessId,
                  branch_id: branchId,
                  gender: values?.gender
                }
              });
              if (hisRes) {
                await createCustomer({
                  setShowLoader,
                  data: {
                    ...restData,
                    insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                      ...plan,
                      max_limit: parseInt(plan.max_limit),
                      insurance_plan_id: response?.data?.registerPatient?.data?.insurance_plans[index]?.id,
                      payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                      payer_id: hisPayers?.find(payer => payer?.rcm_ref == plan?.payer_id)?.payer_id || plan?.payer_id,
                      patient_share: parseInt(plan.patient_share),
                      class_name: plan?.class_name,
                      class_id: plan?.class_id,
                      class_type: plan?.class_type
                    })),
                    passport_no: data.passport_no.toString(),
                    visa_no: data.visa_no.toString(),
                    border_no: data.border_no.toString(),
                    business_id: businessId,
                    branch_id: branchId,
                    his_refrence: hisRes?.data,
                    primary_contact_number: data?.contact_number,
                    file_no: String(hisRes?.file_no),
                    type: data?.beneficiary_type,
                    max_limit: parseInt(data?.insurance_plans[0]?.max_limit)
                  }
                });
              }
            } else {
              const hisRes = await createPatientHIS({
                setShowLoader,
                data: {
                  ...data,
                  insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                    ...plan,
                    insurance_plan_id: response?.data?.registerPatient?.data?.insurance_plans[index]?.id,
                    max_limit: parseInt(plan.max_limit),
                    payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                    payer_id: hisPayers?.find(payer => payer?.rcm_ref == plan?.payer_id)?.payer_id || plan?.payer_id,
                    patient_share: parseInt(plan.patient_share),
                    class_name: plan?.class_name,
                    class_id: plan?.class_id,
                    class_type: plan?.class_type
                  })),
                  rcm_ref: response?.data?.registerPatient?.data?.id,
                  full_name: data?.name,
                  contact: data?.contact_number,
                  passport_no: data.passport_no.toString(),
                  visa_no: data.visa_no.toString(),
                  border_no: data.border_no.toString(),
                  business_id: businessId,
                  branch_id: branchId,
                  gender: values?.gender
                }
              });
              if (hisRes) {
                await createCustomer({
                  setShowLoader,
                  data: {
                    ...restData,
                    insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                      ...plan,
                      max_limit: parseInt(plan.max_limit),
                      insurance_plan_id: response?.data?.registerPatient?.data?.insurance_plans[index]?.id,
                      payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                      payer_id: hisPayers?.find(payer => payer?.rcm_ref == plan?.payer_id)?.payer_id || plan?.payer_id,
                      patient_share: parseInt(plan.patient_share),
                      class_name: plan?.class_name,
                      class_id: plan?.class_id,
                      class_type: plan?.class_type
                    })),
                    passport_no: data.passport_no.toString(),
                    visa_no: data.visa_no.toString(),
                    border_no: data.border_no.toString(),
                    business_id: businessId,
                    branch_id: branchId,
                    his_refrence: hisRes?.data,
                    primary_contact_number: data?.contact_number,
                    file_no: String(hisRes?.file_no),
                    type: data?.beneficiary_type,
                    max_limit: parseInt(data?.insurance_plans[0]?.max_limit)
                  }
                });
              }
            }
          }
        } else if (formik.values.beneficiary_type === 'Cash') {
          const { id, ...restData } = data;
          const res = await createPatient({
            variables: {
              input: {
                ...otherFields,
                insurance_plans: values?.insurance_plans?.map((plan, index) => {
                  const { class_id, class_name, class_type, payer_id, payer_name, ...remainingFields } = plan;
                  const parsedPlan = {
                    ...remainingFields,
                    policy_class_id: class_id,
                    policy_class_name: class_name,
                    policy_class_type: class_type,
                    max_limit: parseInt(plan.max_limit),
                    patient_share: parseInt(plan.patient_share),
                    payer_id: hisPayers?.find(payer => payer?.payer_id == plan?.payer_id)?.rcm_ref || payer_id
                  };
                  delete parsedPlan.payer_name;
                  return parsedPlan;
                }),
                full_name: values?.name,
                gender: values?.gender,
                nationality: values?.nationality
              }
            }
          });
          if (res) {
            const hisRes = await createPatientHIS({
              setShowLoader,
              data: {
                ...data,
                insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                  ...plan,
                  max_limit: parseInt(plan.max_limit),
                  payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                  patient_share: parseInt(plan.patient_share),
                  class_name: plan?.class_name,
                  class_id: plan?.class_id,
                  class_type: plan?.class_type
                })),

                rcm_ref: res?.data?.registerPatient?.data?.id,
                full_name: data?.name,
                contact: data?.contact_number,
                passport_no: data.passport_no.toString(),
                visa_no: data.visa_no.toString(),
                border_no: data.border_no.toString(),
                business_id: businessId,
                branch_id: branchId,
                gender: values?.gender
              }
            });

            if (hisRes) {
              await createCustomer({
                setShowLoader,
                data: {
                  ...restData,

                  insurance_plans: values?.insurance_plans?.map((plan, index) => ({
                    ...plan,
                    max_limit: parseInt(plan.max_limit),
                    payer_name: hisPayers?.find(payer => payer?.payer_id === plan?.payer_id)?.name,
                    patient_share: parseInt(plan.patient_share),
                    class_name: plan?.class_name,
                    class_id: plan?.class_id,
                    class_type: plan?.class_type
                  })),

                  passport_no: data.passport_no.toString(),
                  visa_no: data.visa_no.toString(),
                  border_no: data.border_no.toString(),
                  business_id: businessId,
                  branch_id: branchId,
                  primary_contact_number: data?.contact_number,
                  his_refrence: hisRes?.data,
                  file_no: String(hisRes?.file_no),
                  type: data?.beneficiary_type
                }
              });
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in addData:', error);
      toast.error('An error occurred, please try again.');
    }
  };

  const inValidBtn = values => {
    if (!values?.is_new_born && values?.insurance_plans?.length < 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (chiReqRecord) {
      formik.resetForm();
      formik.setFieldValue('name', chiReqRecord?.Insurance[0]?.Name || '');
      formik.setFieldValue('document_id', chiReqRecord?.Insurance[0]?.IdentityNumber || '');

      formik.setFieldValue(
        'gender',
        chiReqRecord?.Insurance[0]?.Gender === 1
          ? 'male'
          : chiReqRecord?.Insurance[0]?.Gender === 2
          ? 'female'
          : chiReqRecord?.Insurance[0]?.Gender === 3
          ? 'unknown'
          : 'other' || ''
      );
    }
  }, [chiReqRecord]);

  const formik = useFormik({
    initialValues: {
      beneficiary_type:
        item?.beneficiary_type || formData?.beneficiaryType || chiReqRecord ? 'Insurance' : 'Cash' || 'Cash',
      status: 'Active',
      full_name: item?.full_name || formData?.full_name || '',
      first_name: item?.first_name || (chiReqRecord ? chiReqRecord?.Insurance[0]?.Name : ''),
      middle_name: item?.middle_name || '',
      last_name: item?.last_name || '',
      family_name: item?.family_name || '',
      name: item?.full_name || (chiReqRecord ? chiReqRecord?.Insurance[0]?.Name : ''),
      file_no: item?.file_no || '',
      dob: item?.dob || formData?.dob || currentDate.toISOString().substring(0, 10),
      gender:
        item?.gender ||
        formData?.gender ||
        (chiReqRecord
          ? chiReqRecord?.Insurance[0]?.Gender === 1
            ? 'male'
            : chiReqRecord?.Insurance[0]?.Gender === 2
            ? 'female'
            : 'unknown'
          : ''),
      address: item?.address || formData?.address || '',
      document_type: item?.document_type || formData?.documentType || '',
      document_id:
        item?.document_id || formData?.documentId || (chiReqRecord ? chiReqRecord?.Insurance[0]?.IdentityNumber : ''),
      nphies_id: item?.nphies_id || '',
      eHealth_id: item?.eHealth_id || '',
      nationality: item?.nationality || formData?.nationality || '',
      residency_type: item?.residency_type || '',
      contact_number: item?.contact || '',
      martial_status: item?.martial_status || 'U',
      religion: item?.religion || '1',
      occupation: item?.occupation || 'others',
      blood_group: item?.blood_group || '',
      preferred_language: item?.preferred_language || '',
      emergency_number: item?.emergency_number || '',
      email: item?.email || '',
      is_new_born: item?.is_new_born || false,
      street_line: item?.street_line || '',
      address_line: item?.address_line || '',
      city: item?.city || '',
      state: item?.state || '',
      country: item?.country || '',
      postal_code: item?.postal_code || '',
      subscriber_id: item?.subscriber_id || '',
      subscriber_relationship: item?.subscriber_relationship || '',
      subscriber_insurance_plan: item?.subscriber_insurance_plan || '',
      insurance_plans: item?.insurance_plans || [],
      passport_no: item?.passport_no || '',
      visa_no: item?.visa_no || formData?.visaNo || '',
      visa_title: item?.visa_title || '',
      visa_type: item?.visa_type || '',
      border_no: item?.border_no || '',
      insurance_duration: item?.insurance_duration || '',
      id: item?.patient_id || ''
      // maternity: item?.maternity || "",
    },

    enableReinitialize: true,

    validationSchema: () => {
      return Yup.object().shape({
        // first_name: Yup.string().required("first_name is required"),
        name: Yup.string().required('Full Name is required'),
        // file_no: Yup.string().required("File no is required"),
        dob: Yup.date()
          .nullable()
          .required('Dob is required')
          .max(new Date(), 'Dob cannot be in the future')
          .typeError('Please enter a valid date'),
        gender: Yup.string().required('Gender is required'),
        document_type: Yup.string().required('Document Type is required'),
        document_id: Yup.string().required('Document id is required'),
        insurance_plans:
          formik.values.beneficiary_type === 'Insurance'
            ? Yup.array()
                .of(
                  Yup.object().shape({
                    member_card_id: Yup.string().required('Member Card ID is required'),
                    policy_number: Yup.string().required('Policy number is required'),
                    expiry_date: Yup.date().required('Expiry date is required'),
                    issue_date: Yup.string().required('Issue Date is required'),
                    is_primary: Yup.boolean().required('Is primary is required'),
                    payer_id: Yup.string().required('Payer ID is required'),
                    max_limit: Yup.number().required('Max Limit is required'),
                    relation_with_subscriber: Yup.string().required('Relation with subscriber is required'),
                    class_type: Yup.string().required('Class Type is required'),
                    class_name: Yup.string().required('Class Name is required'),
                    class_id: Yup.string().required('Class ID is required')
                  })
                )
                .required('At least one insurance plan is required')
                .min(1, 'At least one insurance plan is required')
            : null,
        // nphies_id: Yup.string().required("nphies_id is required"),
        // eHealth_id: Yup.string().required("eHealth_id is required"),
        // nationality: Yup.string().required("nationality is required"),
        residency_type: Yup.string().required('Residency type is required'),
        // contact_number: Yup.string().required("Contact Number is required"),
        contact_number: Yup.string()
          .required('Contact Number is required')
          .matches(/^\d{10}$/, 'Contact Number must be exactly 10 digits'),
        martial_status: Yup.string().required('martial_status is required'),
        religion: Yup.string().required('religion is required'),
        occupation: Yup.string().required('occupation is required'),
        nationality: Yup.string().required('Nationality is required'),
        // maternity: Yup.string().required("maternity is required"),
        // emergency_number: Yup.string().required("emergency_number is required"),
        // email: Yup.string().email("Invalid email").required("email is required"),
        // street_line: Yup.string().required("street_line is required"),
        // address_line: Yup.string().required("address_line is required"),
        // city: Yup.string().required("city is required"),
        // state: Yup.string().required("state is required"),
        // country: Yup.string().required("country is required"),
        passport_no: Yup.number(),
        visa_no: Yup.number(),
        visa_title: Yup.string(),
        visa_type: Yup.number(),
        border_no: Yup.number(),
        insurance_duration: Yup.string(),
        address: Yup.string().required('Address is required'),
        beneficiary_type: Yup.string().required('Beneficiary Type is required')
      });
    },

    onSubmit: (values, { setSubmitting }) => {
      addData(values);
    }
  });
  useEffect(() => {
    if (item) {
      // populat form with data
      Object.keys(formik.values).forEach(key => {
        if (item[key] !== undefined) {
          formik.setFieldValue(key, item[key]);
        }
      });

      // Insurance
      if (item.insurance_plans && item.insurance_plans.length > 0) {
        formik.setFieldValue('insurance_plans', item.insurance_plans);
      }
    }
  }, [item]);

  useEffect(() => {
    if (formik.values?.document_id?.length > 0) {
      if (formik.values?.document_id[0] == 1) {
        formik.setFieldValue('document_type', 'NI');
        formik.setFieldValue('residency_type', 'nationalId');
      } else if (formik.values?.document_id[0] == 2) {
        formik.setFieldValue('document_type', 'PRC');
        formik.setFieldValue('residency_type', 'iqama');
      } else if (formik.values?.document_id[0] == 4) {
        formik.setFieldValue('document_type', 'VP');
        formik.setFieldValue('residency_type', 'visa');
      }
    }
  }, [formik.values?.document_id]);

  const addInsurancePlan = () => {
    const initialPlain = {
      member_card_id: '',
      policy_number: '',
      expiry_date: '',
      is_primary: true,
      payer_id: '',
      relation_with_subscriber: 'self',
      coverage_type: 'EHCPOL',
      patient_share: 0.0,
      max_limit: 0,
      network: '',
      issue_date: '',
      sponsor_no: '',
      // policy_class: '',
      policy_holder: '',
      insurance_type: '',
      insurance_status: '',
      insurance_duration: '',
      class_id: '',
      class_name: '',
      class_type: 'plan'
    };
    formik.setFieldValue('insurance_plans', [...(formik.values?.insurance_plans || []), initialPlain]);
  };
  const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      padding: '0px 0px',
      height: '40px',
      fontSize: '12px',
      backgroundColor: 'white',
      '& fieldset': {}
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: '#000',
      border: 'none'
    },
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none'
    }
  }));
  console.log('eee', formik.values);

  return (
    <>
      <div class='personal-data !p-2 !mb-3'>
        <div class='personal-title !mb-2'>
          <div class='inventory-svg'>
            <svg width='26' height='26' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='34' height='34' rx='8' fill='#F4F6F8' />
              <path
                d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                fill='#F5793B'
              />
              <path d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z' fill='#F5793B' />
            </svg>
          </div>
          <div class='personal-head'>
            <span>Personal Data</span>
          </div>
        </div>
        <div class='personal-fields grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-2'>
          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Beneficiary Type*</label>
            <div className='flex w-full'>
              <button
                // type="button"
                onClick={() => {
                  formik.setFieldValue('beneficiary_type', 'Cash');
                  // {console.log("formik.values.beneficiary_type: ", formik.values.beneficiary_type)}
                }}
                className={`w-[50%] border p-1.5 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                  formik.values.beneficiary_type === 'Cash' ? '!bg-primary !border-primary text-white' : null
                }`}
              >
                Cash
              </button>
              <button
                // type="button"
                onClick={() => {
                  formik.setFieldValue('beneficiary_type', 'Insurance');
                  // {console.log("formik.values.beneficiary_type: ", formik.values.beneficiary_type)}
                }}
                className={`w-[50%] border  p-1.5 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                  formik.values.beneficiary_type === 'Insurance' ? '!bg-primary !border-primary text-white' : null
                }`}
              >
                Insurance
              </button>
              {/* {console.log(
                "formik.values.beneficiary_type: ",
                formik.values.beneficiary_type
              )} */}
            </div>
            {formik.errors.beneficiary_type && formik.touched.beneficiary_type ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.beneficiary_type}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Full Name*</label>
            <input
              placeholder='Enter Full name'
              type='text'
              name='name'
              value={formik.values.name}
              onChange={e => {
                const cleanedName = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Removes special characters and numbers
                formik.setFieldValue('name', cleanedName); // Update formik with the cleaned name
              }}
              onBlur={formik.handleBlur}
              id='name'
              autoComplete='name'
              required
              className='w-full'
            />
            {formik.errors.name && formik.touched.name ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.name}</p>
            ) : null}
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Date of Birth*</label>
            <input
              placeholder='Enter Date of Birth'
              type='date'
              name='dob'
              value={formik.values.dob}
              onChange={formik.handleChange}
              id='dobDate'
              autoComplete='off'
              className='dob'
            />

            {formik.errors.dob && formik.touched.dob ? (
              <p className=' max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.dob}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Gender*</label>
            <select
              id='select-gender'
              name='gender'
              onChange={formik.handleChange}
              value={formik.values.gender}
              autoComplete='type-name'
              required
            >
              <option value={''}>Select Gender</option>
              {gender.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.gender && formik.touched.gender ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.gender}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Marital Status*</label>
            <select
              id='select-martial_status'
              name='martial_status'
              onChange={formik.handleChange}
              value={formik.values.martial_status}
              autoComplete='type-name'
              required
            >
              <option value={''}>Select Martial Status</option>
              {maritalStatuses.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.martial_status && formik.touched.martial_status ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.martial_status}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Occupation*</label>
            <select
              id='select-occupation'
              name='occupation'
              onChange={formik.handleChange}
              value={formik.values.occupation}
              autoComplete='type-name'
              required
            >
              <option value={''}>Select Occupation</option>
              {occupationOptions.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.occupation && formik.touched.occupation ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.occupation}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Religion</label>
            <select
              id='select-religion'
              name='religion'
              onChange={formik.handleChange}
              value={formik.values.religion}
              autoComplete='type-name'
            >
              <option value={''}>Select Religion</option>
              {religionOptions.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.religion && formik.touched.religion ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.religion}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Document Type*</label>
            <select
              id='doc-type'
              name='document_type'
              onChange={formik.handleChange}
              value={formik.values.document_type}
              autoComplete='type-name'
              required
            >
              <option value=''>Select Document Type</option>
              {documentTypes.map(item => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.document_type && formik.touched.document_type ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.document_type}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Document ID*</label>
            <input
              placeholder='Enter Document ID'
              type='text'
              name='document_id'
              value={formik.values.document_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='document_id'
              required
            />
            {formik.errors.document_id && formik.touched.document_id ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.document_id}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col h-10'>
            <label className='text-sm'>Nationality*</label>
            <StyledAutocomplete
              id='nationality'
              options={nationalities}
              getOptionLabel={option => option.name}
              value={nationalities.find(option => option.id === formik.values.nationality) || null}
              onChange={(event, selectedOption) => {
                formik.setFieldValue('nationality', selectedOption ? selectedOption.id : '');
              }}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) => value && option.id === value}
              noOptionsText='No options found'
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Select Nationality'
                  error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                  helperText={formik.touched.nationality && formik.errors.nationality}
                  autoComplete='new-password'
                  disabled={false}
                />
              )}
            />
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Residency Type*</label>
            <select
              id='residence-type'
              name='residency_type'
              onChange={formik.handleChange}
              value={formik.values.residency_type}
              autoComplete='type-name'
            >
              <option value={''}>Select Residency Type</option>
              {residencyTypes.map(item => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.residency_type && formik.touched.residency_type ? (
              <p className=' max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.residency_type}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Contact Number*</label>
            <input
              placeholder='Enter contact number'
              type='text'
              name='contact_number'
              value={formik.values.contact_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='contact'
              autoComplete='email'
            />
            {formik.errors.contact_number && formik.touched.contact_number ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.contact_number}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Passport Number</label>
            <input
              placeholder='Enter Passport Number'
              type='number'
              name='passport_no'
              value={formik.values.passport_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='passport_no'
            />
            {formik.errors.passport_no && formik.touched.passport_no ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.passport_no}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Visa Title</label>
            <input
              placeholder='Enter Visa Title'
              type='text'
              name='visa_title'
              value={formik.values.visa_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='visa_title'
            />
            {formik.errors.visa_title && formik.touched.visa_title ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.visa_title}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Visa Number</label>
            <input
              placeholder='Enter Visa Number'
              type='number'
              name='visa_no'
              value={formik.values.visa_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='visa_no'
            />
            {formik.errors.visa_no && formik.touched.visa_no ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.visa_no}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Visa Type</label>
            <select
              id='visa_type'
              name='visa_type'
              onChange={formik.handleChange}
              value={formik.values.visa_type}
              autoComplete='visa_type'
            >
              <option value=''>Select Visa Type</option>
              {visaType.map(item => {
                return <option value={item.id}>{item.name_en}</option>;
              })}
            </select>
            {formik.errors.visa_type && formik.touched.visa_type ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.visa_type}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Border Number</label>
            <input
              placeholder='Enter Border Number'
              type='number'
              name='border_no'
              value={formik.values.border_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='border_no'
            />
            {formik.errors.border_no && formik.touched.border_no ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.border_no}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Insurance Duration</label>
            <input
              placeholder='Enter Insurance Duration'
              type='text'
              name='insurance_duration'
              value={formik.values.insurance_duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='border_no'
            />
            {formik.errors.insurance_duration && formik.touched.insurance_duration ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.insurance_duration}</p>
            ) : null}
          </div>

          <div className='flex gap-1 flex-col h-10'>
            <label className='text-sm'>Select City*</label>
            <StyledAutocomplete
              id='city'
              options={city} // Use the list of city options
              getOptionLabel={option => option.label}
              value={city.find(option => option.value === formik.values.city) || null}
              onChange={(event, selectedOption) => {
                formik.setFieldValue('city', selectedOption ? selectedOption.value : '');
              }}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) => option.value === value}
              noOptionsText='No options found'
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Select City'
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  autoComplete='new-password'
                  disabled={false}
                />
              )}
            />
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-sm'>Address*</label>
            <input
              placeholder='Enter Address'
              type='text'
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='address'
            />
            {formik.errors.address && formik.touched.address ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.address}</p>
            ) : null}
          </div>
          {/* <div>
            <div className="flex gap-1 flex-col">
              <label className="text-sm">Martial Status</label>
              <select
                id="martial_status"
                name="martial_status"
                onChange={formik.handleChange}
                value={formik.values.martial_status}
                autoComplete="martial_status"
              >
                <option value="">Select status</option>
                {martial_status.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>
            {formik.errors.martial_status && formik.touched.martial_status ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.martial_status}
              </p>
            ) : null}
          </div> */}
        </div>
      </div>
      {formik.values.is_new_born ? (
        <SubscriberDetails
          handleChange={formik.handleChange}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
          item={item}
        />
      ) : (
        <>
          <InsurancePlans
            chiReqRecord={chiReqRecord}
            item={item}
            personalDetailValues={formik.values}
            personalDetailsAreValid={formik.isValid}
            formik={formik}
            patient_Id={patient_Id}
            setFieldValue={formik.setFieldValue}
            isFormReset={isFormReset}
            setIsFormReset={setIsFormReset}
          />
          {formik.errors.insurance_plans &&
          typeof formik.errors.insurance_plans === 'string' &&
          formik.touched.insurance_plans ? (
            <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.insurance_plans}</p>
          ) : null}
        </>
      )}

      <div className='flex justify-between'>
        <div className='flex gap-1 mt-2'>
          <div class='toggle-button-ref'>
            <input
              type='checkbox'
              id='toggle-ref'
              name='is_new_born'
              value={formik.values.is_new_born}
              onChange={() => formik.setFieldValue('is_new_born', !formik.values.is_new_born)}
            />
            <label for='toggle-ref'></label>
          </div>
          <div>
            <span className='text-lg font-medium text-gray-600'>Is New Born?</span>
          </div>
        </div>

        {formik?.values?.beneficiary_type === 'Insurance' ? (
          <div className=''>
            <Button
              variant='contained'
              onClick={addInsurancePlan}
              style={{
                backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
              }}
              startIcon={<AddIcon />}
              className=''
              type='button'
              size='small'
            >
              New Insurance Plan
            </Button>
          </div>
        ) : null}
      </div>

      {/* {errors && Object.keys(errors).length > 0 && (
            <div>
              <h3>Validation Errors:</h3>
              <ul>
                {Object.values(errors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )} */}
      {/* <div class="save-benefeciary-btn" onClick={formik.handleSubmit}>
        <span>{item ? "Update" : "Save"} Beneficiary</span>
      </div> */}

      <div className=' flex gap-3 justify-center'>
        {formik.values?.beneficiary_type === 'Cash' ? (
          <Button
            variant='contained'
            onClick={formik.handleSubmit}
            style={{
              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
            }}
            startIcon={<CheckIcon />}
            size='small'
          >
            {item ? 'Update Beneficiary' : 'Save Beneficiary'}
            {/* {Statename === "b-form" ? "Beneficiary" : "Patient"} */}
          </Button>
        ) : null}

        {formik.values?.beneficiary_type === 'Insurance' ? (
          <Button
            variant='contained'
            onClick={() => {
              setSubmitEligibility(true);
              formik.handleSubmit();
            }}
            style={{
              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
            }}
            startIcon={<DoneAllIcon />}
            size='small'
          >
            {item ? 'Update Beneficiary' : 'Save Beneficiary'}
            {/* {Statename === "b-form" ? "Beneficiary" : "Patient"}  */}& Check Eligibility
          </Button>
        ) : null}
      </div>
      <AppLoader setShowLoader={setShowLoader} showLoader={showLoader} />
      <AppModal isOpen={loading || updatedLoading || showLoader || loadingData} />
    </>
  );
}

export default AddBeneficiaryPersonalDataForm;
