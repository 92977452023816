import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { FaArrowLeft, FaPrint } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ShowInvoice() {
  const location = useLocation();
  const invoiceCode = location?.state?.data;
  const invoiceRef = useRef(null);
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  // Populate the invoice content
  useEffect(() => {
    if (invoiceRef.current && invoiceCode) {
      invoiceRef.current.innerHTML = invoiceCode;
    }
  }, [invoiceCode]);

  const handlePrint = () => {
    let printContents = invoiceRef.current.innerHTML;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = printContents;
    printContents = tempDiv.innerHTML;

    // Get the iframe reference
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');

    // Write the content to the iframe
    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    // Trigger print on iframe load
    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  return (
    <div>
      <div className='flex gap-3'>
        <Button
          variant='contained'
          onClick={() => navigate(-1)}
          sx={{
            backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
            borderColor: '#f14f3e',
            color: 'white',
            '&:hover': {
              borderColor: '#f14f3e'
            }
          }}
          startIcon={<FaArrowLeft />}
          size='small'
        >
          Back
        </Button>
        <Button
          variant='contained'
          onClick={handlePrint}
          sx={{
            backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
            borderColor: '#f14f3e',
            color: 'white',
            '&:hover': {
              borderColor: '#f14f3e'
            },
            '&:focus': {
              borderColor: '#f14f3e'
            }
          }}
          startIcon={<FaPrint />}
          size='small'
        >
          Print Invoice
        </Button>
      </div>
      <div id='print-invoice' ref={invoiceRef}></div>
      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}
