import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext
} from 'react'
import {
  getAllPractitioners,
  getAppoinmentsByID,
  getUnassignedNursesList
} from './ApiMethods'
import PatientCard from './PatientCard'
import AppLoader from '../../../components/AppLoader'
import { Button, Drawer } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { appContext } from '../../../context/ContextProvider'
import { IoMdCloseCircle } from 'react-icons/io'
import Spinner from './Spinner'
import moment from 'moment'

export default function PatientAppoinmentHistory ({ patientID, app, doctor }) {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [Doctor, SetDoctors] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [appointments, setAppointments] = useState()
  const [nurseList, setNursesList] = useState([])
  const [status, setStatus] = useState('')
  const [doctorList, setDoctorList] = useState([])
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const { user } = useContext(appContext)
  const currentDate = moment().format('YYYY-MM-DD')
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
  const role = user?.RoleName
  const isReceptionist = user?.RoleId == 7
  const isDoctor = user?.RoleId == 9
  const isNurse = user?.RoleId == 10

  const [filters, setFilters] = useState({
    start_date: currentDate,
    end_date: currentDate,
    status: 'All',
    practitioner_id: '',
    nurse_id: '',
    visit_no: '',
    order_no: '',
    invoice_no: '',
    company_invoice_no: '',
    patient_id: patientID
  })

  const fetchAppointments = useCallback(
    async (reset = false, per_Page = 4, applyFilters = false) => {
      if (loading || (!hasMore && !reset)) return

      setLoading(true)
      try {
        const currentPage = reset ? 1 : page
        if (patientID) {
          let payload = {
            status: 'All',
            practitioner_id: user?.practitioner_id || '',
            patient_id: patientID,
            page: currentPage,
            perPage: per_Page
          }
          if (applyFilters) {
            payload.start_date = filters?.start_date
            payload.end_date = filters?.end_date
            payload.practitioner_id = isDoctor
              ? user?.practitioner_id
              : '' || ''
            payload.status = filters?.status
            payload.nurse_id = isNurse ? user?.practitioner_id : '' || ''
            payload.visit_no = Number(filters?.visit_no)
            payload.order_no = filters?.order_no
            payload.invoice_no = Number(filters?.invoice_no)
            payload.company_invoice_no = Number(filters?.company_invoice_no)
            payload.patient_id = patientID
          }
          const result = await getAppoinmentsByID(
            setLoading,
            payload,
            undefined
          )

          if (result?.data?.length) {
            setAppointments(prev =>
              reset ? result.data : [...prev, ...result.data]
            )
            setPage(prev => (reset ? 2 : prev + 1))
            setHasMore(result.data.length === 4)
          } else {
            if (applyFilters) {
              setAppointments([])
            }
            setHasMore(false)
          }
        } else {
          let payload = {
            status: 'All',
            practitioner_id: user?.practitioner_id || null,
            page: currentPage,
            perPage: per_Page
          }

          if (applyFilters) {
            payload.start_date = filters?.start_date
            payload.end_date = filters?.end_date
            payload.practitioner_id =
              filters?.practitioner_id || isDoctor ? user?.practitioner_id : ''
            payload.status = filters?.status
            payload.nurse_id =
              filters?.nurse_id || isNurse ? user?.practitioner_id : ''
            payload.visit_no = Number(filters?.visit_no)
            payload.order_no = filters?.order_no
            payload.invoice_no = Number(filters?.invoice_no)
            payload.company_invoice_no = Number(filters?.company_invoice_no)
            payload.patient_id = filters?.patient_id
          }
          const result = await getAppoinmentsByID(
            setLoading,
            payload,
            undefined
          )

          if (result?.data?.length) {
            setAppointments(prev =>
              reset ? result.data : [...prev, ...result.data]
            )
            setPage(prev => (reset ? 2 : prev + 1))
            setHasMore(result.data.length === 4)
          } else {
            if (applyFilters) {
              setAppointments([])
            }
            setHasMore(false)
          }
        }
        setOpen(false)
      } catch (error) {
        console.error('Error fetching appointments:', error)
      } finally {
        setLoading(false)
      }
    },
    [page, hasMore, patientID, filters]
  )

  const handleScroll = () => {
    if (!containerRef.current || loading || !hasMore) return
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      fetchAppointments()
    }
  }

  useEffect(() => {
    fetchAppointments(true)
  }, [patientID])

  useEffect(() => {
    if (open && isReceptionist) {
      getAllPractitioners(setLoading, setDoctorList)
      getUnassignedNursesList(setLoading, setNursesList)
    }
  }, [open])

  const handleAddFilters = () => {
    setPage(1)
    fetchAppointments(true, 50, true)
  }

  const hanldeResetFilters = () => {
    setPage(1)
    setFilters({
      start_date: currentDate,
      end_date: currentDate,
      status: 'All',
      practitioner_id: '',
      nurse_id: '',
      visit_no: '',
      order_no: '',
      invoice_no: '',
      company_invoice_no: '',
      patient_id: patientID
    })
    fetchAppointments(true)
  }

  return (
    <div className='flex flex-col h-full'>
      <div className={`mb-2 flex justify-end`}>
        <Drawer
          anchor='right'
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ className: 'p-4 w-[350px]' }}
        >
          <div className='flex items-center justify-between'>
            <h5 className='text-[20px] font-medium'>Apply Filters</h5>
            <IoMdCloseCircle
              size={25}
              className='text-gray-500 cursor-pointer'
              onClick={() => setOpen(false)}
            />
          </div>
          <div className='grid grid-cols-1 gap-2'>
            <div className='flex flex-col mt-4 gap-2'>
              <div>
                <label className='text-base font-medium'>Start Date</label>
                <input
                  id='start'
                  type='date'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.start_date}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      start_date: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>End Date</label>
                <input
                  id='end'
                  type='date'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.end_date}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      end_date: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>Visit No</label>
                <input
                  id='visit_no'
                  type='Number'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.visit_no}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      visit_no: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>Order No</label>
                <input
                  id='order_no'
                  type='text'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.order_no}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      order_no: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>Invoice No</label>
                <input
                  id='invoice_no'
                  type='number'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.invoice_no}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      invoice_no: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>
                  Company Invoice No
                </label>
                <input
                  id='company_invoice_no'
                  type='number'
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.company_invoice_no}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      company_invoice_no: e.target.value
                    }))
                  }}
                />
              </div>
              <div>
                <label className='text-base font-medium'>Status</label>
                <select
                  className='w-full border rounded-md !p-1.5'
                  value={filters?.status}
                  onChange={e => {
                    setFilters(prevValues => ({
                      ...prevValues,
                      status: e.target.value
                    }))
                  }}
                >
                  <option value={'All'}>All</option>
                  <option value={'Fulfilled'}>Fulfilled</option>
                  <option value={'Cancelled'}>Cancelled</option>
                  <option value={'Pending'}>Pending</option>
                  <option value={'Waitlisted'}>Waitlisted</option>
                </select>
              </div>
              {isReceptionist && (
                <>
                  <div>
                    <label className='text-base font-medium'>
                      Select Practitioner
                    </label>
                    <select
                      className='w-full border rounded-md !p-1.5'
                      value={filters?.practitioner_id}
                      onChange={e => {
                        setFilters(prevValues => ({
                          ...prevValues,
                          practitioner_id: e.target.value
                        }))
                      }}
                    >
                      <option value={''}>Select Practitioner</option>
                      {doctorList?.data
                        ?.filter(data => data?.role == 'Doctor')
                        ?.map(doctor => (
                          <option value={doctor?.practitioner_id}>
                            {doctor?.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div>
                    <label className='text-base font-medium'>
                      Select Nurse
                    </label>
                    <select
                      className='w-full border rounded-md !p-1.5'
                      value={filters?.nurse_id}
                      onChange={e => {
                        setFilters(prevValues => ({
                          ...prevValues,
                          nurse_id: e.target.value
                        }))
                      }}
                    >
                      <option value={''}>Select Nurse</option>
                      {nurseList
                        ?.filter(data => data?.role == 'nurse')
                        ?.map(nurse => (
                          <option value={nurse?.nurse_id}>{nurse?.name}</option>
                        ))}
                    </select>
                  </div>
                </>
              )}
              <div className='mt-[20px] flex items-center gap-2'>
                <Button
                  onClick={handleAddFilters}
                  variant='contained'
                  type='button'
                  size='small'
                  className='flex w-full justify-center rounded-md bg-gradient-to-r from-[#f14f3e] to-[#fab768] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
                >
                  Submit
                </Button>
                <Button
                  onClick={hanldeResetFilters}
                  variant='contained'
                  type='button'
                  size='small'
                  className='flex w-full justify-center rounded-md bg-gradient-to-r !bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </Drawer>

        <div
          className={`flex justify-end items-center gap-2 ${
            app ? 'w-1/2' : 'w-auto'
          }`}
        >
          {app && (
            <Button
              variant='contained'
              type='button'
              size='small'
              onClick={() => navigate('/receptionist/create-visit')}
              className='flex w-[200px] justify-center rounded-md bg-gradient-to-r from-[#f14f3e] to-[#fab768] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
            >
              CREATE Visit
            </Button>
          )}
          <Button
            variant='outlined'
            type='button'
            size='small'
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpen(true)}
            className='flex w-[200px] justify-center rounded-md px-3 !border-primary !text-primary  hover:!bg-primary/10 py-1.5 text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
          >
            APPLY FILTER
          </Button>
        </div>
      </div>

      <div
        className='flex-grow max-h-[630px] overflow-y-auto mt-2'
        ref={containerRef}
        onScroll={handleScroll}
      >
        <div className='grid grid-cols-1 gap-3'>
          {appointments?.length > 0 ? (
            appointments?.map((appointment, index) => (
              <div key={appointment.id}>
                <PatientCard
                  appointment={appointment}
                  patientID={patientID}
                  Doctors={Doctor}
                  doctor={doctor}
                  fetchAppointments={fetchAppointments}
                  currentPage={page}
                  filterStatus={filters?.status}
                />
              </div>
            ))
          ) : (
            <div className='flex w-full h-[40vh] items-center justify-center'>
              <h1 className='text-2xl font-bold text-center'>No Visit Found</h1>
            </div>
          )}
        </div>
      </div>
      {page === 1 ? (
        <AppLoader showLoader={loading} />
      ) : (
        loading && (
          <div className='flex items-center justify-center mt-3'>
            <Spinner />
          </div>
        )
      )}
    </div>
  )
}
