import React, { useEffect, useState } from 'react'

import FormList from '../../../components/formCreation/FormList'
import CategoryList from '../../../components/formCreation/CategoryList'
import HomeCareFormResult from '../../../components/homeCareEntries/HomeCareFormResult'
import { GetEmrByNumber } from './ApiMethods'

const CreateNewHomeCareEntries = ({ visitId, appointment }) => {
  const [selectedReportId, setSelectedReportId] = useState(null)
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [selectedFormId, setSelectedFormId] = useState(null)
  const user = localStorage?.getItem('user')
  const loggedUser = JSON.parse(user)
  const isReceptionist = loggedUser?.RoleId == 7
  const isDoctor = loggedUser?.RoleId == 9
  const isNurse = loggedUser?.RoleId == 10
  const [loading, setLoading] = useState(false)
  const [selectedEmrData, setSelectedEmrData] = useState(null)

  useEffect(() => {
    setSelectedCategoryId(null)
    setSelectedFormId(null)
  }, [selectedReportId])

  // useEffect(() => {
  //   if (isNurse && appointment?.emr_numbers?.length) {
  //     GetEmrByNumber(
  //       setLoading,
  //       { emr_id: Number(appointment?.emr_numbers[0]) },
  //       setSelectedEmrData
  //     )
  //   }
  // }, [appointment?.emr_numbers])

  return (
    <div className='h-full overflow-auto'>
      <div className='grid grid-cols-12 gap-3'>
        <div className='col-span-12 xl:col-span-3'>
          <FormList
            selectedFormType={'visit'}
            setSelectedReportId={setSelectedReportId}
            isAllowActions={false}
          />
        </div>
        {selectedReportId && (
          <div className='col-span-12 xl:col-span-3'>
            <CategoryList
              selectedFormType={'visit'}
              selectedReportId={selectedReportId}
              setSelectedCategoryId={setSelectedCategoryId}
              setSelectedFormId={setSelectedFormId}
              isAllowActions={false}
            />
          </div>
        )}
        {selectedCategoryId && (
          <div className='col-span-12 xl:col-span-6'>
            <HomeCareFormResult
              id={selectedCategoryId}
              visitId={visitId}
              // completeCheck={selectedEmrData?.complete_check}
              // isNurse={isNurse}
              isReceptionist={isReceptionist}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateNewHomeCareEntries
