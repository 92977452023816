import { FileUpload, HistoryOutlined } from '@mui/icons-material'

import { hisLogin } from '../pages/HIS/components/ApiMethods'
import { useEffect } from 'react'
import PublishedWithChangesOutlinedIcon from '../assets/imageHis/heart.svg'
import LocalHospitalOutlinedIcon from '../assets/icons/2.png' // Example path
import PersonIcon from '../assets/imageHis/8.png'
import blood from '../assets/icons/labs/1.png'
import lab from '../assets/icons/labs/3.png'
import labDoctor from '../assets/icons/labs/2.png'
import labDiagnosis from '../assets/icons/labs/5.png'
import meetings from '../assets/icons/labs/4.png'
import medicines from '../assets/icons/labs/6.png'
import payroll from '../assets/icons/labs/7.png' // Example path
import pathology from '../assets/icons/labs/8.png'
import radiology from '../assets/icons/labs/9.png' // Example path
import noticeboard from '../assets/icons/Pharmacy/2.png'

// Example path

import FormatListBulletedOutlinedIcon from '../assets/icons/7.png' // Example path
import OrdersIcon from '../assets/icons/10.png'
import FormIcon from '../assets/icons/form-icon.png'
import AddBoxIcon from '../assets/icons/8.png' // Example path
import AppointmentIcon from '../assets/icons/appointment.png' // Example path
import EditNoteIcon from '../assets/icons/Pharmacy/6.png'
import doctors from '../assets/icons/doctors/3.png' // Example path
import ReceiptIcon from '../assets/icons/Pharmacy/7.png' // Example path
import hisIcon from '../assets/icons/doctors/9.png' // Example path
import createPatientIcon from '../assets/icons/9.png' // Example path
import createAppointmentIcon from '../assets/icons/doctors/2.png' // Example path
import invoiceIcon from '../assets/icons/Pharmacy/2.png' // Example path
import emergencyIcon from '../assets/icons/emergency.png' // Example path
import { GiNurseFemale } from 'react-icons/gi'
// Import additional icons as needed

const navigationConfig = {
  6: {
    header: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        permission: 'view-dashboard',
        icon: LocalHospitalOutlinedIcon
      },
      {
        name: 'Beneficiary',
        path: 'beneficiary',
        permission: 'view-beneficiary',
        icon: PersonIcon
      },
      {
        name: 'Eligibility',
        path: 'eligibility',
        permission: 'view-eligibility'
      },
      {
        name: 'Pre-Authorization',
        path: 'preauth',
        permission: 'view-preauth'
      },
      {
        name: 'Claims',
        path: 'claims',
        permission: 'view-claims',
        menu: [
          {
            name: 'Transaction',
            path: 'claims',
            icon: FormatListBulletedOutlinedIcon
          },
          {
            name: 'Submissions',
            path: 'claims/submit',
            icon: PublishedWithChangesOutlinedIcon
          },
          {
            name: 'Excel Uploads',
            path: 'claims/uploads',
            icon: FileUpload
          },
          {
            name: 'History',
            path: 'claims/history',
            icon: HistoryOutlined
          }
        ]
      },
      {
        name: 'Payments / Reconciliation',
        path: 'payments',
        permission: 'view-payments'
      },
      {
        name: 'HIS',
        path: 'his',

        permission: 'view-his'
        // menu: [
        //   {
        //     name: "Patient",
        //     path: "his",
        //     icon: PersonIcon,
        //   },
        // ],
      },
      {
        name: 'Receptionist',
        path: 'receptionist',
        permission: 'view-receptionist',
        menu: [
          {
            name: 'Create Patient',
            path: 'beneficiary/form',
            icon: AddBoxIcon
          },
          {
            name: 'Create Appointments',
            path: 'receptionist/create-appointment',
            icon: EditNoteIcon
          },
          {
            name: 'Invoice',
            path: 'receptionist/invoice',
            icon: ReceiptIcon
          }
        ]
      },
      {
        name: 'Emergency',
        path: 'emergency',
        permission: 'view-receptionist',
        menu: [
          {
            name: 'Emergency List',
            path: 'emergency/list',
            icon: AddBoxIcon
          },
          {
            name: 'Triaged Patients',
            path: 'emergency/triagepatients',
            icon: EditNoteIcon
          },
          // {
          //   name: "Finalized patients",
          //   path: "emergency/invoice",
          //   icon: ReceiptIcon,
          // },
          {
            name: 'Bed Information',
            path: 'emergency/bedinfo',
            icon: ReceiptIcon
          }
        ]
      }
    ],
    sidebar: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        permission: 'view-dashboard',
        icon: LocalHospitalOutlinedIcon
      },
      {
        name: 'Beneficiary',
        path: 'beneficiary',
        permission: 'view-beneficiary',
        icon: PersonIcon,
        menu: [
          {
            name: 'Create Beneficiary',
            path: 'beneficiary/form'
          }
        ]
      },
      {
        name: 'Eligibility',
        path: 'eligibility',
        permission: 'view-eligibility',
        icon: FormatListBulletedOutlinedIcon
      },
      {
        name: 'Pre-Authorization',
        path: 'preauth',
        permission: 'view-preauth',
        icon: ReceiptIcon
      },
      {
        name: 'Claims',
        path: 'claims',
        permission: 'view-claims',
        icon: PublishedWithChangesOutlinedIcon,
        menu: [
          {
            name: 'Transaction',
            path: 'claims',
            icon: FormatListBulletedOutlinedIcon
          },
          {
            name: 'Submissions',
            path: 'claims/submit',
            icon: PublishedWithChangesOutlinedIcon
          },
          {
            name: 'Excel Uploads',
            path: 'claims/uploads',
            icon: FileUpload
          },
          {
            name: 'History',
            path: 'claims/history',
            icon: HistoryOutlined
          }
        ]
      },
      {
        name: 'Payments / Reconciliation',
        path: 'payments',
        permission: 'view-payments',
        icon: payroll
      },
      {
        name: 'HIS',
        path: 'his',
        icon: hisIcon,
        permission: 'view-his'
        // menu: [
        //   {
        //     name: "Patient",
        //     path: "his",
        //     icon: PersonIcon,
        //   },
        // ],
      },
      {
        name: 'Receptionist',
        path: 'receptionist',
        permission: 'view-receptionist',
        icon: invoiceIcon,
        menu: [
          {
            name: 'Create Patient',
            path: 'beneficiary/form',
            icon: AddBoxIcon
          },
          {
            name: 'Create Appointments',
            path: 'receptionist/create-appointment',
            icon: EditNoteIcon
          },
          {
            name: 'Invoice',
            path: 'receptionist/invoice',
            icon: ReceiptIcon
          }
        ]
      },
      {
        name: 'Emergency',
        path: 'emergency',
        icon: emergencyIcon,
        menu: [
          {
            name: 'Patients List',
            path: 'emergency/list',
            icon: AddBoxIcon
          },
          {
            name: 'Triaged Patients',
            path: 'emergency/triagepatients',
            icon: EditNoteIcon
          },
          {
            name: 'Finalized patients',
            path: 'emergency/finalizedpatients',
            icon: ReceiptIcon
          },
          {
            name: 'Bed Information',
            path: 'emergency/bedinfo',
            icon: ReceiptIcon
          }
        ]
      },
      {
        name: 'Form Creation',
        path: 'receptionist/form-creation',
        icon: FormIcon
      }
    ]
  },
  9: {
    header: [
      {
        name: 'D-Dashboard',
        path: 'his/ddashboard',
        permission: 'view-dashboard'
      },
      {
        name: 'EMR',
        path: 'his',
        // command: hisLogin,
        permission: 'view-his'
      }

      // {
      //   name: "Appointment",
      //   path: "his/appointment",
      // },
    ],
    sidebar: [
      {
        name: 'D-Dashboard',
        path: 'his/ddashboard',
        permission: 'view-dashboard',
        icon: hisIcon
      },
      {
        name: 'EMR',
        path: 'his',
        permission: 'view-his',
        icon: doctors
        // createButton: [
        //   {
        //     name: "Create Patient",
        //     path: "his/create-patient",
        //   },
        // ],
        // menu: [
        //   {
        //     name: "Invoice",
        //     path: "his/invoice",
        //     icon: invoiceIcon,
        //   },
        // ],
      }

      // {
      //   name: "Visits",
      //   path: "his/appointments",
      //   icon: createAppointmentIcon,
      // },
    ]
  },
  7: {
    header: [
      {
        name: 'R-Dashboard',
        path: 'receptionist/rdashboard',
        permission: 'view-dashboard'
      },
      {
        name: 'Beneficiary',
        path: 'receptionist/beneficiary',
        permission: 'view-beneficiary'
      },
      {
        name: 'Eligibility',
        path: 'receptionist/eligibility',
        permission: 'view-eligibility'
      },
      {
        name: 'Visits',
        path: 'receptionist/appointments'
      },
      {
        name: 'Billing',
        path: 'receptionist/invoice'
      }
    ],
    sidebar: [
      {
        name: 'R-Dashboard',
        path: 'receptionist/rdashboard',
        permission: 'view-dashboard',
        icon: PublishedWithChangesOutlinedIcon
      },
      {
        name: 'Visits',
        path: 'receptionist/appointments', // Correct path to appointments
        icon: FormatListBulletedOutlinedIcon,
        createButton: [
          {
            name: 'Create Visits',
            path: '/receptionist/create-visit'
          }
        ]
        // menu: [{ name: "Visit Report" }],
      },
      {
        name: 'Orders',
        path: 'receptionist/orders-list',
        icon: OrdersIcon,
        createButton: [
          {
            name: 'Create Order',
            path: '/receptionist/create-order'
          }
        ]
        // menu: [{ name: "Visit Report" }],
      },
      {
        name: 'Beneficiary',
        path: 'receptionist/beneficiary',
        permission: 'view-beneficiary',
        icon: PersonIcon,
        createButton: [
          {
            name: 'Create Beneficaries',
            path: '/beneficiary/form'
          }
        ]
        // menu: [{ name: "Beneficiary Report" }],
      },
      {
        name: 'Eligibility',
        path: 'receptionist/eligibility',
        permission: 'view-eligibility',
        icon: LocalHospitalOutlinedIcon
        // createButton: [
        //   {
        //     name: "Create Eligibility",
        //     path: "receptionist/eligibility/details",
        //     permission: "view-eligibility",
        //   }, // Correct create button path
        // ],
        // menu: [{ name: "eligi Report" }],
      },

      {
        name: 'Billing',
        path: 'receptionist/invoice', // Correct path to billing route
        icon: AddBoxIcon
        // createButton: [
        //   {
        //     name: "Create Invoice",
        //     path: "receptionist/invoice", // Correct path for invoice creation
        //   },
        // ],
        // menu: [{ name: "invoice" }],
      },
      {
        name: 'Appointment',
        path: 'receptionist/appointment', // Correct path to billing route
        icon: AppointmentIcon
      }
      // {
      //   name: 'Nursing',
      //   path: 'nursing', // Correct path to billing route
      //   icon: AddBoxIcon,
      //   menu: [
      //     {
      //       name: 'Out Patient',
      //       path: 'nursing/out-patient'
      //     },
      //     {
      //       name: 'In Patient',
      //       path: 'nursing/in-patient'
      //     },
      //     {
      //       name: 'Nephrology',
      //       path: 'nursing/nephrology'
      //     },
      //     {
      //       name: 'Requisition List',
      //       path: 'nursing/requisition-list'
      //     },
      //     {
      //       name: 'Discharge Summary',
      //       path: 'nursing/discharge-summary'
      //     }
      //   ]
      // }
    ]
  },

  10: {
    header: [
      {
        name: 'D-Dashboard',
        path: 'his/ddashboard',
        permission: 'view-dashboard'
      },
      {
        name: 'Nursing',
        path: 'his',
        permission: 'view-his'
      }
    ],
    sidebar: [
      {
        name: 'D-Dashboard',
        path: 'his/ddashboard',
        permission: 'view-dashboard',
        icon: hisIcon
      },
      {
        name: 'Visits',
        path: 'nursing',
        icon: AddBoxIcon,
        permission: 'view-his'
        // menu: [
        //   {
        //     name: 'Triage',
        //     path: 'nursing/out-patient'
        //   },
        //   {
        //     name: 'In Patient',
        //     path: 'nursing/in-patient'
        //   },
        //   {
        //     name: 'Nephrology',
        //     path: 'nursing/nephrology'
        //   },
        //   {
        //     name: 'Requisition List',
        //     path: 'nursing/requisition-list'
        //   },
        //   {
        //     name: 'Discharge Summary',
        //     path: 'nursing/discharge-summary'
        //   }
        // ]
      }
    ]
  }
}

// Function to get navigation based on roleId stored in localStorage
export const getNavigation = () => {
  const user = localStorage.getItem('user')
  const roleId = user ? JSON.parse(user).RoleId : null

  // Return navigation config based on roleId or empty if none
  return roleId && navigationConfig[roleId]
    ? {
        header: navigationConfig[roleId].header,
        sidebar: navigationConfig[roleId].sidebar
      }
    : { header: [], sidebar: [] }
}

// Export a function that returns the current navigation
export const navigation = () => getNavigation()

// Function to get sidebar navigation
export const getSidebarNavigation = () => {
  const { sidebar } = getNavigation() // Destructure sidebar from getNavigation

  if (!sidebar || !sidebar.length) {
    console.log('Sidebar Navigation is empty')
    return []
  }

  // If there are createButton arrays within the sidebar items, flatten them
  const createButtons = sidebar
    .flatMap(item => item.createButton || []) // Flatten and filter out undefined
    .map(createItem => ({ name: createItem.name, path: createItem.path })) // Map name and path
  return { sidebar, createButtons }
}
