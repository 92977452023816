import axios from 'axios';
import toast from 'react-hot-toast';
import { ERP_BACKEND_URL, HIS_BACKEND_URL, HIS_PASSWORD, HIS_USERNAME } from '../../../Config';
import { set } from 'lodash';

const HISURl = axios.create({
  baseURL: HIS_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});
const ERPURl = axios.create({
  baseURL: ERP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

const validHIS = axios.create({
  baseURL: HIS_BACKEND_URL
});

const validERP = axios.create({
  baseURL: ERP_BACKEND_URL
});

// validHIS.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("HIStoken");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
validHIS.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    const logedUser = JSON.parse(localStorage.getItem('user'));

    let apiKey = '';
    let userId = '';

    if (logedUser) {
      if (logedUser.business_id === 2 && logedUser.business_name_en === 'Manarat Home care') {
        apiKey = '148f9170ddb9f7966c3f6adeda8880f21a70d8687190b2a7e5840f9efcc6087e';
        userId = '38';
      } else if (logedUser.business_id === 1 && logedUser.business_name_en === 'Himam Center') {
        apiKey = '64efd6a27d672833c3523aed8f3ad925a1adc5265e27419cc47ba3eed7dc5c3e';
        userId = '1';
      } else if (logedUser.business_id === 3 && logedUser.business_name_en === 'testing business') {
        apiKey = 'dd20744046cef79cb856cc2ea4312f1f008ced2b1117dd9b7b4e2d15831fdf59';
        userId = '40';
      } else if (logedUser.business_id === 3 && logedUser.business_name_en === 'Testing user') {
        apiKey = 'dd20744046cef79cb856cc2ea4312f1f008ced2b1117dd9b7b4e2d15831fdf59';
        userId = '40';
      } else if (logedUser.business_id === 5 && logedUser.business_name_en === 'Jobs Clinic') {
        apiKey = 'f520b042b8b12f26e4c50a7139eeea72ba78f7d0398913d4a88609937396d692';
        userId = '98';
      } else if (logedUser.business_id === 6 && logedUser.business_name_en === 'Mushrifah') {
        apiKey = '13196cceea100d197545891123784663313a5d66f4e18688d4dbd4521a1a126f';
        userId = '100';
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (apiKey) {
      config.headers['x-api-key'] = apiKey;
    }
    if (userId) {
      config.headers['x-user-id'] = userId;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
validERP.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    const logedUser = JSON.parse(localStorage.getItem('user'));

    let apiKey = '';
    let userId = '';

    if (logedUser) {
      if (logedUser.business_id === 2 && logedUser.business_name_en === 'Manarat Home care') {
        apiKey = '148f9170ddb9f7966c3f6adeda8880f21a70d8687190b2a7e5840f9efcc6087e';
        userId = '38';
      } else if (logedUser.business_id === 1 && logedUser.business_name_en === 'Himam Center') {
        apiKey = '64efd6a27d672833c3523aed8f3ad925a1adc5265e27419cc47ba3eed7dc5c3e';
        userId = '1';
      } else if (logedUser.business_id === 3 && logedUser.business_name_en === 'testing business') {
        apiKey = 'dd20744046cef79cb856cc2ea4312f1f008ced2b1117dd9b7b4e2d15831fdf59';
        userId = '40';
      } else if (logedUser.business_id === 5 && logedUser.business_name_en === 'Jobs Clinic') {
        apiKey = 'f520b042b8b12f26e4c50a7139eeea72ba78f7d0398913d4a88609937396d692';
        userId = '98';
      } else if (logedUser.business_id === 6 && logedUser.business_name_en === 'Mushrifah') {
        apiKey = '13196cceea100d197545891123784663313a5d66f4e18688d4dbd4521a1a126f';
        userId = '100';
      }
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (apiKey) {
      config.headers['x-api-key'] = apiKey;
    }
    if (userId) {
      config.headers['x-user-id'] = userId;
    }

    return config;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);
// export const hisLogin = async () => {
//   try {
//     const response = await HISURl.post("login", {
//       employee_id: HIS_USERNAME,
//       password: HIS_PASSWORD,
//     });
//     const token = response?.data?.token;
//     localStorage.setItem("HIStoken", token);
//   } catch (error) {
//     console.error(error);
//     toast.error(error?.response?.data?.error);
//   }
// };
export const getPatientList = async (setLoading, setPatientList, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('practitioner_patientlist', data);
    setPatientList(response?.data);
    return response?.data;
  } catch (error) {
    setLoading(false);
    toast.error(error?.response?.data?.message);
    localStorage.removeItem('visit_selected_patient_id');
    console.error('Error fetching patient list:', error);
    // Optionally handle the error, e.g., show a toast
  }
};

export const getPatientVisitType = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('checkPatientFollowup', payload);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    toast.error(error?.response?.data?.message);
    console.error('Error fetching  data:', error);
  }
};
export const getPayerList = async setPayerList => {
  try {
    const response = await validHIS.post('payer_list');
    if (setPayerList) {
      setPayerList(response?.data);
    }
    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching patient list:', error);
  }
};
export const SearchInvoice = async data => {
  const { setLoading, query } = data;
  try {
    setLoading(true);
    const response = await validERP.post('searchInvoices', {
      query: query,
      page: 1,
      per_page: 10
    });
    // toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const SearchCompanyInvoice = async data => {
  const { setLoading, query } = data;
  try {
    setLoading(true);
    const response = await validERP.post('searchCompanyInvoices', {
      query: query,
      page: 1,
      per_page: 10
    });
    // toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getCustomerList = async (setLoading, setCustomerList) => {
  try {
    setLoading(true);
    const response = await validERP.post('getHisCustomers');
    // toast.success(response.data.message);
    setLoading(false);
    if (setCustomerList) {
      setCustomerList(response?.data);
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getInsurancePolicyList = async (setLoading, setInsurancePolicyList) => {
  try {
    setLoading(true);
    const response = await validHIS.post('list_insurance_policy');
    // toast.success(response.data.message);
    setLoading(false);
    setInsurancePolicyList(response?.data);
    console.log(response.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getCashBankAccounts = async (setLoading, setCashBankAccounts) => {
  try {
    setLoading(true);
    const response = await validERP.post('getCashBankAccounts');
    setLoading(false);
    setCashBankAccounts(response?.data);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};
export const getPractitionerDetail = async (setLoading, setPractitionerDetail, token) => {
  try {
    setLoading(true);
    const response = await axios.get('https://api.supergitsa.com/api/physician_by_userid', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setLoading(false);
    setPractitionerDetail(response?.data);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error('Error fetching practitioner details:', error);
    throw error;
  }
};
export const createCustomer = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validERP.post('customer', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const UploadExcelFile = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validERP.post('uploadItems', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const createInsurancePolicy = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validHIS.post('insurance_policy', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const deleteInsurancePolicy = async (setShowLoader, data) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('delete_insurance_policy', {
      policy_id: data
    });
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const updateInsurancePolicy = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validHIS.put('update_insurance_policy', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const updateAppoinmentStatus = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validHIS.post('updatePaymentStatus', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const getitemsList = async (setLoading, setItemsList) => {
  try {
    setLoading(true);
    const response = await validERP.post('items');
    // toast.success(response.data.message);
    setLoading(false);
    setItemsList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getCompanyList = async (setLoading, setCompanyList) => {
  try {
    setLoading(true);
    const response = await validERP.post('getBusinesses');
    // toast.success(response.data.message);
    setLoading(false);
    setCompanyList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getPatientListDropdown = async (setLoading, setPatientList, data = { page: 0, perPage: 0 }) => {
  try {
    setLoading(true);
    const response = await validHIS.post('patientslist', data);
    // toast.success(response.data.message);
    setLoading(false);
    setPatientList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};

export const getPractitionersList = async (setLoading, setpractitionerList, data = { page: 0, perPage: 0 }) => {
  try {
    setLoading(true);
    const response = await validHIS.post('practitionerslist', data);
    // toast.success(response.data.message);
    setLoading(false);
    setpractitionerList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const getAllPractitioners = async (setLoading, setpractitionerList) => {
  try {
    setLoading(true);
    const response = await validHIS.post('getPractitioners');
    // toast.success(response.data.message);
    setLoading(false);
    setpractitionerList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const createPatientHIS = async data => {
  const { setShowLoader, data: payload } = data;
  try {
    setShowLoader(true);
    const response = await validHIS.post('patient', payload);
    toast.success(response?.data?.message);
    setShowLoader(false);

    return response?.data;
  } catch (error) {
    setShowLoader(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};

export const createPractitionerHIS = async data => {
  try {
    const response = await validHIS.post('practitioner', data);
    toast.success(response.data.message);
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};
export const createNewInvoice = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validERP.post('createInvoice', data);
    toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};
export const createCashInvoice = async data => {
  try {
    const response = await validERP.post('patientInvoice', data);
    toast.success(response?.data?.message);
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};
export const createInsuranceInvoice = async data => {
  try {
    const response = await validERP.post('insuranceInvoice', data);
    toast.success(response?.data?.message);
  } catch (error) {
    console.log(error);
  }
};
export const getAppoinmentsList = async (setLoading, setAppointmentList, status) => {
  try {
    setLoading(true);
    const response = await validHIS.post('visitslist', status);
    setLoading(false);
    if (setAppointmentList) {
      setAppointmentList(response?.data);
    }
    return response.data;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const getslots = async (setLoading, setSlotsList, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('getslots', payload);
    setLoading(false);

    setSlotsList(response?.data);

    return response.data;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};
export const getCashInvoiceList = async (setLoading, setCashList, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('listInvoices', payload);
    setLoading(false);
    setCashList(response?.data);
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const ShowInvocieApi = async (setLoading, id, payload = {}) => {
  try {
    setLoading(true);
    const response = await validERP.post(`printInvoice/${id}`, payload);
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const ShowInsuranceInvocieApi = async (setLoading, id) => {
  try {
    setLoading(true);
    const response = await validERP.post(`printCompanyInvoice/${id}`);
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const ShowCreditInvocieApi = async (setLoading, id) => {
  try {
    setLoading(true);
    const response = await validERP.post(`printCreditInvoice/${id}`);
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const ShowCreditCompanyInvocieApi = async (setLoading, id) => {
  try {
    setLoading(true);
    const response = await validERP.post(`printCreditCompanyInvoice/${id}`);
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const getInsuranceInvoiceList = async (setLoading, setInsuranceList, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('listInsuranceInvoice', payload);
    setLoading(false);
    setInsuranceList(response?.data);
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const getCreditInvoiceList = async (setLoading, setCreditInvoices, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('listCreditInvoice', payload);
    setLoading(false);
    setCreditInvoices(response?.data);
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};

export const getCompanyCreditInvoiceList = async (setLoading, setInvoiceData, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('listCreditCompanyInvoice', payload);
    setLoading(false);
    setInvoiceData(response?.data);
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
export const getInvoiceData = async (setLoading, setInvoiceData, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('invoiceData', payload);
    setLoading(false);
    setInvoiceData(response?.data);
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
};
// export const getPatientByID = async (
//   setLoading,
//   patientId,
//   setFormData,
//   loadingEnabled = true
// ) => {
//   try {
//     if (loadingEnabled) {
//       setLoading(true);
//     }
//     const response = await validHIS.post("patientbyid", {
//       patient_id: patientId,
//     });

//     if (loadingEnabled) {
//       setLoading(false);
//     }

//     setFormData(response?.data);
//     console.log(response.data); // Call setFormData instead of set
//     return response?.data;
//   } catch (error) {
//     if (loadingEnabled) {
//       setLoading(false);
//     }
//     console.error(error);
//     toast.error(error?.response?.data?.error);
//   }
// };

export const getPatientByID = async (setLoading, patientId, loadingEnabled = true, setFormData = null) => {
  try {
    if (loadingEnabled && typeof setLoading === 'function') {
      setLoading(true);
    }
    const response = await validHIS.post('patientbyid', {
      patient_id: patientId
    });

    if (loadingEnabled && typeof setLoading === 'function') {
      setLoading(false);
    }

    if (setFormData) {
      setFormData({
        fullName: response.data.full_name || '',
        dob: response.data.dob || '',
        gender: response.data.gender || '',
        contact: response.data.contact || '',
        documentType: response.data.document_type || '',
        documentId: response.data.document_id || '',
        visaNo: response.data.visa_no || '',
        fileNo: response.data.file_no || '',
        beneficiaryType: response.data.beneficiary_type || '',
        address: response.data.address || '',
        nationality: response.data.nationality || '',
        profession: response.data.profession || ''
      });
    }

    return response?.data;
  } catch (error) {
    if (loadingEnabled && typeof setLoading === 'function') {
      setLoading(false);
    }
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const CreateNewEmr = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('emr', data);
    setLoading(false);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const GetEmrByNumber = async (setLoading, payload, setSelectedEmrData) => {
  try {
    setLoading(true);
    const response = await validHIS.post('emrByEmrId', payload);
    toast.success(response?.data?.message);
    setSelectedEmrData(response?.data?.data);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const UpdateEMR = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('updateEmr', data);
    setLoading(false);
    toast.success(response?.data?.message);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const CreateAppointmentApi = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('visits', data);
    console.log(response?.data);
    toast.success(response?.data?.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const updateNumberofSession = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('updateOrderSession', data);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const CreateOrdersApi = async (setLoading, data, navigate) => {
  try {
    setLoading(true);
    const response = await validHIS.post('createOrder', data);
    toast.success(response?.data?.message);
    setLoading(false);
    if (navigate) {
      navigate('/receptionist/orders-list');
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const viewVitalReports = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post('vital-report', data);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};

export const gerOrdersList = async (setLoading, setOrdersList, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('listOrders', payload);
    setLoading(false);
    setOrdersList(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};
export const getMedicalByID = async (setLoading, data, setPatientMedicalHistory) => {
  try {
    setLoading(true);
    const response = await validHIS.post('patient_emr', data);
    setLoading(false);
    if (setPatientMedicalHistory) {
      setPatientMedicalHistory(response?.data?.data);
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const getAppoinmentsByID = async (setLoading, data, setPatientAppoinmenmt) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`patientvisitbyid`, data);
    setLoading(false);
    if (setPatientAppoinmenmt) {
      setPatientAppoinmenmt(response?.data);
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
// export const getAllAppointments = async (setLoading, data, setAppointmentsData) => {
//   try {
//     setLoading(true);
//     const response = await validHIS.post(`patientvisits`, data);
//     setLoading(false);
//     if (setAppointmentsData) {
//       setAppointmentsData(response?.data);
//     }
//     return response?.data;
//   } catch (error) {
//     setLoading(false);
//     console.error(error);
//     // toast.error(error?.response?.data?.error);
//   }
// };
export const getPriceListByID = async (setLoading, data, setPriceLIst) => {
  try {
    setLoading(true);
    const response = await validERP.post(`items`, data);
    setLoading(false);
    setPriceLIst(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
    // toast.error(error?.response?.data?.error);
  }
};
export const updateAppointment = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`updatevisitStatus`, data);
    toast.success(response?.data?.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const updateDoctor = async (setLoading, data) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`updatePractitioner`, data);
    toast.success(response?.data?.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const deleteDoctor = async (setLoading, id) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`deletePractitioner`, id);
    toast.success(response?.data?.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const doctorById = async (setLoading, setDoctors, id) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`practitionerbyid`, id);
    // toast.success(response?.data?.message);
    setLoading(false);
    setDoctors(response?.data);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const createHisPayer = async (setShowLoader, payload) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('payer', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const getPayersList = async (setLoading, setPayersList) => {
  try {
    setLoading(true);
    const response = await validHIS.post(`payer_list`);
    setPayersList(response?.data);
    return response?.data;
  } catch (error) {
    console.error('Error fetching payers list:', error);
  } finally {
    setLoading(false); // Set loading to false once request completes
  }
};

export const deletePayer = async (setShowLoader, data) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('deletepayer', {
      payer_id: data
    });
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const updatePayer = async (setShowLoader, payload) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('updatepayer', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const getRcmPatientByID = async (id, setRcmDetail) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token) throw new Error('Authorization token not found.');

    const response = await axios.get(`https://api.supergitsa.com/api/patient/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (setRcmDetail) {
      setRcmDetail(response?.data?.data);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching patient data:', error);
  }
};
export const updateEmrItemsStatus = async (setShowLoader, payload) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('updateEMRItemStatus', payload);
    toast.success(response.data.message);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
    toast.error(error?.response?.data?.error);
  }
};
export const GetTodayAppointment = async (setShowLoader, setToday) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('todayVisit');
    setToday(response?.data?.data);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
  }
};
export const SearchPatient = async (setShowLoader, setPatient, payload) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('searchPatient', payload);
    setPatient(response?.data);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
  }
};
export const updateVisitInvoice = async (setShowLoader, payload) => {
  try {
    setShowLoader(true);
    const response = await validHIS.post('updateInvoiceNumber', payload);
    setShowLoader(false);
    return response.data;
  } catch (error) {
    setShowLoader(false);
    console.error(error);
  }
};

export const getNursesList = async (setLoading, setNurses, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('listNurses', payload);
    setLoading(false);
    setNurses(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};

export const getUnassignedNursesList = async (setLoading, setNurses) => {
  try {
    setLoading(true);
    const response = await validHIS.post('getNurses');
    setLoading(false);
    setNurses(response?.data?.data ?? []);
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};

export const createNewNurse = async (setLoading, payload, navigate) => {
  try {
    setLoading(true);
    const response = await validHIS.post('nurse', payload);
    toast.success(response.data.message);
    setLoading(false);
    if (navigate) {
      navigate('/nurses');
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};

export const getDriversList = async (setLoading, setNurses, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('listDrivers', payload);
    setLoading(false);
    setNurses(response?.data);
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};

export const getAllDriversList = async (setLoading, setDrivers) => {
  try {
    setLoading(true);
    const response = await validHIS.post('getDrivers');
    setLoading(false);
    if (response?.data?.data) {
      let updatedDrivers = response?.data?.data?.map(driver => {
        return {
          ...driver,
          assigned_nurses: []
        };
      });
      setDrivers(updatedDrivers ?? []);
    }
  } catch (error) {
    setLoading(false);
    console.error(error);
  }
};

export const updateNurse = async (setLoading, payload, navigate) => {
  try {
    setLoading(true);
    const response = await validHIS.post('updateNurse', payload);
    toast.success(response.data.message);
    setLoading(false);
    if (navigate) {
      navigate('/nurses');
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};

export const createNewDriver = async (setLoading, payload, navigate) => {
  try {
    setLoading(true);
    const response = await validHIS.post('driver', payload);
    toast.success(response.data.message);
    setLoading(false);
    if (navigate) {
      navigate('/drivers');
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};
export const updateDriver = async (setLoading, payload, navigate) => {
  try {
    setLoading(true);
    const response = await validHIS.post('updateDriver', payload);
    toast.success(response.data.message);
    setLoading(false);
    if (navigate) {
      navigate('/drivers');
    }
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};

export const cancelInvoice = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await validERP.post('updateInvoice', payload);
    toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};

export const getDashboardStats = async (setLoading, setDashboardData, payload) => {
  try {
    setLoading(true);
    const response = await validHIS.post('getStats', payload);
    setDashboardData(response?.data);
    toast.success(response.data.message);
    setLoading(false);
    return response?.data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    toast.error(error?.response?.data?.error);
  }
};
